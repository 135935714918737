import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'; 

// RICHIESTA REIMPOSTAZIONE PASSWORD
export function ForgotPassword() {
  const annoCorrente = new Date().getFullYear();
  const [mail, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Dati inviati:", { mail }); //<--- questa riga di log, scrive correttamente il valore inserito nel form
      const response = await axios.post(
        `/api/recupera-password`,
        { mail } // Invia l'oggetto mail direttamente
      );
      setMessage(response.data.message);
      setError(null); // Cancella eventuali errori precedenti
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Errore durante la richiesta di reset della password"
      );
    }
  };

  return (
    <div className="main_content_iner">
      <div className="container-fluid body_white_bg plr_30 pt_30 pb_30">
        <div className="row justify-content-center mt-5 pt_30 pb_30">
          <div className="col-md-4">
            <div className="modal-content cs_modal">
              <div className="modal-header flexxa">
                <h5 className="modal-title">Recupera Password</h5>
                <img src="/img/logo-animus-operandi.svg" alt="Animus Operandi" className="logologin"></img>
              </div>
              <div className="modal-body">
                {message && <div className="alert alert-success">{message}</div>}
                {error && <div className="alert alert-danger">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                    <label htmlFor="mail">E-mail con cui ti sei registrato:</label>
                    <input
                        type="mail"
                        className="form-control"
                        id="mail"
                        name="mail"
                        value={mail}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </div>
                    <button type="submit" className="btn btn-primary">
                    Richiedi la reimpostazione
                    </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div style={{minHeight: '120px'}}> </div>
      </div>
      <div className="footer_partGen justify-content-center">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                    <div className="footer_iner text-center">
                        <p>© {annoCorrente} Animus Operandi -  Designed & Developed by <a href="https://stefanocoggiola.com/" target="_blank" rel="noreferrer">Stefano Coggiola</a></p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

// REIMPOSTAZIONE PASSWORD
export function ResetPassword() {
  const annoCorrente = new Date().getFullYear();
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Le password non corrispondono');
      return;
    }

    try {
      const response = await axios.post(
        `/api/reimposta-password/${decodeURIComponent(token)}`,
        { password } // Passa l'oggetto con la password come secondo argomento
      );
      setMessage(response.data.message);
      setError(null);
      setTimeout(() => {
        navigate('/login');
      }, 1000);
    } catch (err) {
      setError(err.response?.data?.message || 'Errore durante il reset della password');
    }
  };

  return (
    <div className="main_content_iner">
      <div className="container-fluid body_white_bg plr_30 pt_30 pb_30">
        <div className="row justify-content-center mt-5 pt_30 pb_30">
          <div className="col-md-4">
            <div className="modal-content cs_modal">
              <div className="modal-header flexxa">
                <h5 className="modal-title">Reimposta la tua Password</h5>
                <img src="/img/logo-animus-operandi.svg" alt="Animus Operandi" className="logologin"></img>
              </div>
              <div className="modal-body">
                {message && <div className="alert alert-success">{message}</div>}
                {error && <div className="alert alert-danger">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                    <label htmlFor="password">Nuova Password:</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    </div>

                    <div className="form-group">
                    <label htmlFor="confirmPassword">Conferma Password:</label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    </div>
                    <button type="submit" className="btn btn-primary">Reimposta Password</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div style={{minHeight: '120px'}}> </div>
      </div>
      <div className="footer_partGen justify-content-center">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                    <div className="footer_iner text-center">
                        <p>© {annoCorrente} Animus Operandi -  Designed & Developed by <a href="https://stefanocoggiola.com/" target="_blank" rel="noreferrer">Stefano Coggiola</a></p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

// Assegna l'oggetto a una variabile
const ResetPasswordComponents = { ForgotPassword, ResetPassword };
// Esporta la variabile
export default ResetPasswordComponents; 
