import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";

function CertificatoUpload() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [titolo, setTitolo] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);  
  // const [cosa] = useState('');
  const [formData, setFormData] = useState({
    id_utente: userData ? userData.id : "",
    titolo: "",
    testo: "",
    filename: "",
    tipo: "i",
    cosa: "Altro",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const { name, value } = e.target; // Ottieni il valore del radio button selezionato
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Aggiorna lo stato con il valore del radio button
    }));
    setSelectedFile(e.target.files[0]); // Aggiorna lo stato con il file selezionato
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target; // Ottieni il valore del radio button selezionato
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Aggiorna lo stato con il valore del radio button
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('titolo', titolo);
    if (selectedFile) {  // Aggiungi il file solo se è stato selezionato
      formData.append('file', selectedFile);
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/upload_certificati_utente`, { // Chiama l'endpoint di upload
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: formData
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
        navigate('/dashboard'); // Reindirizza alla dashboard dopo l'upload
      } else {
        const errorData = await response.json();
        alert(errorData.error || 'Errore durante il caricamento del documento');
      }
    } catch (err) {
      console.error('Errore durante il caricamento:', err);
      alert('Si \u00e8 verificato un errore durante il caricamento. Probabilmente il tipo di file non è consentito o le dimensioni superano i 5 Mb. massimi consentiti.');
    }    
  };
  return (
    <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box mb_30">
                  <div className="box_header ">
                    <div className="main-title">
                      <h3 className="mb-0">Invia un Certificato</h3>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">                    
                    <div className='row'>
                      <div className="col-12 alert alert-primary text-center">
                          <p style={{color: '#222'}}>Qui puoi inviare una Certificazione, un'Autorizzazione, un Attestato o altri certificati utili da conservare e rendere disponibili al tuo Consulente.</p>
                      </div>     
                    </div>
                    <div className='row mb_20'>
                      <div className='col-12 alert alert-warning'>
                        <h3>ATTENZIONE:</h3>
                        Non sono consentiti file di dimensioni superiori a <b>5 MegaBytes</b> e nei seguenti formati:
                        <ul>
                          <li> &nbsp; a) Testo <b>.TXT</b></li>
                          <li> &nbsp; b) Rich Text Format <b>.RTF</b></li>
                          <li> &nbsp; c) <b>Word Processors</b>: MicroSoft Word oppure Libre/Open Office Writer</li>
                          <li> &nbsp; d) <b>Fogli di Calcolo</b>: MicroSoft Excel oppure Libre/Open Office Calc</li>
                          <li> &nbsp; e) <b>Presentazioni</b>: MicroSoft PowerPoint oppure Libre/Open Office Impress</li>
                          <li> &nbsp; f) Adobe <b>.PDF</b></li>
                          <li> &nbsp; g) Immagini/Foto: <b>.JPG/.JPEG</b> o <b>.PNG</b></li>
                          <li> &nbsp; h) File compressi: <b>.ZIP</b>, <b>.RAR</b>, <b>.7ZIP</b></li>
                        </ul>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-12 mb-3">
                        <label className="form-label" htmlFor="titolo">Titolo identificativo <b className='text text-danger'>*</b></label>
                        <input type="text" className="form-control" id="titolo" placeholder="Titolo identificativo" value={titolo} onChange={(e) => setTitolo(e.target.value)} required aria-required="true" />
                      </div>
                      <div className="col-6 mb-3">
                        <div className="input-group mb-3">
                          <label className="form-label" htmlFor="file">Scegli il file dal tuo device
                          <input type="file" className="form-control" name="file" id="file" onChange={handleFileChange} />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                        <button type="submit" className="btn btn-outline-primary" id="btnUploadUserDoc" style={{marginTop: '14px'}}><i className="ti-upload" style={{paddingRight: '6px'}}></i> Invia Certificato</button>
                      </div>
                      {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                        <div className="col-12">
                          <div className="alert alert-success mt_20" role="alert">Status aggiornato con successo!<br /> <br />Puoi modificarlo ulteriormente o impostare </div>
                        </div>
                      )}
                    </div>
                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
      </section>
    </>
  );

}

export default CertificatoUpload;