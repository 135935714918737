import React, { useState, useEffect } from 'react';

function BoxPlafond() {
  const [plafond, setPlafond] = useState(null);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchplafond = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/boxplafond`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (response.ok) {
          const data = await response.json();
          setPlafond(data);
        } else {
          throw new Error('Errore nel recupero delle informazioni');
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchplafond();
  }, []);

  function formatHoursMinutes(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`; // Aggiunge uno zero iniziale se i minuti sono meno di 10
  }

  return (
    <div className="single_quick_activity">
      <h4>Ore mensili residue</h4>
      {error ? (
        <p>Errore: {error}</p>
      ) : plafond && plafond.ore && plafond.residue ? ( // Controlla se plafond e le sue proprietà esistono
        <h3>
          {formatHoursMinutes(plafond.residue)}<p><span style={{fontSize:'1.3rem',fontWeight:'400'}}>di {formatHoursMinutes(plafond.ore)}</span></p>
          {/* {plafond.residue}<p><span style={{fontSize:'1.3rem',fontWeight:'400'}}>di {plafond.ore}</span></p> */}
        </h3>
      ) : (
        <p>Sto calcolando...</p>
      )} 
    </div>
  );
}

export default BoxPlafond;