import React, { useContext, useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import $ from 'jquery';
import 'datatables.net';
function FormazioneCliente() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [documenti, setDocumenti] = useState([]); // Stato per i documenti
  const [errorDoc, setDocError] = useState(null);
//   const navigate = useNavigate();

  useEffect(() => {   
    const fetchDocumenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/formazione`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const docum = await response.json();
            console.log(docum);
            setDocumenti(docum); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei documenti');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchDocumenti(); 
  }, []);// Chiama la funzione per recuperare i documenti    

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

  useEffect(() => {
  // Funzione per inizializzare o aggiornare la DataTable
  let table;
  const initializeOrUpdateTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        // Inizializza la DataTable solo se non è già stata inizializzata
        table = $(tableRef.current).DataTable({ // Assegna il valore a table
            ordering: true,
            searching: true,
            paging: true,
            info: true,
            dom: "frtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            language: {
            url: "/js/it-IT.json",
            },
            pageLength: 10,
        });
      }
    };

    // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
    if (tableRef.current) {
        setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
    }

  // Distruggi la DataTable quando il componente viene smontato
  return () => {
    if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
            table.destroy();
        }
    };
    }, [documenti]);

    const handleChangeBool = async (e, documentoId) => {
        const { checked } = e.target;
            try {
            const token = localStorage.getItem('token');
            const response = await fetch(`/api/formazione/${documentoId}/svolta`, {
                method: 'PATCH',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: JSON.stringify({ svolta: checked }) 
            });

            if (response.ok) {
                // Aggiorna lo stato locale solo se la richiesta ha avuto successo
                setDocumenti(prevDocumenti => prevDocumenti.map(doc =>
                doc._id === documentoId ? { ...doc, svolta: checked } : doc
                ));
            } else {
                console.error('Errore durante l\'aggiornamento del documento:', response.statusText);
                // Potresti voler ripristinare lo stato del checkbox in caso di errore
                e.target.checked = !checked; 
            }
            } catch (err) {
            console.error('Errore durante l\'aggiornamento:', err);
            // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
            e.target.checked = !checked; // Ripristina lo stato del checkbox
            }
        };

    return (
      <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>Formazione obbligatoria per la tua Attività</h4>
                            </div>
                            {errorDoc ? (
                                <h5>Errore: {errorDoc}</h5>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table table-striped" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                        <thead>
                                            <tr>
                                                <th style={{fontWeight: '700'}}><i>Pubblicato il</i></th>
                                                <th style={{fontWeight: '700'}}><i>Tipo</i></th>
                                                <th style={{fontWeight: '700'}}><i>Descrizione</i></th>
                                                <th style={{fontWeight: '700'}}><i>Effettuato</i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documenti.map(documento => (
                                            <tr key={documento._id} id={documento._id}>
                                                <td style={{ paddingTop: '20px' }}>
                                                <p>{documento.data ? moment(documento.data).format('DD MMMM YYYY') : ''}</p>
                                                </td>
                                                <td style={{ paddingTop: '20px' }}>
                                                    <p className="text text-dark" style={{fontWeight: '600'}}>{documento.tipo}</p>
                                                </td>
                                                <td style={{ paddingTop: '20px' }}>
                                                    <p>{documento.testo}</p>
                                                </td>
                                                <td title={`Segna come effettuata:  ${documento.titolo}`}>
                                                    <p style={{fontWeight: '400', textDecoration: 'none', whiteSpace: 'nowrap' }}>
                                                        <input 
                                                        type="checkbox" 
                                                        id="svolta" 
                                                        name="svolta" 
                                                        checked={documento.svolta} 
                                                        onChange={(e) => handleChangeBool(e, documento._id)} // Passa anche l'ID del documento
                                                    />
                                                    </p>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th style={{fontWeight: '700'}}><i>Pubblicato il</i></th>
                                                <th style={{fontWeight: '700'}}><i>Tipo</i></th>
                                                <th style={{fontWeight: '700'}}><i>Descrizione</i></th>
                                                <th style={{fontWeight: '700'}}><i>Effettuato</i></th>
                                            </tr>
                                        </tfoot>
                                        </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
      </>
    );
  }

export default FormazioneCliente;