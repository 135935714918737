import React from 'react';

function AzioniRapide() {

    return (
        <div className="col-lg-12 mb_20">
            <div className="single_element">
                <div className="quick_activity">
                    <div className="row">
                        <div className="col-12">
                            <h5>Aggiungi al volo:</h5>
                            <div className='row'>
                                <div className="col-12 flex-container">
                                    <a href="/nuova-anagrafica" className="btn btn-primary btn-sm flex-item" title="Inserisci nuova Anagrafica"><i className='ti-agenda'></i>&nbsp; Anagrafica</a>
                                    <a href="/nuovo_preventivo" className="btn btn-primary btn-sm flex-item" title="Richiedi nuovo Preventivo"><i className='ti-files'></i>&nbsp; Preventivo</a>
                                    <a href="/nuova_fattura" className="btn btn-primary btn-sm flex-item" title="Richiedi nuova Fattura"><i className='ti-stamp'></i>&nbsp; Fattura</a>
                                    <a href="/upload-documenti/?i=Registrazione in Prima Nota" className="btn btn-primary btn-sm flex-item" title="Registra entrate/uscite in Prima Nota"><i className='ti-pulse'></i>&nbsp; Prima Nota</a>
                                    <a href="/upload-documenti/?i=Contratto da rivedere" className="btn btn-primary btn-sm flex-item" title="Genera un contratto con l'AI e sottoponilo ai ns. legali per una revisione"><i className='ti-marker-alt'></i>&nbsp; Contratto</a>
                                    <a href="/upload-documenti/?i=Documento Generico" className="btn btn-primary btn-sm flex-item" title="Carica un documento diverso da quelli precedenti"><i className='ti-cloud-up'></i>&nbsp; Documento</a>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-sm-6 col-md-4 col-lg-2 d-grid gap-2 mb_10'>
                                    <a href="/nuova-anagrafica" className="btn btn-light btn-sm"><i className='ti-agenda'></i> Nuovo Anagrafica</a>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-2 d-grid gap-2 mb_10'>
                                    <a href="/nuovo_preventivo" className="btn btn-light btn-sm"><i className='ti-files'></i> Preventivo</a>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-2 d-grid gap-2 mb_10'>
                                    <a href="/nuova_fattura" className="btn btn-light btn-sm"><i className='ti-stamp'></i> Fattura</a>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-2 d-grid gap-2 mb_10'>
                                    <a href="/upload-documenti/?i=Registrazione in Prima Nota" className="btn btn-light btn-sm"><i className='ti-pulse'></i> Prima Nota</a>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-2 d-grid gap-2 mb_10'>
                                    <a href="/upload-documenti/?i=Contratto da rivedere" className="btn btn-light btn-sm"><i className='ti-marker-alt'></i> Contratto</a>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-2 d-grid gap-2 mb_10'>
                                    <a href="/upload-documenti/?i=Documento Generico" className="btn btn-light btn-sm"><i className='ti-cloud-up'></i> Documento generico</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AzioniRapide;