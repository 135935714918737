import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "./UserContext";
import TestataAdmin from "./partials/TestataAdmin";
import NavBarAdmin from "./partials/NavBarAdmin";
import Footer from "./partials/Footer";

function OperatoreNew() {
  const { userData } = useContext(UserContext);
  const [formData, setFormData] = useState({
    role: "operatore",
    ragioneSociale: "Animus Operandi",
    indirizzo: "",
    comune: "",
    prov: "",
    cap: "",
    codUniv: "",
    pec: "",
    whatsapp: "",
    url: "",
    disponibili: 0,
    residue: 0,
    attivo: true,
    mostraStripe: false,
  });
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 
  const [generatedPassword, setGeneratedPassword] = useState('');
  
   useEffect(() => {
    // Genera una password casuale al caricamento del componente
    setGeneratedPassword(generateRandomPassword());
    }, []); // Esegui solo una volta al montaggio del componente

    const generateRandomPassword = (length = 12) => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%()_-+=[]./?";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
    };
    
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeBool = (e) => {
    const { name, checked } = e.target; // Ottieni il valore checked (booleano)
    setFormData({ ...formData, [name]: checked }); // Aggiorna lo stato con il valore booleano
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/nuovo-operatore`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        setTimeout(() => {
            setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Errore durante il salvataggio');
      }
    } catch (err) {
      console.error('Errore durante il salvataggio:', err);
      setError('Si è verificato un errore durante il salvataggio');
    }
  };

 return (
    <>
      <NavBarAdmin />
      <section className="main_content dashboard_part">
        <div className='className="container-fluid g-0'>            
            <TestataAdmin userData={userData} />
        </div>
        <div className="main_content_iner ">
            <div className="container-fluid plr_30 body_white_bg pt_30">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="white_box_tittle list_header">
                            <h4>Aggiungi nuovo Operatore</h4>
                        </div>
                        {error && <p className="error-message">{error}</p>}
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" id="disponibili" name="disponibili" value="0" onChange={handleChange} />
                            <input type="hidden" id="ore" name="ore" value="0" onChange={handleChange} />
                            <input type="hidden" id="residue" name="residue" value="100" onChange={handleChange} />
                            <input type="hidden" id="ragioneSociale" name="ragioneSociale" value=" " onChange={handleChange} />
                            <input type="hidden" id="indirizzo" name="indirizzo" value=" " onChange={handleChange} />
                            <input type="hidden" id="comune" name="comune" value=" " onChange={handleChange} />
                            <input type="hidden" id="prov" name="prov" value=" " onChange={handleChange} />
                            <input type="hidden" id="cap" name="cap" value=" " onChange={handleChange} />
                            <input type="hidden" id="whatsapp" name="whatsapp" value=" " onChange={handleChange} />
                            <input type="hidden" id="url" name="url" value=" " onChange={handleChange} />
                            <input type="hidden" id="pec" name="pec" value=" " onChange={handleChange} />
                            <input type="hidden" id="codUniv" name="codUniv" value=" " onChange={handleChange} />                           

                            <div className="row">
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="attivo">Attivo:</label><br />
                                    <input
                                      type="checkbox"
                                      id="attivo"
                                      name="attivo"
                                      checked={formData.attivo}
                                      onChange={handleChangeBool}                                     
                                  />
                                </div>  
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="nome">Nome <b style={{color: "#CC0000"}}>*</b>:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nome"
                                        name="nome"
                                        value={formData.nome}
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="cognome">Cognome <b style={{color: "#CC0000"}}>*</b>:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cognome"
                                        name="cognome"
                                        value={formData.cognome}
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                            </div>                      
                      
                            <div className="row">
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="mail">Indirizzo e-mail/Nome Utente <b style={{color: "#CC0000"}}>*</b><b style={{color: "#CC0000"}}>*</b>:</label>
                                    <input
                                        type="mail"
                                        className="form-control"
                                        id="mail"
                                        name="mail"
                                        value={formData.mail}
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="tel">Telefono:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="tel"
                                        name="tel"
                                        value={formData.tel}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="cell">Cellulare:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cell"
                                        name="cell"
                                        value={formData.cell}
                                        onChange={handleChange}                                       
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="codFisc">Codice Fiscale <b style={{color: "#CC0000"}}>*</b>:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="codFisc"
                                        name="codFisc"
                                        value={formData.codFisc}
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="piva">Partita IVA (0 se non disponibile) <b style={{color: "#CC0000"}}>*</b>:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="piva"
                                        name="piva"
                                        value={formData.piva}
                                        onChange={handleChange}
                                        required                                       
                                    />
                                </div> 

                                <div className="form-group col-md-2 cols-sm-12 mb_20">
                                    <label htmlFor="regime">Regime:</label><br />
                                    <select
                                        className="form-select form-control"
                                        id="regime"
                                        name="regime"
                                        value={formData.regime}
                                        onChange={handleChange}                                       
                                    >
                                        <option value=""> </option>
                                        <option value="Forfettario">Forfettario</option>
                                        <option value="Semplificato">Semplificato</option>
                                        <option value="Ordinario">Ordinario</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-2 cols-sm-12 mb_20">
                                    <label htmlFor="ritenuta">R.A.</label><br />
                                    <select
                                        className="form-select form-control"
                                        id="ritenuta"
                                        name="ritenuta"
                                        value={formData.ritenuta}
                                        onChange={handleChange}                                    
                                    >               
                                        <option value=""> </option>                          
                                        <option value="0">0%</option>
                                        <option value="4">4%</option>
                                        <option value="15">15%</option>
                                    </select>
                                </div> 
                            </div>

                            <div className="row">
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="piva">Password <b style={{color: "#CC0000"}}>*</b>:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        value={generatedPassword}
                                        onChange={handleChange}                                       
                                    />
                                </div> 
                                <div className="form-group col-md-8 cols-sm-12 mb_20" style={{textAlign: 'right'}}>
                                    {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                                        <div className="alert alert-success mt_20" role="alert">Operatore aggiunto con successo!</div>
                                    )}                                    
                                    <button type="submit" className="btn btn-primary mt_20">
                                        Aggiungi Operatore
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default OperatoreNew;
