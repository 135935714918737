/* eslint-disable no-undef */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js

function Preventivi() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [preventivi, setPreventivi] = useState([]); // Stato per i preventivi
  const [errorPrev, setPrevError] = useState(null);
//   const navigate = useNavigate();

  useEffect(() => {
    const fetchPreventivi = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/preventivi`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const preventivi = await response.json();
            setPreventivi(preventivi); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei preventivi');
            }
        } catch (err) {
            setPrevError(err.message);
        }
    };

    fetchPreventivi();
  }, []);

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

  useEffect(() => {
    // Funzione per inizializzare o aggiornare la DataTable
    let table;
    const initializeOrUpdateTable = () => {
        if (!$.fn.DataTable.isDataTable(tableRef.current)) {
            table = $(tableRef.current).DataTable({ // Assegna il valore a table
                ordering: true,
                searching: true,
                paging: true,
                info: true,
                dom: "frtip",
                buttons: ["copy", "csv", "excel", "pdf", "print"],
                language: {
                url: "/js/it-IT.json",
                },
                pageLength: 10,
            });
        }
        };

        // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
        if (tableRef.current) {
            setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
        }

    // Distruggi la DataTable quando il componente viene smontato
    return () => {
        if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
            table.destroy();
        }
    };
    }, [preventivi]);
    
  const handleDeletePreventivo = async (preventivoId) => {
        if (window.confirm('Sei sicuro di voler eliminare questo preventivo? Questa operazione NON sarà reversibile.')) {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`/api/elimina-preventivo/${preventivoId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
            });

            if (response.ok) {
            // Rimuovi il preventivo eliminato dall'array di stato
            setPreventivi(prevPreventivi => prevPreventivi.filter(p => p._id !== preventivoId));
            } else {
            const errorData = await response.json();
            setPrevError(errorData.error || 'Errore durante l\'eliminazione del preventivo');
            }
        } catch (err) {
            console.error('Errore durante l\'eliminazione del preventivo:', err);
            setPrevError('Si è verificato un errore durante l\'eliminazione del preventivo');
        }
      }
    };

  // Esegui l'effetto solo al montaggio del componente
  return (
    <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>
                                    <a href="/nuovo_preventivo" className="btn btn-outline-dark" style={{float:'right',fontWeight:'700',marginTop: '-18px', marginLeft: '100px'}} title="Predisponi un nuovo preventivo"><i className="ti-plus"></i></a>
                                    Preventivi aperti
                                </h4>
                            </div>
                            {errorPrev ? (
                                <tr><td colspan="4"> <h5>Errore: {errorPrev}</h5> </td></tr>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table table-striped" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                        <thead>
                                            <tr>
                                                <th><b>Data</b></th>
                                                <th><b>Destinatario</b></th>
                                                <th><b>Status</b></th>
                                                <th> </th>
                                            </tr>
                                        </thead>                                              
                                        <tbody>
                                          {preventivi.map(preventivo => (
                                            <tr key={preventivo._id}>
                                                <td><p>{moment(preventivo.data).format('DD/MM/YY')}</p></td>
                                                <td><p><a href={`/preventivo/${preventivo._id}`} title="Vedi/Modifica questo preventivo">{preventivo.cliente ? preventivo.cliente : "Cliente non trovato"}</a></p></td>
                                                <td><p className={`${preventivo.status}`} style={{fontSize:'0.7rem'}}>{preventivo.status}</p></td>
                                                <td>{preventivo.status === 'Da inviare' && (
                                                    <>                                                    
                                                        <button className="btn btn-danger btn-sm"  style={{fontSize:'0.7rem'}} // Aggiungi un pulsante di eliminazione
                                                            onClick={() => handleDeletePreventivo(preventivo._id)}
                                                            title="Elimina preventivo"
                                                        ><i className="ti-trash"></i></button>
                                                    </>
                                                    )}            
                                                </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Data</th>
                                                <th>Destinatario</th>
                                                <th>Status</th>
                                                <th> </th>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    </>
  );
}

export default Preventivi;