import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import { useParams } from "react-router-dom";
import TestataOperatore from "./partials/TestataOperatore";
import NavBarOp from "./partials/NavBarOp";
import Footer from "./partials/Footer";

function DocOpUpload() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const { id } = useParams(); 
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tipo = query.get("i"); // Ottieni il valore di 'i' dalla query string
  const [titolo, setTitolo] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);  
  // const [cosa] = useState('');
  const [formData, setFormData] = useState({
    id_utente: id,
    titolo: "",
    filename: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Imposta il valore predefinito di cosa solo se tipo è null o undefined
    if (!tipo) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        cosa: "Documento Generico",
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        cosa: tipo,
      }));
    }
  }, [tipo]); // Dipendenza da tipo

  const handleFileChange = (e) => {
    const { name, value } = e.target; // Ottieni il valore del radio button selezionato
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Aggiorna lo stato con il valore del radio button
    }));
    setSelectedFile(e.target.files[0]); // Aggiorna lo stato con il file selezionato
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target; // Ottieni il valore del radio button selezionato
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Aggiorna lo stato con il valore del radio button
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      alert('Seleziona un file da caricare.');
      return;
    }
    const formData = new FormData();
    formData.append('titolo', titolo);
    formData.append('file', selectedFile);
    formData.append('cosa', tipo);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/documento_per_utente/${id}`, { 
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: formData
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
        navigate(`/gestione/dettaglio-utente/${id}`); // Reindirizza alla dashboard dopo l'upload
      } else {
        const errorData = await response.json();
        alert(errorData.error || 'Errore durante il caricamento del documento');
      }
    } catch (err) {
      console.error('Errore durante il caricamento:', err);
      alert('Si \u00e8 verificato un errore durante il caricamento. Probabilmente il tipo di file non è consentito o le dimensioni superano i 5 Mb. massimi consentiti.');
    }    
  };
  return (
    <>
      <NavBarOp />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <TestataOperatore userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box mb_30">
                  <h4 style={{float: 'right'}}>
                    <a href={`/gestione/dettaglio-utente/${id}`} className='btn btn-outline-primary'><i className='ti-control-backward'></i> Dettaglio Utente</a>
                  </h4>
                  <div className="box_header ">
                    <div className="main-title">
                      <h3 className="mb-0">Invia un documento a questo Utente</h3>                      
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                  
                    <div className='row mb_20'>
                      <div className='col-12'>
                        <h3>Tipologia</h3>
                      </div>
                      <div className="col-4 text-center">
                        <label htmlFor='Generico'><b className='text text-dark'>Documenti vari</b></label><br />
                        <input type="radio" name="cosa" id="Generico" value="Documento Generico" checked={formData.cosa === "Documento Generico"}  onChange={handleChange} style={{ minHeight: '22px', minWidth: '22px' }} />
                      </div>
                      <div className="col-4 text-center">
                        <label htmlFor='Preventivo'><b className='text text-orange'>Preventivo</b></label><br />
                        <input type="radio" name="cosa" id="Preventivo" value="Preventivo" checked={formData.cosa === "Preventivo"} onChange={handleChange} style={{ minHeight: '22px', minWidth: '22px' }} />
                      </div>
                      <div className="col-4 text-center">
                        <label htmlFor='Contratto'><b className='text text-dark'>Contratto</b></label><br />
                        <input type="radio" name="cosa" id="Contratto" value="Contratto" checked={formData.cosa === "Contratto"}  onChange={handleChange} style={{ minHeight: '22px', minWidth: '22px' }} />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-12">
                        <div className="alert alert-primary text-center">
                          <b>ATTENZIONE!</b><br />Devi sempre indicare <b>tra le opzioni qui sopra</b> quale tipo di documento stai inviando, affinché venga trattato per la finalità corretta.<br />Di default è impostato come Documento Generico, se non selezionato diversamente
                        </div>
                      </div>     
                    </div>
                    <div className='row mt_30'>
                      <div className="col-md-4 col-xs-12 mb-3">
                        <label className="form-label" htmlFor="titolo">Titolo del documento (max 50 caratteri)</label>
                        <input type="text" maxLength="50" className="form-control" id="titolo" placeholder="Titolo del documento/Voce di spesa o di incasso" value={titolo} onChange={(e) => setTitolo(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4 col-xs-12 mb-3">
                        <div className="input-group mb-3">
                          <label className="form-label" htmlFor="file" style={{lineHeight: '14px'}}>Scegli il file dal tuo device<br /><br /> 
                          <input type="file" className="form-control" name="file" id="file" onChange={handleFileChange} />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 col-xs-12 mb-3" style={{textAlign: 'right'}}><br />
                        <button type="submit" className="btn btn-primary" id="btnUploadUserDoc" style={{marginTop: '1vh'}}><i className="ti-upload" style={{paddingRight: '6px'}}></i> Invia il file</button>
                      </div>
                    </div>
                    <div className="row">
                      {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                        <div className="col-12">
                          <div className="alert alert-success mt_20" role="alert">Status aggiornato con successo!<br /> <br />Puoi modificarlo ulteriormente o impostare </div>
                        </div>
                      )}
                    </div>
                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
      </section>
    </>
  );

}

export default DocOpUpload;