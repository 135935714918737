import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";

function DocUtenteUpload() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tipo = query.get("i"); // Ottieni il valore di 'i' dalla query string
  const [titolo, setTitolo] = useState('');
  const [testo, setTesto] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);  
  // const [cosa] = useState('');
  const [formData, setFormData] = useState({
    id_utente: userData ? userData.id : "",
    titolo: "",
    filename: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Imposta il valore predefinito di cosa solo se tipo è null o undefined
    if (!tipo) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        cosa: "Documento Generico",
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        cosa: tipo,
      }));
    }
  }, [tipo]); // Dipendenza da tipo

  const handleFileChange = (e) => {
    const { name, value } = e.target; // Ottieni il valore del radio button selezionato
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Aggiorna lo stato con il valore del radio button
    }));
    setSelectedFile(e.target.files[0]); // Aggiorna lo stato con il file selezionato
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target; // Ottieni il valore del radio button selezionato
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Aggiorna lo stato con il valore del radio button
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('titolo', titolo);
    if (selectedFile) {  // Aggiungi il file solo se è stato selezionato
      formData.append('file', selectedFile);
    }
    formData.append('cosa', tipo);
    formData.append('testo', testo);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/upload_documenti_utente`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: formData
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
        navigate('/dashboard'); // Reindirizza alla dashboard dopo l'upload
      } else {
        const errorData = await response.json();
        alert(errorData.error || 'Errore durante il caricamento del documento');
      }
    } catch (err) {
      console.error('Errore durante il caricamento:', err);
      alert('Si \u00e8 verificato un errore durante il caricamento. Probabilmente il tipo di file non è consentito o le dimensioni superano i 5 Mb. massimi consentiti.');
    }    
  };
  return (
    <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box mb_30">
                  <div className="box_header ">
                    <div className="main-title">
                      <h3 className="mb-0">Invia un documento</h3>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className='row mb_20'>
                      <div className='col-12 alert alert-warning'>
                        <h3>ATTENZIONE:</h3>
                        Non sono consentiti file di dimensioni superiori a <b>10 MegaBytes</b> e nei seguenti formati:
                        <ul>
                          <li> &nbsp; a) Testo <b>.TXT</b></li>
                          <li> &nbsp; b) Rich Text Format <b>.RTF</b></li>
                          <li> &nbsp; c) <b>Word Processors</b>: MicroSoft Word oppure Libre/Open Office Writer</li>
                          <li> &nbsp; d) <b>Fogli di Calcolo</b>: MicroSoft Excel oppure Libre/Open Office Calc</li>
                          <li> &nbsp; e) <b>Presentazioni</b>: MicroSoft PowerPoint oppure Libre/Open Office Impress</li>
                          <li> &nbsp; f) Adobe <b>.PDF</b></li>
                          <li> &nbsp; g) Immagini/Foto: <b>.JPG/.JPEG</b> o <b>.PNG</b></li>
                          <li> &nbsp; h) File compressi: <b>.ZIP</b>, <b>.RAR</b>, <b>.7ZIP</b></li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className='row mb_20'>
                      <div className='col-12'>
                        <h3>Tipologia</h3>
                      </div>
                      <div className="col-4 text-center">
                        <label htmlFor='Generico'><b className='text text-dark'>Documenti personali/generici</b></label><br />
                        <input type="radio" name="cosa" id="Generico" value="Documento Generico" checked={formData.cosa === "Documento Generico"}  onChange={handleChange} style={{ minHeight: '22px', minWidth: '22px' }} />
                      </div>                 
                      <div className="col-4 text-center">
                        <label htmlFor='PrimaNota'><b className='text text-dark'>Entrate/Uscite per Prima Nota</b></label><br />
                        <input type="radio" name="cosa" id="PrimaNota" value="Registrazione in Prima Nota" checked={formData.cosa === "Registrazione in Prima Nota"} onChange={handleChange} style={{ minHeight: '22px', minWidth: '22px' }} />
                      </div>                      
                      <div className="col-4 text-center">
                        <label htmlFor='Contratto'><b className='text text-dark'>Predisposizione di un Contratto</b></label><br />
                        <input type="radio" name="cosa" id="Contratto" value="Contratto da rivedere" checked={formData.cosa === "Contratto da rivedere"}  onChange={handleChange} style={{ minHeight: '22px', minWidth: '22px' }} />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-12">
                        <div className="alert alert-primary text-center">
                          <b>ATTENZIONE!</b><br />Devi sempre indicare <b>tra le opzioni qui sopra</b> quale tipo di documento stai inviando, affinché venga trattato per la finalità corretta.<br />Di default è impostato come Documento Generico, se non selezionato diversamente
                        </div>
                      </div>     
                    </div> */}
                    <div className='row'>
                      <div className="col-12 mb-3">
                        <label className="form-label" htmlFor="titolo">Titolo/Voce di spesa o di incasso <b className='text text-danger'>*</b></label>
                        <input type="text" className="form-control" id="titolo" placeholder="Titolo del documento/Voce di spesa o di incasso" value={titolo} onChange={(e) => setTitolo(e.target.value)} required aria-required="true" />
                      </div>
                      <div className="col-12 mb-3">
                        <div className="input-group mb-3">
                          <label className="form-label" htmlFor="file">Scegli il file dal tuo device
                          <input type="file" className="form-control" name="file" id="file" onChange={handleFileChange} />
                          </label>
                        </div>
                      </div> 
                      <div className="col-12 mb-3 mt-3 text-center"><h4 className='text text-dark'>oppure</h4></div>
                      <div className="col-12 mb-3">
                        <label className="form-label" htmlFor="titolo">Descrivi qui ciò di cui hai bisogno</label>
                        <textarea className="form-control" id="testo" rows="5" placeholder="Scrivi qui la descrizione " value={testo} onChange={(e) => setTesto(e.target.value)}></textarea>
                      </div>                     
                      <div className="col-12 mb-3" style={{textAlign: 'right'}}>
                        <button type="submit" className="btn btn-outline-primary" id="btnUploadUserDoc" style={{marginBottom: '0px'}}><i className="ti-upload" style={{paddingRight: '6px'}}></i> Invia richiesta</button>
                      </div>
                      {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                        <div className="col-12">
                          <div className="alert alert-success mt_20" role="alert">Status aggiornato con successo!<br /> <br />Puoi modificarlo ulteriormente o impostare </div>
                        </div>
                      )}
                    </div>
                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
      </section>
    </>
  );

}

export default DocUtenteUpload;