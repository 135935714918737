import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import TestataOperatore from "./partials/TestataOperatore";
import NavBarOp from "./partials/NavBarOp";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import 'moment/locale/it'; // Importa il locale italiano

function UtenteOperatore() {
  const { userData } = useContext(UserContext);
  const { id } = useParams(); 
  const [utente, setUtente] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [operatori, setOperatori] = useState([]);
  const [formData, setFormData] = useState({
    disponibili: 0,
    residue: 0,
    attivo: false,
    mostraStripe: false,
    formazioneObbligatoria: false
  });
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 
  // Definizione della funzione fetchCliente FUORI da useEffect
  const fetchUtente = async () => { 
    try {        
      const token = localStorage.getItem("token");
      const response = await fetch(
        `/api/utente/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        data.attivo = data.attivo === true;
        data.mostraStripe = data.mostraStripe === true;
        data.formazioneObbligatoria = data.formazioneObbligatoria === true;
        setUtente(data);
        setFormData(data); // Inizializza formData con i dati del cliente
        // Fetch operatori attivi (corretto endpoint)
        const responseOperatori = await fetch(`/api/operatori-attivi`, { // Rimosso /${id}
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
          credentials: "include",
        });
        const dataOperatori = await responseOperatori.json();
        setOperatori(dataOperatori);
      } else {
        throw new Error("Errore nel recupero dell'anagrafica");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchUtente();
  }, [id]);
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeBool = (e) => {
    const { name, checked } = e.target; // Ottieni il valore checked (booleano)
    setFormData({ ...formData, [name]: checked }); // Aggiorna lo stato con il valore booleano
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/utente/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setEditMode(false);        
        fetchUtente(); // Ricarica i dati aggiornati del cliente
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
        setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Errore durante il salvataggio');
      }
    } catch (err) {
      console.error('Errore durante il salvataggio:', err);
      setError('Si è verificato un errore durante il salvataggio');
    }
  };

  return (
    <>
      <NavBarOp />
      <section className="main_content dashboard_part">
        <div className='className="container-fluid g-0'>            
            <TestataOperatore userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box_tittle list_header">
                  <h4>Profilo dell'Attività/Utente</h4>                  
                  {!editMode && ( // Mostra il pulsante "Modifica" solo se non sei in modalità modifica
                    <button className="btn btn-outline-warning btn-sm" onClick={() => setEditMode(true)}>
                      Modifica
                    </button>
                  )}
                </div>
                {error && <p className="error-message">{error}</p>}
                {utente ? (
                  <form onSubmit={handleSubmit}>
                    <div className="col-12 mb_30">
                      <div className="row">
                        <div className="form-group col-md-3 cols-sm-12 mb_20">
                            <label htmlFor="ore"><b className="text text-success">Fatturato anno corrente</b>:</label><br />
                            <input type="number" className="form-control" id="fatturato" name="fatturato" value={formData.fatturato} onChange={handleChange} disabled={!editMode} />
                        </div>
                        <div className="form-group col-md-3 cols-sm-12 mb_20">
                            <label htmlFor="disponibili"><b className="text text-warning">Disponibilità in cassa</b>:</label>
                            <div className="input-group input-group mb-3">
                                <div className="input-group-text">
                                    <span className="" id="inputGroup-sizing-sm">€</span>
                                </div>
                                <input type="number" step="0.1" className="form-control" id="disponibili" name="disponibili" value={formData.disponibili} onChange={handleChange} disabled={!editMode} />
                            </div>
                        </div>
                        <div className="form-group col-md-3 cols-sm-6 mb_20">
                            <label htmlFor="ore"><b className="text text-success">Minuti compresi mensili</b>:</label><br />
                            <input type="text" className="form-control" id="ore" name="ore" value={formData.ore} onChange={handleChange} disabled={!editMode} />
                        </div>                        
                        <div className="form-group col-md-3 cols-sm-6 mb_20">
                            <label htmlFor="residue"><b className="text text-danger">Minuti da conteggiare</b><small><small><br />(attuali: {parseFloat(formData.residue, 0)} ovvero <b className="text text-danger">{((formData.residue / 60).toFixed(2)).replace(".", ",")} ore</b>)</small></small>:</label><br />
                            <input type="hidden" id="residue" name="residue" value="{formData.residue}" disabled={!editMode} />
                            <input type="text" className="form-control" id="residueAdd" name="residueAdd" value={formData.residueAdd} onChange={handleChange} disabled={!editMode} />
                        </div>                                                
                      </div>

                      <div className="row">
                        <div className="form-group col-md-3 cols-sm-6 mb_20">
                            <label htmlFor="ragioneSociale">Registrato dal:</label><br />
                            <b>{moment(formData.registrazione).format('DD MMMM YYYY')}</b>
                        </div>
                        <div className="form-group col-md-3 cols-sm-6 mb_20">
                            <label htmlFor="ragioneSociale">Scadenza:</label><br />
                            <b>{moment(formData.scade).format('DD MMMM YYYY')}</b>
                        </div>                        
                        <div className="form-group col-md-3 cols-sm-6 mb_20">
                            <label htmlFor="ragioneSociale">Quota lorda mensile:</label><br />
                            <b> € {(formData.quota / 100).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                        </div>
                        <div className="form-group col-md-1 cols-sm-6 mb_20">
                            <label htmlFor="ragioneSociale">Attivo:</label><br />
                            <input
                                type="checkbox"
                                id="attivo"
                                name="attivo"
                                checked={formData.attivo}
                                value="true"
                                onChange={handleChangeBool}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-2 cols-sm-6 mb_20">
                            <label htmlFor="formazioneObbligatoria">Soggetto a Formazione:</label><br />
                            <input
                                type="checkbox"
                                id="formazioneObbligatoria"
                                name="formazioneObbligatoria"
                                checked={formData.formazioneObbligatoria}
                                value="true"
                                onChange={handleChangeBool}
                                disabled={!editMode}
                            />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="ragioneSociale">Ragione Sociale:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="ragioneSociale"
                                name="ragioneSociale"
                                value={formData.ragioneSociale}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="nome">Nome:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nome"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="cognome">Cognome:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cognome"
                                name="cognome"
                                value={formData.cognome}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="indirizzo">Indirizzo:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="indirizzo"
                                name="indirizzo"
                                value={formData.indirizzo}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                      
                      <div className="form-group col-md-4 cols-sm-12 mb_20">
                        <label htmlFor="comune">Comune:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="comune"
                            name="comune"
                            value={formData.comune}
                            onChange={handleChange}
                            disabled={!editMode}
                        />
                      </div>
                        <div className="form-group col-md-2 cols-sm-6 mb_20">
                            <label htmlFor="prov">Prov:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="prov"
                                name="prov"
                                value={formData.prov}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-2 cols-sm-6 mb_20">
                            <label htmlFor="cap">CAP:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cap"
                                name="cap"
                                value={formData.cap}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="tel">Telefono:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="tel"
                                name="tel"
                                value={formData.tel}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="cell">Cellulare:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cell"
                                name="cell"
                                value={formData.cell}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>     
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="whatsapp">WhatsApp al n°:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="whatsapp"
                                name="whatsapp"
                                value={formData.whatsapp}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>                      
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="whatsapp">Sito web &nbsp; [http(s)://nomeazienda.ext]:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="url"
                                name="url"
                                value={formData.url}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="mail">Indirizzo e-mail:</label>
                            <input
                                type="mail"
                                className="form-control"
                                id="mail"
                                name="mail"
                                value={formData.mail}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="pec">Indirizzo P.E.C.:</label><br />
                            <input
                                type="mail"
                                className="form-control"
                                id="pec"
                                name="pec"
                                value={formData.pec}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>  
                    </div>

                    <div className="row">                                        
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="codUniv">Codice Univoco/S.D.I.:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="codUniv"
                                name="codUniv"
                                value={formData.codUniv}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="codFisc">Codice Fiscale:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="codFisc"
                                name="codFisc"
                                value={formData.codFisc}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="piva">Partita IVA:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="piva"
                                name="piva"
                                value={formData.piva}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div> 
                    </div>

                    <div className="row">                        
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="regime">Regime:</label><br />
                            <select
                                className="form-select form-control"
                                id="regime"
                                name="regime"
                                value={formData.regime}
                                onChange={handleChange}
                                disabled={!editMode}
                            >
                                <option value="Forfettario">Forfettario</option>
                                <option value="Semplificato">Semplificato</option>
                                <option value="Ordinario">Ordinario</option>
                            </select>
                        </div>
                                             
                        <div className="form-group col-md-2 cols-sm-12 mb_20">
                            <label htmlFor="ritenuta">R.A.</label><br />
                            <select
                                className="form-select form-control"
                                id="ritenuta"
                                name="ritenuta"
                                value={formData.ritenuta}
                                onChange={handleChange}
                                disabled={!editMode}
                            >
                                <option></option>
                                <option value="0">0%</option>
                                <option value="4">4%</option>
                                <option value="15">15%</option>
                            </select>
                        </div> 
                    </div>

                    <div className="row">                                         
                        <div className="form-group col-md-6 cols-sm-12 mb_20">
                            <label htmlFor="fatturazione">Piattaforma Fatturazione:</label><br />
                            {/* <b>{formData.fatturazione}</b> */}
                            <input
                                type="text"
                                className="form-control"
                                id="fatturazione"
                                name="fatturazione"
                                value={formData.fatturazione}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>                                            
                        <div className="form-group col-md-6 cols-sm-12 mb_20">
                            <label htmlFor="urlFatture">URL accesso alla piattaforma:</label><br />
                            {/* <b><a href={formData.urlFatture} target="_blank" rel="noreferrer" style={{color: '#ff7f2a', fontWeight: '700'}}>{formData.urlFatture}</a></b> */}
                            <input
                                type="text"
                                className="form-control"
                                id="urlFatture"
                                name="urlFatture"
                                value={formData.urlFatture}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                      </div>

                       <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="userFatt">Nome Utente piattaforma fatturazione:</label><br />
                            {/* <b>{formData.userFatt}</b> */}
                            <input
                                type="text"
                                className="form-control"
                                id="userFatt"
                                name="userFatt"
                                value={formData.userFatt}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="pwdFatt">Password piattaforma fatturazione:</label><br />
                            {/* <b>{formData.pwdFatt}</b> */}
                            <input
                                type="text"
                                className="form-control"
                                id="pwdFatt"
                                name="pwdFatt"
                                value={formData.pwdFatt}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>

                        <div className="form-group col-md-4 cols-sm-12 mb_20" style={{textAlign: 'right'}}>
                            {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                                <div className="alert alert-success mt_20" role="alert">Dati aggiornati con successo!</div>
                            )}
                            {editMode && ( // Mostra il pulsante "Salva" solo se sei in modalità modifica
                                <button type="submit" className="btn btn-primary mt_20">
                                    Salva
                                </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <p>Caricamento dei tuoi dati...</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default UtenteOperatore;
