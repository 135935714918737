import React, { useState, useContext } from 'react';
import { UserContext } from './UserContext'; // Assumiamo che tu abbia un contesto per l'utente
import { useNavigate } from 'react-router-dom';
import TestataOperatore from "./partials/TestataOperatore";
import NavBarOp from "./partials/NavBarOp";
import Footer from "./partials/Footer";

function CambioPasswordOperatore() {
  const { userData } = useContext(UserContext); // Ottieni i dati dell'utente dal contesto
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogout = () => {
      localStorage.removeItem('token');
      navigate('/'); // Reindirizza al login
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null); // Reset eventuali errori precedenti
    setSuccess(false);

    // Validazione di base (aggiungi controlli più rigorosi se necessario)
    if (formData.newPassword !== formData.confirmPassword) {
      setError('Le nuove password non corrispondono');
      return;
    }

    try {
      const response = await fetch(`/api/cambia-password`, { // Assumiamo che l'endpoint sia /api/change-password
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.token}`, // Includi il token di autenticazione se necessario
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess(true);
        setFormData({ currentPassword: '', newPassword: '', confirmPassword: '' }); // Reset del form
        setTimeout(() => {
           handleLogout();
        }, 2500);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Errore durante il cambio password');
      }
    } catch (err) {
      console.error(err);
      setError('Errore di connessione al server');
      // #vgmaoeeyxWo
    }
  };

  return (
    <>
        <NavBarOp />            
        <section className="main_content dashboard_part">
            <div className='class="container-fluid g-0'>            
                <TestataOperatore userData={userData} />
            </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>Cambio password</h4>
                                </div>
                                <div className="row justify-content-center mt_20">
                                    <div className="col-sm-4">
                                        <div className="alert alert-warning">
                                            Digita la tua password attuale e poi la nuova password (che devi anche confermare per esser sicuri che venga scritta correttamente).<br />
                                            <b>Attenzione:</b> al termine della procedura verrai disconnesso e dovrai accedere con la nuova password.<br />
                                            Ti ricordiamo che la mail di accesso - anche qualora l'avessi cambiata nel tuo profilo - rimane quella di registrazione iniziale.
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center mt_50'>                                        
                                    <div className='col-lg-4'>
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        {success && <div className="alert alert-success">Password cambiata con successo!</div>}

                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                            <label htmlFor="currentPassword">Password Attuale:</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                id="currentPassword" 
                                                name="currentPassword"
                                                value={formData.currentPassword}
                                                onChange={handleChange} 
                                            />
                                            </div>

                                            <div className="form-group mt_30">
                                            <label htmlFor="newPassword">Nuova Password:</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                id="newPassword" 
                                                name="newPassword"
                                                value={formData.newPassword}
                                                onChange={handleChange} 
                                            />
                                            </div>

                                            <div className="form-group mt_30">
                                            <label htmlFor="confirmPassword">Conferma Nuova Password:</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                id="confirmPassword" 
                                                name="confirmPassword"
                                                value={formData.confirmPassword}
                                                onChange={handleChange} 
                                            />
                                            </div>

                                            <button type="submit" className="btn btn-primary mt_30 mb_50" style={{float: 'right'}}>Cambia Password</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </section>
    </>
  );
}

export default CambioPasswordOperatore;