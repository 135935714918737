import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import { useNavigate } from 'react-router-dom';
import TestataOperatore from "./partials/TestataOperatore";
import NavBarOp from "./partials/NavBarOp";
import Footer from "./partials/Footer";

function NotificaOperatoreNew() {
  const { userData } = useContext(UserContext);
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id_utente: id,
    titolo: "",
    testo: "",
    url: ""
  });
  const [error, setError] = useState(null);  
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 
  const [showSubmitButton, setShowSubmitButton] = useState(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSubmitButton(false);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/adminNewNotifica/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (response.ok) {      
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
          setShowSuccessMessage(false);
          navigate(`/amministratore/dettaglio-utente/${id}`); // Reindirizza alla dashboard dopo l'upload
        }, 3000); // Nascondi dopo 3 secondi
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Errore durante il salvataggio');
      }
    } catch (err) {
      console.error('Errore durante il salvataggio:', err);
      setError('Si è verificato un errore durante il salvataggio');
    }
  };

  return (
    <>
      <NavBarOp />
      <section className="main_content dashboard_part">
        <div className='className="container-fluid g-0'>            
            <TestataOperatore userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box_tittle list_header">
                  <h4>Nuova Notifica per questo Utente</h4>
                  <h4>
                    <a href={`/gestione/dettaglio-utente/${id}`} className='btn btn-outline-primary'><i className='ti-control-backward'></i> Dettaglio Utente</a>
                  </h4>
                </div>
                {error && <p className="error-message">{error}</p>}
                
                <form onSubmit={handleSubmit}>
                    <div className="col-12 mb_30">
                        <div className="row">
                            <div className="form-group col-md-4 col-xs-12 mb_20">
                                <label htmlFor="disponibili">Titolo (<small>max 50 caratteri</small>): </label>                            
                                <input type="text" maxLength="50" className="form-control" id="titolo" name="titolo" value={formData.titolo} onChange={handleChange} />
                            </div>
                            <div className="form-group col-md-8 col-xs-12 mb_20">
                                <label htmlFor="disponibili">Testo (<small>max 120 caratteri</small>): </label>
                                <input type="text" maxLength="120" className="form-control" id="testo" name="testo" value={formData.testo} onChange={handleChange} />                                                        
                            </div>                         
                        </div> 

                        <div className="row">
                            <div className="form-group col-xs-12 mb_20" style={{textAlign: 'right'}}>
                                {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                                    <div className="alert alert-success mt_20" role="alert">Notifica inviata con successo!</div>
                                )}
                                {showSubmitButton && ( // Mostra il pulsante solo se showSubmitButton è true
                                    <button type="submit" className="btn btn-success mt_20">Invia adesso</button>
                                )}
                            </div>
                        </div>
                    </div>
                </form>                
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default NotificaOperatoreNew;
