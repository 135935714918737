import React, { useContext, useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";
import BoxFatturato from "./partials/BoxFatturato";
import BoxDisponibili from "./partials/BoxDisponibili";
import BoxDocuNo from "./partials/BoxDocuNo";
import BoxOreDispo from "./partials/BoxOreDispo";
import AzioniRapide from "./partials/AzioniRapide";
import moment from 'moment'; // Importa moment.js
import $ from 'jquery';
import 'datatables.net';
import { abbreviateName } from './utils';
function Dashboard() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [clienti, setClienti] = useState([]); // Stato per i documenti
  const [errorClienti, setClientiError] = useState(null);
  const [fornitori, setFornitori] = useState([]); // Stato per i documenti
  const [errorFornitori, setFornitoriError] = useState(null);
  const [documenti, setDocumenti] = useState([]); // Stato per i documenti
  const [errorDoc, setDocError] = useState(null);
  //   const navigate = useNavigate();

  // Recupero ultimi clienti
  useEffect(() => {   
    const fetchClienti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/clientiHome`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const clienti = await response.json();
            setClienti(clienti); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei Clienti');
            }
        } catch (err) {
            setClientiError(err.message);
        }
    };

    fetchClienti(); 
  }, []);// Chiama la funzione per recuperare i Clienti    

  // Recupero ultimi fornitori
  useEffect(() => {   
    const fetchFornitori = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/fornitHome`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const fornitori = await response.json();
            setFornitori(fornitori); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei Fornitori');
            }
        } catch (err) {
            setFornitoriError(err.message);
        }
    };

    fetchFornitori(); 
  }, []);// Chiama la funzione per recuperare i Clienti    

  // Recupero documenti ricevuti
  useEffect(() => {   
    const fetchDocumenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/docuhome`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const docum = await response.json();
            setDocumenti(docum); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei documenti');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchDocumenti(); 
  }, []);// Chiama la funzione per recuperare i documenti    

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

  useEffect(() => {
  // Funzione per inizializzare o aggiornare la DataTable
  let table;
  const initializeOrUpdateTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        // Inizializza la DataTable solo se non è già stata inizializzata
        table = $(tableRef.current).DataTable({ // Assegna il valore a table
            ordering: true,
            searching: false,
            paging: true,
            info: true,
            dom: "rt",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            language: {
            url: "/js/it-IT.json",
            },
            pageLength: 20,
        });
      }
    };

    // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
    if (tableRef.current) {
        setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
    }

  // Distruggi la DataTable quando il componente viene smontato
  return () => {
    if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
        table.destroy();
        }
    };
    }, [documenti]);

    const handleDownload = async (documentoId, filename) => { // Funzione per gestire il download
        try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/documenti/${documentoId}/scaricato`, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
        });
        if (response.ok) {
            const blob = await response.blob(); // Ottieni il file come blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // Usa il nome file corretto
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setDocumenti(prevDocumenti => prevDocumenti.map(doc =>
            doc._id === documentoId ? { ...doc, scaricato: true } : doc
      ));
        } else {
            throw new Error("Errore durante il download del documento");
        }
        } catch (err) {
        console.error('Errore durante il download:', err);
        // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
        }
    };
  // Esegui l'effetto solo al montaggio del componente
  return (
    <>
        <NavBar />
        <section className="main_content dashboard_part">
            <div className="container-fluid g-0">
                <Testata userData={userData} />
            </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">                    
                            <AzioniRapide />
                            <div className="single_element">
                                <div className="quick_activity">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="quick_activity_wrap">
                                                <BoxDisponibili />
                                                <BoxFatturato />
                                                <BoxOreDispo />
                                                <BoxDocuNo />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    
                    
                        <div className="col-lg-12 col-xl-6">
                            <div className="white_box mb_30 min_430">
                                <div className="box_header  box_header_block ">
                                    <div className="main-title">
                                        <h3 className="mb-0">Documenti ricevuti</h3>
                                    </div>
                                </div>
                                {errorDoc ? (
                                    <tr><td colSpan="4"> <h5>Errore: {errorDoc}</h5> </td></tr>
                                ) : (
                                    <div className="table-responsive">
                                        <table className="table table-striped" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th>Titolo del Documento</th>
                                                    <th>Pubblicato in data</th>
                                                    <th>Già scaricato</th>
                                                </tr>
                                            </thead>                                              
                                            <tbody>
                                                {documenti.map(documento => (
                                                    <tr key={documento._id}>
                                                        <td>
                                                            <p title="Scarica questo documento" style={{color: '#212529', fontWeight: '500', textDecoration: 'none' }}>
                                                                {documento.titolo}
                                                            </p>
                                                        </td>
                                                        <td style={{paddingTop: '20px'}}><p>{moment(documento.pubblicato).format('DD/MM/YY')} h. {moment(documento.pubblicato).format('HH:mm')}</p></td>
                                                        {/* <td style={{paddingTop: '20px'}}><p>{moment(documento.modificato).format('DD/MM/YY')} h. {moment(documento.modificato).format('HH:mm')}</p></td> */}
                                                        <td>
                                                            {!documento.scaricato && (
                                                                <button className="btn btn-sm btn-success" onClick={() => handleDownload(documento._id, documento.filename)} title="Scarica questo documento">
                                                                    Scarica ora!
                                                                </button>
                                                            )}
                                                            {documento.scaricato && (
                                                                <button className="btn btn-sm btn-secondary" onClick={() => handleDownload(documento._id, documento.filename)} title="Scarica nuovamente">
                                                                    Scarica di nuovo
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}                
                                            </tbody>
                                            {/* <tfoot>
                                                <tr>
                                                    <th>Titolo del Documento</th>
                                                    <th>Pubblicato in data</th>
                                                    <th>Già scaricato</th>
                                                </tr>
                                            </tfoot>    */}
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 ">
                            <div className="white_box mb_30 min_430">
                                <div className="box_header box_header_block">
                                    <div className="main-title">
                                        <h3 className="mb-0">Ultimi Clienti inseriti</h3>
                                    </div>
                                </div>
                                
                                {errorClienti ? (
                                    <p>Errore: {errorClienti}</p>
                                ) : (
                                    <div className="QA_table">
                                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                                            <table className="table lms_table_active dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" style={{width: '100%'}}>
                                                <thead>
                                                    <tr role="row">
                                                        <th scope="col" rowSpan="1" colSpan="1" style={{width: '66%', fontSize: '0.8rem'}}>Ragione Sociale</th>
                                                        <th scope="col" rowSpan="1" colSpan="1" style={{width: '34%', fontSize: '0.8rem'}}>Nominativo</th>
                                                    </tr>
                                                </thead>
                                                    <tbody>
                                                        {clienti.map((cliente) => ( // Usare map per iterare sui clienti
                                                            <tr key={cliente._id} role="row" className="odd"> 
                                                                <td> 
                                                                    <a href={`/cliente/${cliente._id}`} title="Vedi anagrafica dettagliata" style={{color: '#ff7f2a', fontWeight: '500'}}>
                                                                    {cliente.ragioneSociale || ""} 
                                                                    </a>
                                                                </td>
                                                               <td>{abbreviateName(cliente.nome)}{cliente.cognome}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                        
                                <div className="box_header box_header_block mt_50">
                                    <div className="main-title">
                                        <h3 className="mb-0">Ultimi Fornitori registrati</h3>
                                    </div>
                                </div>

                                {errorClienti ? (
                                    <p>Errore: {errorFornitori}</p>
                                ) : (
                                    <div className="QA_table">
                                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                                            <table className="table lms_table_active dataTable no-footer dtr-inline" id="DataTables_Table_1" role="grid" aria-describedby="DataTables_Table_0_info" style={{width: '100%'}}>
                                                <thead>
                                                    <tr role="row">
                                                        <th scope="col" rowSpan="1" colSpan="1" style={{width: '66%', fontSize: '0.8rem'}}>Ragione Sociale</th>
                                                        <th scope="col" rowSpan="1" colSpan="1" style={{width: '34%', fontSize: '0.8rem'}}>Nominativo</th>
                                                    </tr>
                                                </thead>
                                                    <tbody>
                                                        {fornitori.map((fornitore) => ( // Usare map per iterare sui clienti
                                                            <tr key={fornitore._id} role="row" className="odd"> 
                                                                <td> 
                                                                    <a href={`/fornitore/${fornitore._id}`} title="Vedi anagrafica dettagliata" style={{color: '#ff7f2a', fontWeight: '500'}}>
                                                                    {fornitore.ragioneSociale || ""} 
                                                                    </a>
                                                                </td>
                                                               <td>{abbreviateName(fornitore.nome)}{fornitore.cognome}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        <Footer />
      </section>
    </>
  );
}

export default Dashboard;