import React, { useState, useEffect } from 'react';

function BoxDocuNo() {
  const [docuNo, setdocuNo] = useState(null); // Inizializza a null
  const [openPrev, setOpenPrev] = useState(null); // Inizializza a null
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchDocuBox = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/boxdocuno`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (response.ok) {
          const data = await response.json();
          setdocuNo(data.totale || 0); // Estrai fatturatoTotale o imposta 0 se null
        } else {
          throw new Error('Errore nel recupero dei documenti da scaricare');
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchDocuBox();
  
  const fetchPrevAperti = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/boxopenprev`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (response.ok) {
          const data = await response.json();
          setOpenPrev(data.totale || 0); // Estrai fatturatoTotale o imposta 0 se null
        } else {
          throw new Error('Errore nel recupero delle fatture');
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchPrevAperti();
  }, []);
  
  return (
    <div className="single_quick_activity">
      <h4>Documenti da scaricare</h4>
      {error ? (
        <p>Errore: {error}</p>
      ) : (
        <h3>
          <span className="counter">{docuNo}</span>
        </h3>
      )}
      <h4 style={{textAlign: 'right'}}>Preventivi aperti</h4>
      {error ? (
        <p>Errore: {error}</p>
      ) : (
        <h3 style={{textAlign: 'right'}}>
          <span className="counter">{openPrev}</span>
        </h3>
      )}
    </div>
  );
}

export default BoxDocuNo;