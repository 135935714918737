import React, { useEffect, useRef } from 'react';
function Footer() {
  const annoCorrente = new Date().getFullYear();
  
  return (
    <div className="footer_part">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className="footer_iner text-center">
              <p>© 2023 - { annoCorrente } Animus Operandi - Designed & Developed by <a href="https://stefanocoggiola.com/"
              rel="noreferrer" target="_blank">Stefano Coggiola</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;