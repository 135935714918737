import React, { useContext, useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import TestataAdmin from "./partials/TestataAdmin";
import NavBarAdmin from "./partials/NavBarAdmin";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import $ from 'jquery';
import 'datatables.net';
import { abbreviateName } from './utils';
function Dashboard() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [utenti, setUtenti] = useState([]); // Stato per i documenti
  const [errorUtenti, setUtentiError] = useState(null);
  const [operatori, setOperatori] = useState([]); // Stato per i documenti
  const [errorOperatori, setOperatoriError] = useState(null);
  const [documenti, setDocumenti] = useState([]); // Stato per i documenti
  const [errorDoc, setDocError] = useState(null);
  const navigate = useNavigate();

  // Recupero ultimi Utenti registrati
  useEffect(() => {   
    const fetchUtenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/utenti`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const utenti = await response.json();
            setUtenti(utenti); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero degli Utenti');
            }
        } catch (err) {
            setUtentiError(err.message);
        }
    };

    fetchUtenti(); 
  }, []);// Chiama la funzione per recuperare gli Utenti   

  // Recupero ultimi fornitori
  useEffect(() => {   
    const fetchOperatori = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/operatori`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const operatori = await response.json();
            setOperatori(operatori); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero degli Operatori');
            }
        } catch (err) {
            setOperatoriError(err.message);
        }
    };

    fetchOperatori(); 
  }, []);// Chiama la funzione per recuperare gli Operatori  

  // Recupero documenti ricevuti
  useEffect(() => {   
    const fetchDocumenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/docuhomeAdmin`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const docum = await response.json();
            setDocumenti(docum); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei documenti');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchDocumenti(); 
  }, []);// Chiama la funzione per recuperare i documenti    

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);
  useEffect(() => {
  // Funzione per inizializzare o aggiornare la DataTable
  let table;
  const initializeOrUpdateTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        // Inizializza la DataTable solo se non è già stata inizializzata
        table = $(tableRef.current).DataTable({ // Assegna il valore a table
            ordering: true,
            searching: false,
            paging: false,
            info: true,
            dom: "rt",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            language: {
            url: "/js/it-IT.json",
            },
            pageLength: 20,
        });
      }
    };

    // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
    if (tableRef.current) {
        setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
    }

  // Distruggi la DataTable quando il componente viene smontato
  return () => {
    if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
        table.destroy();
        }
    };
}, [documenti]);

  // Ref per l'elemento della tabella
  const tableUtentiRef = useRef(null);
  useEffect(() => {
    let tableUtenti;
    const initializeOrUpdateTable = () => {
      if (!$.fn.DataTable.isDataTable(tableUtentiRef.current)) {
        // Inizializza la DataTable solo se non è già stata inizializzata
        tableUtenti = $(tableUtentiRef.current).DataTable({ // Assegna il valore a table
            ordering: true,
            searching: true,
            paging: false,
            info: true,
            dom: "ft",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            language: {
            url: "/js/it-IT.json",
            },
            pageLength: 20,
        });
      }
    };

    // Verifica se tableUtentiRef.current è definito prima di inizializzare o aggiornare
    if (tableUtentiRef.current) {
        setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
    }

    // Distruggi la DataTable quando il componente viene smontato
    return () => {
        if (tableUtenti && tableUtenti.destroy) { // Controlla se table è definito e ha il metodo destroy
            tableUtenti.destroy();
            }
        };
    }, [utenti]);

    const handleDownload = async (item, filename) => { // Funzione per gestire il download
        try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/documentazione/${item}`, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
        });
        if (response.ok) {
            const blob = await response.blob(); // Ottieni il file come blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // Usa il nome file corretto
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // setDocumenti(prevDocumenti => prevDocumenti.map(doc =>
            // doc._id === item ? { ...doc, scaricato: true } : doc
        //  ));
        } else {
            throw new Error("Errore durante il download del documento");
        }
        } catch (err) {
        console.error('Errore durante il download:', err);
        // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
        }
    };
  
    function truncateString(str, num) {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    }

    const options = utenti.map(utente => ({
        value: utente._id, // Il valore dell'opzione sarà l'ID dell'utente
        label: `${utente.ragioneSociale} - ${abbreviateName(utente.nome)} ${utente.cognome}`, // Il testo visualizzato sarà nome e cognome
    }));
    const handleChange = (selectedOption) => {
        navigate(`/amministratore/dettaglio-utente/${selectedOption.value}`);
    };

  return (
    <>
        <NavBarAdmin />
        <section className="main_content dashboard_part">
            <div className="container-fluid g-0">
                <TestataAdmin userData={userData} />
            </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-xl-12">
                            <div className="white_box mb_30 min_430">
                                <div className="box_header box_header_block">
                                    <div className="main-title" style={{whiteSpace: 'nowrap', width: '100%'}}>
                                        <h3 className="mb_0">Seleziona Utente su cui operare: </h3>
                                        <Select
                                            options={options}
                                            onChange={handleChange}
                                            placeholder="Seleziona un utente..."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-xl-6">
                            <div className="white_box mb_30 min_430">
                                <div className="box_header  box_header_block ">
                                    <div className="main-title">
                                        <h3 className="mb-0">Documenti ricevuti</h3>
                                    </div>
                                </div>
                                {errorDoc ? (
                                    <tr><td colSpan="4"> <h5>Errore: {errorDoc}</h5> </td></tr>
                                ) : (
                                    <div className="table-responsive">
                                        <table className="table lms_table_active dataTable no-footer dtr-inline" id="datatable" style={{width: '100%'}}>
                                            <thead>
                                                <tr>
                                                    <th style={{fontSize: '0.8rem', fontWeight: '400', fontStyle: 'italic'}}>Titolo del Documento</th>
                                                    <th style={{fontSize: '0.8rem', fontWeight: '400', fontStyle: 'italic'}}>Mittente</th>
                                                </tr>
                                            </thead>                                              
                                            <tbody>
  {documenti.map(documento => (
    <tr key={documento._id} id={documento._id}>
      {/* ... */}
      <td>
        <p style={{ paddingTop: '10px', color: '#212529', fontWeight: '400', textDecoration: 'none', whiteSpace: 'nowrap' }}>
          {documento.filename ? (
            <>
              <button className="btn btn-sm btn-warning" onClick={() => handleDownload(documento._id, documento.filename)} title={`Scarica:  ${documento.titolo}`}>
                <i className='ti-cloud'></i>
              </button> {truncateString(documento.titolo, 44)} 
            </>
          ) : (
            <>              
              <a href={`/amministratore/messaggio/${documento._id}`} className="btn btn-sm btn-warning" title={`Leggi:  ${documento.titolo}`}>
                <i className='ti-eye'></i>
              </a> {truncateString(documento.titolo, 44)} 
            </>
          )}
        </p>
      </td>
      <td>
        <p style={{paddingTop: '10px', color: '#212529', fontWeight: '600', whiteSpace: 'nowrap', lineHeight: ''}}>
          {documento.ragioneSociale}
          <br />
          <i style={{fontSize: '0.7rem', fontWeight: '300'}}>{moment(documento.pubblicato).format('DD MMMM YYYY')} alle h. {moment(documento.pubblicato).format('HH:mm')}</i>
        </p>
      </td>
    </tr>
  ))}
</tbody>                                            
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6">
                            <div className="white_box mb_30 min_430">
                                <div className="box_header box_header_block">
                                    <div className="main-title">
                                        <h3 className="mb-0"><a href="/amministratore/utenti" className="btn btn-sm btn-outline-primary" style={{float: "right", fontSize: "0.66rem"}}>Tutti</a>
                                        Ultimi Utenti inseriti &nbsp; </h3>
                                    </div>
                                </div>
                                
                                {errorUtenti ? (
                                    <p>Errore: {errorUtenti}</p>
                                ) : (
                                    <div className="QA_table">
                                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                                            <table className="table lms_table_active dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" style={{width: '100%'}} ref={tableUtentiRef}>
                                                <thead>
                                                    <tr role="row">
                                                        <th scope="col" rowSpan="1" colSpan="1" style={{width: '40%', fontSize: '0.8rem', fontWeight: '400', fontStyle: 'italic'}}>Ragione Sociale</th>
                                                        <th className="d-none d-sm-table-cell" scope="col" rowSpan="1" colSpan="1" style={{width: '26%', fontSize: '0.8rem', fontWeight: '400', fontStyle: 'italic'}}>Nominativo</th>
                                                        <th scope="col" rowSpan="1" colSpan="1" style={{width: '34%', fontSize: '0.8rem', fontWeight: '400', fontStyle: 'italic'}}>E-mail</th>
                                                    </tr>
                                                </thead>
                                                    <tbody>
                                                        {utenti.map((utente) => ( // Usare map per iterare sugli Operatori
                                                            <tr key={utente._id} role="row" className="odd"> 
                                                                <td> 
                                                                    <a href={`/amministratore/utente/${utente._id}`} title="Vedi anagrafica dettagliata" style={{color: '#ff7f2a', fontWeight: '500'}}>
                                                                    {utente.ragioneSociale || ""} 
                                                                    </a>
                                                                </td>
                                                               <td className="d-none d-sm-table-cell">{abbreviateName(utente.nome)}{utente.cognome}</td>
                                                               <td><a href={`mailto:${utente.mail}`} style={{color: '#ff7f2a', fontWeight: '500'}}>{utente.mail}</a></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                        
                                <div className="box_header box_header_block mt_50">
                                    <div className="main-title mt_30">                                        
                                        <h3 className="mb-0">
                                            <a href="/amministratore/operatori" className="btn btn-sm btn-outline-primary" style={{float: "right", fontSize: "0.66rem"}}>Tutti</a>
                                            Ultimi Operatori registrati &nbsp; </h3>
                                    </div>
                                </div>

                                {errorOperatori ? (
                                    <p>Errore: {errorOperatori}</p>
                                ) : (
                                    <div className="QA_table">
                                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                                            <table className="table lms_table_active dataTable no-footer dtr-inline" id="DataTables_Table_1" role="grid" aria-describedby="DataTables_Table_0_info" style={{width: '100%'}}>
                                                <thead>
                                                    <tr role="row">
                                                        <th scope="col" rowSpan="1" colSpan="1" style={{width: '40%', fontSize: '0.8rem', fontWeight: '400', fontStyle: 'italic'}}>Nome e Cognome</th>
                                                        <th scope="col" rowSpan="1" colSpan="1" style={{width: '26%', fontSize: '0.8rem', fontWeight: '400', fontStyle: 'italic'}}>Cellulare</th>
                                                        <th className="d-none d-sm-table-cell" scope="col" rowSpan="1" colSpan="1" style={{width: '34%', fontSize: '0.8rem', fontWeight: '400', fontStyle: 'italic'}}>E-mail</th>
                                                    </tr>
                                                </thead>
                                                    <tbody>
                                                        {operatori.map((operatore) => ( // Usare map per iterare sugli Operatori
                                                            <tr key={operatore._id} role="row" className="odd"> 
                                                                <td> 
                                                                    <a href={`/amministratore/operatore/${operatore._id}`} title="Vedi anagrafica dettagliata" style={{color: '#ff7f2a', fontWeight: '500'}}>
                                                                    {operatore.nome || ""}  {operatore.cognome}
                                                                    </a>
                                                                </td>
                                                               <td>{operatore.cell}</td>
                                                               <td className="d-none d-sm-table-cell"><a href={`mailto:${operatore.mail}`} style={{color: '#ff7f2a', fontWeight: '500'}}>{operatore.mail}</a></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        <Footer />
      </section>
    </>
  );
}

export default Dashboard;