import React, { useContext, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { UserContext } from './UserContext'; // Importa il contesto
import TestataAdmin from "./partials/TestataAdmin";
import NavBarAdmin from "./partials/NavBarAdmin";
import Footer from "./partials/Footer";

function MessaggioOperatore() {
    const { userData } = useContext(UserContext);
    const { id } = useParams(); 
    const [messaggio, setMessaggio] = useState(null);
    const [errorDoc, setDocError] = useState(null);

    useEffect(() => {
    const fetchMessaggio = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/messaggio/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const messaggio = await response.json();
            setMessaggio(messaggio[0]); // Aggiorna lo stato con i documenti ricevuti
            console.log(messaggio[0].operatore.nome);
            } else {
            throw new Error('Errore nel recupero del messaggio');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchMessaggio(); 
  }, [id]); // Chiama la funzione per recuperare il messaggio


    return (
        <>
            <NavBarAdmin />            
            <section className="main_content dashboard_part">
                <div className='class="container-fluid g-0'>            
                    <TestataAdmin userData={userData} />
                </div>
                <div className="main_content_iner ">
                    <div className="container-fluid plr_30 body_white_bg pt_30">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="white_box_tittle list_header">
                                    <h3>
                                        Richiesta da{" "}
                                        {messaggio && messaggio.utente ? ( // Controlla se messaggio e messaggio.utente sono definiti
                                            messaggio.utente.ragioneSociale ? (
                                            <b>{messaggio.utente.ragioneSociale}</b>
                                            ) : ( 
                                            <b>{messaggio.utente.nome} {messaggio.utente.cognome}</b>
                                            )
                                        ) : ( 
                                            <></>
                                        )}
                                    </h3>
                                    {messaggio && messaggio.utente ? (
                                        <h5>Operatore: <b>{messaggio.operatore.nome} {messaggio.operatore.cognome}</b></h5>
                                    ) : ( 
                                        <h5>Nessun Operatore</h5>
                                    )}
                                </div>
                                
                                <div className='row'>                                        
                                    <div className='col-lg-12' style={{paddingBottom: '50px'}}>
                                      {messaggio ? ( // Controlla se messaggio è definito
                                            <>
                                            <h5 className="mb_20" style={{fontWeight: '400', color: '#a5adc6'}}>Tipo di richiesta: <b>{messaggio._doc.cosa}</b></h5>
                                            <h4 className="mb_30" style={{fontWeight: '600'}}>{messaggio._doc.titolo}</h4>
                                            <p style={{fontWeight: '400', color: '#222'}}>{messaggio._doc.testo}</p>
                                            </>
                                        ) : (
                                            <p>Dettaglio della richiesta non disponibile</p>
                                        )}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
};

export default MessaggioOperatore;