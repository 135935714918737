import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import { loadStripe } from "@stripe/stripe-js";

function SetupPagamento() {
  const annoCorrente = new Date().getFullYear();
  const { userData } = useContext(UserContext); // Ottieni i dati dell'utente dal contesto
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Verifica se i dati dell'utente sono disponibili
    if (userData) {
      // Se i dati sono presenti, non è necessario recuperarli dal backend
      setIsLoading(false);
    } else {
      // Se i dati non sono presenti, reindirizza al login
      navigate('/login');
    }
  }, [userData]); // Dipendenza da userData per eseguire l'effetto quando cambia

  const handlePaymentSetup = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      // Richiesta al backend per creare la sessione di checkout
      const response = await fetch(`/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
      });

      console.log('Risposta del server:', response); 
      console.log('Tipo di dato della risposta:', typeof response);

      if (!response.ok) {
        throw new Error('Errore nella creazione della sessione Stripe');
      }
      const data = await response.json(); 
      console.log('Dati ricevuti dal backend:', data);
      console.log('Tipo di dato della risposta:', typeof data); // Dovrebbe essere "object"

      const { sessionId } = data; // Estrai sessionId direttamente da data
      const stripe = await loadStripe('pk_test_51O4kf7CDvlFPddED6m2assyTJORlFxwkmVznSvUvvJj9de8xfpp5uvtRp3YDwcC9GKLu13TSbedxdKMmjrhTmY9A00ntXc7b4N');
      stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      console.error('Errore:', err);
      setError('Errore durante il setup del pagamento');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="main_content_iner">
      <div className="container-fluid body_white_bg plr_30 pt_30 pb_30">
        <div className="row justify-content-center mt-5 pt_30 pb_30">
          <div className="col-md-4">
            <div className="modal-content cs_modal">
              <div className="modal-header flexxa">
                <h5 className="modal-title">Accedi ad Animus Operandi</h5>
                <img src="/img/logo-animus-operandi.svg" alt="Animus Operandi" className="logologin"></img>
              </div>
              <div className="modal-body">
                <div className="row">
                    <div className="col-12 justify-content-center">                    
                        {isLoading ? (
                            <p>Caricamento...</p>
                        ) : error ? (
                            <p className="error-message">{error}</p>
                        ) : (
                            <>
                            <h2>Setup Pagamento</h2>
                            <p>
                                Ciao {userData.nome}, per completare la configurazione del tuo account devi impostare il tuo metodo di pagamento.
                            </p>
                            <p>Ti invitiamo a scegliere come metodo di pagamento preferenziale l'Addebito SEPA.<br />
                            <small>(I tempi di autorizzazione e quindi di abilitazione del tuo account potrebbero essere un po' più lunghi, ma è più sicuro).</small></p>
                            <p className="justify-content-center">
                                <button onClick={handlePaymentSetup} className="btn btn-lg btn-primary">Imposta Pagamento</button>
                            </p>
                            </>
                        )}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{minHeight: '120px'}}> </div>
      </div>
      <div className="footer_partGen justify-content-center">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                    <div className="footer_iner text-center">
                        <p>© {annoCorrente} Animus Operandi -  Designed & Developed by <a href="https://stefanocoggiola.com/" target="_blank" rel="noreferrer">Stefano Coggiola</a></p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SetupPagamento;