import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'react-tooltip';
import "react-tooltip/dist/react-tooltip.css";
import { UserContext } from "./UserContext";
import TestataAdmin from "./partials/TestataAdmin";
import NavBarAdmin from "./partials/NavBarAdmin";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import 'moment/locale/it'; // Importa il locale italiano
import $ from 'jquery';
import 'datatables.net';

function FormazioneAdmin() {
  const { userData } = useContext(UserContext);
  const { id } = useParams(); 
  const [utente, setUtente] = useState(null);
  const [copied, setCopied] = useState({ userFatt: false, pwdFatt: false }); // Unico stato per entrambi i campi
  const [documenti, setDocumenti] = useState([]); // Stato per i documenti ricevuti dall'utente
  const [errorDoc, setDocError] = useState(null);
  const [operatore, setOperatore] = useState([]);
  const [formData, setFormData] = useState({
    evaso: false,
  });
  useEffect(() => {
  const fetchUtente = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `/api/utente/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUtente(data); 

        if (data.id_operatore) {
          try {
            const responseOperatore = await fetch(`/api/operatore/${data.id_operatore}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              credentials: "include",
            });

            if (responseOperatore.ok) {
              const dataOperatore = await responseOperatore.json();
              setOperatore(dataOperatore); 
            } else {
              console.error('Errore nel recupero dell\'operatore:', responseOperatore.statusText);
            }
          } catch (error) {
            console.error('Errore nel recupero dell\'operatore:', error);
          }
        }
      } else {
        // Gestisci qui l'errore della prima fetch se necessario
        console.error('Errore nel recupero dell\'utente:', response.statusText);
      } 
    } catch (error) { // Aggiunto blocco catch per la prima fetch
      console.error('Errore nel recupero degli utenti:', error);
    } 
  };

  fetchUtente();
}, [id]);

    const handleCopy = (field) => {
        setCopied({ ...copied, [field]: true });
        setTimeout(() => setCopied({ ...copied, [field]: false }), 4000);
    };

    useEffect(() => {   
    const fetchDocumenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/formazione-admin/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const docum = await response.json();
            setDocumenti(docum); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei documenti');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchDocumenti(); 
  }, [id]); // Chiama la funzione per recuperare i documenti ricevuti

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

useEffect(() => {
  // Inizializza la DataTable per la prima tabella (documenti)
  let table;
  const initializeTable = () => {
    if (!$.fn.DataTable.isDataTable(tableRef.current)) {
      table = $(tableRef.current).DataTable({
        ordering: true,
        searching: true,
        paging: true,
        info: true,
        dom: "frtip",
        buttons: ["copy", "csv", "excel", "pdf", "print"],
        language: {
          url: "/js/it-IT.json",
        },
        pageLength: 20,
      });
    }
  };

  if (tableRef.current) {
    setTimeout(initializeTable, 500);
  }

  // Distruggi la DataTable quando il componente viene smontato
  return () => {
    if (table && table.destroy) {
      table.destroy();
    }
  };
}, [documenti]); // Dipendenza da documenti per aggiornare la tabella

    const handleDownload = async (documentoId, filename) => { // Funzione per gestire il download
        try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/documentazione/${documentoId}`, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
        });
        if (response.ok) {
            const blob = await response.blob(); // Ottieni il file come blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // Usa il nome file corretto
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // setDocumenti(prevDocumenti => prevDocumenti.map(doc =>
            // doc._id === documentoId ? { ...doc, scaricato: true } : doc
            //   ));
        } else {
            throw new Error("Errore durante il download del documento");
        }
        } catch (err) {
        console.error('Errore durante il download:', err);
        // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
        }
    };

  return (
    <>
      <NavBarAdmin />
      <section className="main_content dashboard_part">
        <div className='className="container-fluid g-0'>            
            <TestataAdmin userData={userData} />
        </div>
        <div className="main_content_iner">
          <div className="container-fluid plr_30 body_white_bg pt_30 pd_30">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                        {utente ? ( // Controllo se utente è definito
                            <>
                            <div className="white_box_tittle list_header mb_30">
                                <h4>Formazione assegnata a 
                                  {utente.ragioneSociale ? (
                                  
                                      <span className="text text-info" style={{ fontWeight: "700" }}> {utente.ragioneSociale}</span>
                                  ) : (
                                      <span className="text text-info" style={{ fontWeight: "700" }}> {utente.nome} {utente.cognome}</span>
                                  )}
                                </h4>
                                <p className="text text-info">                                   
                                  <a href={`/amministratore/dettaglio-utente/${id}`} className="btn btn-sm btn-outline-primary">Vedi documenti inviati <i className="ti-control-backward"></i></a> &nbsp; 
                                  <a href={`/amministratore/ricevuti-utente/${id}`} className="btn btn-sm btn-outline-primary">Vedi documenti inviati <i className="ti-control-forward"></i></a>
                                </p>
                              </div>                                                          
                            </>
                        ) : (
                            <p>Caricamento dati utente...</p> // Messaggio di caricamento
                        )}
                        </div>
                        <p><a href={`/amministratore/nuova-formazione/${id}`} className="btn btn-sm btn-success"><i className="ti-plus"></i> Aggiungi nuova formazione</a></p>
            </div>
          </div>
        
          <div className="container-fluid plr_30 body_white_bg pt_30 pd_30">
            <div className="row justify-content-center">
                        <div className="col-xs-12 mb_50" style={{borderBottom: '1px solid #cacaca'}}>
                            {errorDoc ? (
                                <h5>Errore: {errorDoc}</h5>
                            ) : (
                                <div className="table-responsive mb_50">
                                        <table className="table lms_table_active table-striped dataTable no-footer dtr-inline" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                            <thead>
                                                <tr>
                                                  <th style={{fontWeight: '700'}}><i>Assegnato/Scadenza</i></th>
                                                  <th style={{fontWeight: '700'}}><i>Tipo</i></th>
                                                  <th style={{fontWeight: '700'}}><i>Descrizione</i></th>
                                                  <th style={{fontWeight: '700'}}><i>Effettuato</i></th>                                         
                                                </tr>
                                            </thead>                                              
                                            <tbody>
                                            {documenti.map(documento => (
                                                <tr key={documento._id} id={documento._id}>
                                                  <td style={{ paddingTop: '20px' }}>
                                                    <p style={{ color: '#222' }}>{documento.data ? moment(documento.data).format('DD/MM/YYYY') : ''} / <span style={{fontWeight: '500', color: '#dc3545'}}>{documento.data ? moment(documento.scade).format('DD/MM/YYYY') : ''}</span></p>
                                                  </td>
                                                  <td style={{ paddingTop: '20px' }}>
                                                      <p>{documento.tipo}</p>
                                                  </td>
                                                  <td style={{ paddingTop: '20px' }}>
                                                      <p className="text text-dark" style={{fontWeight: '600'}}>{documento.testo}</p>
                                                  </td>
                                                  <td>
                                                      <p>
                                                        {documento.svolta ? (
                                                          <span className="badge bg-success">Effettuata</span>
                                                        ) : (
                                                          <span className="badge bg-danger"><i className="ti-danger"></i> DA FARE</span>
                                                        )}
                                                      </p>
                                                  </td>
                                              </tr>
                                            ))}                
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                  <th style={{fontWeight: '700'}}><i>Assegnato/Scadenza</i></th>
                                                <th style={{fontWeight: '700'}}><i>Tipo</i></th>
                                                <th style={{fontWeight: '700'}}><i>Descrizione</i></th>
                                                <th style={{fontWeight: '700'}}><i>Effettuato</i></th>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
          </div>
        </div>
         <Footer />
      </section>
    </>
  );
}

export default FormazioneAdmin;