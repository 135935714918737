import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";
import moment from "moment";

function Preventivo() {
  const { userData } = useContext(UserContext);
  const { preventivoId } = useParams();
  const [preventivo, setPreventivo] = useState(null);
  const [formData, setFormData] = useState({
    id_utente: userData ? userData.id : "",
    id_cliente: "",
    cliente: "",
    data: moment().format("DD-MM-YYYY"),
    numero: "",
    anno: new Date().getFullYear(),
    titolo: "",
    voci: [
      {
        descrizione: "",
        prezzoUnitario: "",
        quantita: "",
        ivaPercent: 22,
        importoIva: "",
        subtot: "",
      },
    ],
    imponibile: 0,
    tasse: 0,
    bollo: false,
    ritenuta: false,
    importoRitenuta: 0,
    totale: 0,
    status: preventivo ? preventivo.status : "Da inviare", // Imposta lo stato iniziale in base al preventivo
    dataInvio: "",
    ultimoContatto: "",
  });
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const [rifiutato, setRifiutato] = useState(false);
  // const [daRivedere, setDaRivedere] = useState(false);
  // const [accettato, setAccettato] = useState(false);

  // Definizione della funzione fetchCliente FUORI da useEffect
  const fetchPreventivo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `/api/preventivo/${preventivoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setPreventivo(data);
        setFormData(data); // Inizializza formData con i dati del cliente
        // setSelectedStatus(data.status); // Imposta lo stato iniziale della select
      } else {
        throw new Error("Errore nel recupero dell'anagrafica");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchPreventivo();
  }, [preventivoId]);

  const handleChange = (e) => {
    const { name, value } = e.target; // Ottieni il valore del radio button selezionato
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Aggiorna lo stato con il valore del radio button
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Preveniamo l'invio automatico del form
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `/api/preventivi/${preventivoId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Aggiorna lo stato del preventivo nel frontend
        fetchPreventivo(); // Ricarica i dati aggiornati del cliente
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
      } else {
      }
    } catch (err) {
      console.error("Errore durante l'aggiornamento dello stato:", err);
    }
  };

  return (
    <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box_tittle list_header">
                  <h4> </h4>
                </div>
                {error && <p className="error-message">{error}</p>}
                {preventivo ? (
                  <form onSubmit={handleSubmit}>
                    <div className="col-12 mb_30">
                      <div className="row">
                        <div className="form-group col-md-6 cols-sm-12">
                            <h4 style={{lineHeight: '2rem'}}>Preventivo<br />n°  {formData.numero}/{formData.anno} del {moment(formData.data).format('DD/MM/YYYY')}</h4>
                        </div>
                          {preventivo.status === 'Inviato' && (                      
                            <div className="form-group col-md-6 cols-sm-12">
                              <label htmlFor="status">Esito da parte del Cliente:</label>
                              <div className="row">
                                <div className="col-3 text-center">
                                  <label htmlFor="Rifiutato" className="text text-danger">Rifiutato: &nbsp; 
                                  </label>
                                    <input
                                      type="radio"
                                      name="status"
                                      id="Rifiutato"
                                      value="Rifiutato"
                                      checked={formData.status === "Rifiutato"}
                                      onChange={handleChange}
                                      style={{ minHeight: '30px', minWidth: '30px', accentColor: '#dc3545' }}
                                    />
                                </div>
                                
                                <div className="col-3 text-center">
                                  <label htmlFor="Da rivedere" className="text text-warning">Da rivedere: &nbsp; 
                                  </label>
                                    <input
                                      type="radio"
                                      name="status"
                                      id="Da rivedere"
                                      value="Da rivedere"
                                      checked={formData.status === "Da rivedere"}                                      
                                      onChange={handleChange}
                                      style={{ minHeight: '30px', minWidth: '30px', accentColor: '#ffc107' }}
                                    />
                                </div>
                                
                                <div className="col-3 text-center">
                                  <label htmlFor="Accettato" className="text text-success">Accettato: &nbsp; 
                                  </label>
                                    <input
                                      type="radio"
                                      name="status"
                                      id="Accettato"
                                      value="Accettato"
                                      checked={formData.status === "Accettato"}
                                      onChange={handleChange}
                                      style={{ minHeight: '30px', minWidth: '30px', accentColor: '#198754' }}
                                    />
                                </div>
                                <div className="col-3 text-center">
                                  <button type="submit" className="btn btn-sm btn-primary right">Cambia status</button>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      
                      <div className="row">
                        {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                          <div className="col-12">
                            <div className="alert alert-success mt_20" role="alert">Status aggiornato con successo!<br /> <br />Puoi modificarlo ulteriormente o impostare </div>
                          </div>
                        )}
                      </div>

                      <div className="row">
                        <div className="form-group col-md-6 cols-sm-12">
                            <label htmlFor="cliente">Cliente:</label><br /><br />
                            <h4>{formData.cliente}</h4>
                        </div>
                        <div className="form-group col-md-6 cols-sm-12">
                            <label htmlFor="titolo">Titolo:</label><br /><br />
                            <h4>{formData.titolo}</h4>
                        </div>
                      </div>
                      <div className="row mt_30">
                        <div className="col-12"><h5 style={{color: '#a5adc6'}}>Dettaglio Prodotti/Servizi</h5></div>
                        <div className="col-12">
                            {/* Voci del preventivo */}
                            <div className="voci-container">
                                <div className="row">
                                    <div className="col-md-6 mb-3"><b>Prodotto/Servizio</b></div>
                                    <div className="col-md-2 mb-3" style={{textAlign: 'right'}}><b>Prezzo unitario</b></div>
                                    <div className="col-md-1 mb-3" style={{textAlign: 'right'}}><b>Quantità</b></div>
                                    <div className="col-md-1 mb-3" style={{textAlign: 'right'}}><b>IVA</b></div>
                                    <div className="col-md-2 mb-3" style={{textAlign: 'right'}}><b>Subtotale</b></div>
                                </div>
                            {formData.voci.map((voce, index) => (
                                <div key={index} className="row">
                                    <div className="col-md-6 mb-3"> {/* Descrizione */}
                                        {voce.descrizione}
                                    </div>

                                    <div className="col-md-2 mb-3" style={{textAlign: 'right'}}> {/* Prezzo Unitario */}
                                        € {voce.prezzoUnitario.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </div>

                                    <div className="col-md-1 mb-3" style={{textAlign: 'right'}}> {/* Quantità */}
                                        {voce.quantita.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </div>

                                    <div className="col-md-1 mb-3" style={{textAlign: 'right'}}> {/* IVA Percentuale */}
                                        {voce.ivaPercent.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%
                                    </div>

                                    <div className="col-md-2 mb-3" style={{textAlign: 'right'}}> {/* Subtotale */}
                                        € {voce.subtot.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})} 
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                      </div>
                      <div className="row mt_30">
                        <div className="col-md-10 col-xs-6" style={{textAlign: 'right'}}>
                            Imponibile
                        </div>
                        <div className="col-md-2 col-xs-6" style={{textAlign: 'right'}}>
                            <b>€ {formData.imponibile.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</b>
                        </div>
                      </div>
                      
                      <div className="row mt_30">
                        <div className="col-md-10 col-xs-6" style={{textAlign: 'right'}}>
                            Bollo
                        </div>
                        <div className="col-md-2 col-xs-6" style={{textAlign: 'right'}}>
                            <b>€ {formData.bollo.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}) || 0}</b>
                        </div>
                      </div>                      
                      
                      <div className="row mt_30">
                        <div className="col-md-10 col-xs-6" style={{textAlign: 'right'}}>
                            Ritenuta d'Acconto {formData.ritenuta}
                        </div>
                        <div className="col-md-2 col-xs-6" style={{textAlign: 'right'}}>
                            <b>€ {formData.importoRitenuta.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}) || "-"}</b>
                        </div>
                      </div>                      
                      
                      <div className="row mt_30">
                        <div className="col-md-10 col-xs-6" style={{textAlign: 'right'}}>
                            Totale
                        </div>
                        <div className="col-md-2 col-xs-6" style={{textAlign: 'right'}}>
                            <b style={{fontSize: '1.3rem'}}>€ {formData.totale.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}) || "-"}</b>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <p>Caricamento dati fornitore...</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Preventivo;
