import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import 'moment/locale/it'; // Importa il locale italiano
import $ from 'jquery';
import 'datatables.net';
function NotificheTot() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [notifiche, setNotifiche] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {   
    const fetchNotifiche = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(`/api/notificheAll`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setNotifiche(data);
        } else {
          throw new Error("Errore nel recupero delle notifiche");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifiche();

    const intervalId = setInterval(fetchNotifiche, 300000); // 300000 ms = 5 minuti

    // Pulisci l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, []); 

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

  useEffect(() => {
    // Funzione per inizializzare o aggiornare la DataTable
    let table;
    const initializeOrUpdateTable = () => {
        if (!$.fn.DataTable.isDataTable(tableRef.current)) {
            // Inizializza la DataTable solo se non è già stata inizializzata
            table = $(tableRef.current).DataTable({ // Assegna il valore a table
                ordering: false,
                searching: true,
                paging: true,
                info: true,
                dom: "frtip",
                buttons: ["copy", "csv", "excel", "pdf", "print"],
                language: {
                url: "/js/it-IT.json",
                },
                pageLength: 20,
            });
        }
        };

        // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
        if (tableRef.current) {
            setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
        }

    // Distruggi la DataTable quando il componente viene smontato
    return () => {
        if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
            table.destroy();
        }
    };
    }, [notifiche]);

    return (
      <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
            <div className="main_content_iner">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header pb_50">
                                <h4>Notifiche ricevute</h4>
                            </div>
                                <p className='alert alert-info'>Utilizzare il menù a discesa qui in alto per visualizzare i dettagli di quelle non ancora visualizzate.</p>
                            {error ? (
                                <h5>Errore: {error}</h5>
                            ) : (
                                <div className="table-responsive mb_50">
                                    <table className="table table-striped" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                        <thead>
                                            <tr>
                                                <th><i style={{fontWeight: '700'}}>Titolo</i></th>
                                                <th><i style={{fontWeight: '700'}}>Testo</i></th>
                                                <th><i style={{fontWeight: '700'}}>Data</i></th>
                                                <th><i style={{fontWeight: '700'}}>Letto</i></th>
                                            </tr>
                                        </thead>                                              
                                        <tbody>
                                            {notifiche.map(notifica => (
                                                <tr key={notifica._id}>
                                                    <td><p style={{fontWeight: '700'}}>{notifica.titolo}</p></td>
                                                    <td><p>{notifica.testo}</p></td>
                                                    <td><p style={{fontSize: '0.7rem'}}>{moment(notifica.data).format("DD/MM/YYYY")} alle {moment(notifica.data).format("HH:mm")}</p></td>
                                                    <td>
                                                        <p>
                                                            {notifica.letto ? (
                                                                <span className='badge bg-success'>Già letto</span>
                                                            ) : (
                                                                <span className='badge bg-danger'>Non letto</span>
                                                            )}                                                        
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))}                                            
                                        </tbody>
                                        <tfoot>
                                            <tr>                                                
                                                <th><i style={{fontWeight: '700'}}>Titolo</i></th>
                                                <th><i style={{fontWeight: '700'}}>Testo</i></th>
                                                <th><i style={{fontWeight: '700'}}>Data</i></th>
                                                <th><i style={{fontWeight: '700'}}>Letto</i></th>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
      </>
    );
  }

export default NotificheTot;
