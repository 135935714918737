import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../UserContext'; // Importa il contesto
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faArrowAltCircleDown, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';

function NavBar() {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext); // Ottieni userData dal contesto
    const [activeMenu, setActiveMenu] = useState(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);    
    const [displayedUserData, setDisplayedUserData] = useState(userData);

     useEffect(() => {    
      
  }, [userData]);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);

        // Utilizza jQuery per aggiungere/rimuovere la classe
        $('.sidebar').toggleClass('active_sidebar', isMobileMenuOpen); 
        };

    useEffect(() => {
        // Simula il comportamento di jQuery per l'apertura/chiusura del menu
        $('.sidebar_icon').on('click', toggleMobileMenu);

        // Pulisci l'event listener quando il componente viene smontato
        return () => {
            $('.sidebar_icon').off('click', toggleMobileMenu);
        };
    });

    const toggleSubMenu = (menuName) => {
        setActiveMenu(activeMenu === menuName ? null : menuName);
    };
    
    
  useEffect(() => {
    // Aggiorna i dati visualizzati solo se userData cambia
    setDisplayedUserData(userData);
  }, [userData]);
  
  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token'); 
    setDisplayedUserData(userData);
    navigate('/');
  };

    return (        
       <nav className={`sidebar ${isMobileMenuOpen ? "active_sidebar" : ""}`}>
            <div className="sidebar_icon d-lg-none" onClick={() => {toggleMobileMenu();}}>
                <i className="ti-menu"></i>
            </div>

            <div className="logo d-flex justify-content-between">
                <a href="/dashboard"><img src="/img/logo-animus-operandi.svg" alt="Animus Operandi" /></a>
                <div className="sidebar_close_icon d-lg-none" onClick={() => {toggleMobileMenu();}}>
                    <i className="ti-close"></i>
                </div>
            </div>
            <ul id="sidebar_menu">
                <li className="mm-active">
                    <a href="/dashboard">
                        <i className="ti-home"></i>
                        <span>Dashboard</span>                        
                    </a>
                </li>
        
                <li className={activeMenu === 'documenti' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!" onClick={() => toggleSubMenu('documenti')} aria-expanded={activeMenu === 'documenti'}>
                        <i className="ti-import"></i>
                        <span>I tuoi documenti
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'documenti' ? 'rotate-180' : ''} style={{float: 'right'}} /> {/* Aggiungi l'icona */}
                        </span>
                    </a>
                    <ul style={{ display: activeMenu === 'documenti' ? 'block' : 'none' }}>
                        <li><a href="/documenti-ricevuti">Documenti che hai ricevuto</a></li>
                        <li><a href="/documenti-personali">Documenti che hai caricato</a></li>                        
                        <li className="mt-3 pt-3" style={{borderTop: '1px dotted #CCC'}}><a href="/certificati">I tuoi certificati</a></li>
                        <li className="mb-5"><a href="/nuovo-certificato">Carica nuovo</a></li>
                    </ul>
                </li>
        
                <li className={activeMenu === 'anagrafiche' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!"  onClick={() => toggleSubMenu('anagrafiche')} aria-expanded={activeMenu === 'anagrafiche'}>
                        <i className="ti-agenda"></i>
                        <span>Anagrafiche
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'anagrafiche' ? 'rotate-180' : ''} style={{float: 'right'}} /> {/* Aggiungi l'icona */}
                        </span>                        
                    </a>
                    <ul style={{ display: activeMenu === 'anagrafiche' ? 'block' : 'none' }}>
                        <li><a href="/clienti">Clienti</a></li>
                        <li><a href="/fornitori">Fornitori</a></li>
                        <li className="mt-3 pt-3 mb-5" style={{borderTop: '1px dotted #CCC'}}><a href="/nuova-anagrafica">Nuova anagrafica</a></li>
                    </ul>
                </li>        

                <li className={activeMenu === 'richiedi' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!" onClick={() => toggleSubMenu('richiedi')} aria-expanded={activeMenu === 'richiedi'}>
                        <i className="ti-clip"></i>
                        <span>Richiedi & Registra
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'richiedi' ? 'rotate-180' : ''} style={{float: 'right'}} /> {/* Aggiungi l'icona */}
                        </span>
                    </a>
                    <ul style={{ display: activeMenu === 'richiedi' ? 'block' : 'none' }}>
                        <li><a href="/upload-documenti/?i=Registrazione in Prima Nota">Movimenti Prima Nota</a></li>
                        <li><a href="/nuovo_preventivo">Emissione preventivo</a></li>
                        <li><a href="/nuova_fattura">Emissione fattura</a></li>
                        <li><a href="/upload-documenti/?i=Contratto da rivedere">Redazione di un contratto</a></li>
                        <li><a href="/upload-documenti?i=Documenti Generici">Documento generico</a></li>
                        
                        <li className="mt-3 pt-3 mb-5" style={{borderTop: '1px dotted #CCC'}}><a href="/assistenza-crediti">Assistenza recupero crediti</a></li>
                    </ul>
                </li>
                  
                <li>
                    <a href="/elenco-notifiche">
                        <i className="ti-bell"></i>
                        <span>Notifiche ricevute</span>
                    </a>
                </li>

                <li>
                    <a href="https://tidycal.com/animusoperandi/15-minuti" target="_blank" rel="noreferrer">
                        <i className="ti-headphone-alt"></i>
                        <span>Il tuo Consulente</span>
                    </a>
                </li>
        
                <li>
                    <a href="/convenzioni">
                        <i className="ti-reload"></i>
                        <span>Servizi convenzionati</span>
                    </a>
                </li>
        
                <li>
                    <a href="/supporto">
                        <i className="ti-help-alt"></i>
                        <span>Supporto tecnico</span>
                    </a>
                </li>

                {displayedUserData && displayedUserData.formazione ? (
                    <li>
                        <a href="/formazione">
                            <i className="ti-crown"></i>
                            <span>Formazione obbligatoria</span>
                        </a>
                    </li>
                ) : (
                    <></>
                )}
            
                <li className="mt-3 pt-3" style={{borderTop: "1px solid #CCC"}}>
                    <a href="#!" onClick={handleLogout}>
                        <i className="ti-power-off text text-danger"></i>
                        <span className="text-danger">Uscita</span>
                    </a>
                </li>
        
                
                
                {/* 

                <li>
                    <a href="/assistenza-crediti">
                        <i className="ti-money"></i>
                        <span>Assistenza Crediti</span>
                    </a>
                </li>
                
                <li>
                    <a href="/upload-documenti/?i=Registrazione in Prima Nota">
                        <i className="ti-split-h"></i>
                        <span>Comunica movimenti<br /><i style={{fontSize: '0.66rem'}}>(Prima Nota)</i></span>
                    </a>
                </li>

                <li>
                    <a href="/nuovo_preventivo">
                        <i className="ti-files"></i>
                        <span>Richiedi preventivo</span>
                    </a>
                </li>              
        
                <li>
                    <a href="/nuova_fattura">
                        <i className="ti-stamp"></i>
                        <span>Richiedi fattura</span>
                    </a>
                </li> 

                <li>
                    <a href="/upload-documenti/?i=Contratto da rivedere">
                        <i className="ti-marker-alt"></i>
                        <span>Richiedi contratto</span>
                    </a>
                </li> 

                <li className={activeMenu === 'certificazioni' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!"  onClick={() => toggleSubMenu('certificazioni')} aria-expanded={activeMenu === 'anagrafiche'}>
                        <i className="ti-medall"></i>
                        <span>Certificati
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'certificazioni' ? 'rotate-180' : ''} style={{float: 'right'}} />
                        </span>                        
                    </a>
                    <ul style={{ display: activeMenu === 'certificazioni' ? 'block' : 'none' }}>
                        <li><a href="/certificati">Le tue certificazioni</a></li>
                        <li><a href="/nuovo-certificato">Carica nuovo</a></li>
                    </ul>
                </li> 
                
                <li className={activeMenu === 'catalogo' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!"  onClick={() => toggleSubMenu('catalogo')} aria-expanded={activeMenu === 'catalogo'}>
                        <i className="ti-list"></i>
                        <span>Prezzi Prodotti/Servizi
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'catalogo' ? 'rotate-180' : ''} style={{float: 'right'}} />
                        </span>
                    </a>
                    <ul style={{ display: activeMenu === 'catalogo' ? 'block' : 'none' }}>
                        <li><a href="/catalogo">Listino</a></li>
                        <li><a href="/nuovo-listino">Aggiungi Prodotto/Servizio</a></li>
                    </ul>
                </li>
                
                <li className={activeMenu === 'preventivi' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!"  onClick={() => toggleSubMenu('preventivi')} aria-expanded={activeMenu === 'preventivi'}>
                        <i className="ti-files"></i>
                        <span>Preventivi
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'preventivi' ? 'rotate-180' : ''} style={{float: 'right'}} />
                        </span>
                    </a>
                    <ul style={{ display: activeMenu === 'preventivi' ? 'block' : 'none' }}>
                        <li><a href="/preventivi">Storico</a></li>
                        <li><a href="/nuovo_preventivo">Richiedi nuovo preventivo</a></li>
                    </ul>
                </li>
                
                <li className={activeMenu === 'fatture' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!"  onClick={() => toggleSubMenu('fatture')} aria-expanded={activeMenu === 'fatture'}>
                        <i className="ti-stamp"></i>
                        <span>Fatture attive
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'fattire' ? 'rotate-180' : ''} style={{float: 'right'}} />
                        </span>
                    </a>
                    <ul style={{ display: activeMenu === 'fatture' ? 'block' : 'none' }}>
                        <li><a href="/fatture">Storico</a></li>
                        <li><a href="/nuova_fattura">Richiedi nuova fattura</a></li>
                    </ul>
                </li>
                
                <li className={activeMenu === 'contratti' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!"  onClick={() => toggleSubMenu('contratti')} aria-expanded={activeMenu === 'contratti'}>
                        <i className="ti-marker-alt"></i>
                        <span>Contratti
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'contratti' ? 'rotate-180' : ''} style={{float: 'right'}} />
                        </span>
                    </a>
                    <ul style={{ display: activeMenu === 'contratti' ? 'block' : 'none' }}>
                        <li><a href="/contratti">Storico</a></li>
                        <li><a href="/upload-documenti/?i=Contratto da rivedere">Richiedi nuovo contratto</a></li>
                    </ul>
                </li> */}
                
            </ul>
        </nav>
    )
};

export default NavBar;