import React, { useState, useEffect } from 'react';

function BoxDisponibili() {
  const [utente, setUtente] = useState(null);
  const [error, setError] = useState(null);

  const fetchUtente = async () => { 
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `/api/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.user.disponibili && data.user.disponibili.$numberDecimal) { // Accedi a data.user.disponibili
          data.user.disponibili = parseFloat(data.user.disponibili.$numberDecimal);
        }
        setUtente(data.user); // Imposta l'intero oggetto utente nello stato
      } else {
        throw new Error("Errore nel recupero dell'anagrafica");
      }
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    fetchUtente();
  }, []);
  
  return (
    <div className="single_quick_activity">
      <h4>Disponibilità in cassa</h4>
      <h3>
        {error ? (
          <div>Errore: {error}</div>
        ) : utente ? (
          <div>€ <span className="counter">{utente?.disponibili ? utente.disponibili.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</span></div>
        ) : (
          <p>Sto calcolando...</p> // Mostra un messaggio di caricamento
        )}
      </h3>
    </div>
  );
}

export default BoxDisponibili;