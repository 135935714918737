import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext'; // Importa il contesto
import { useNavigate } from 'react-router-dom';
import Notifiche from "./Notifiche";
function Testata() {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  // Stato per i dati dell'utente nel componente
  const [imageExists, setImageExists] = useState(true); // Inizia assumendo che l'immagine esista
  const [displayedUserData, setDisplayedUserData] = useState(userData);

  useEffect(() => {    
      
  }, [userData]);

  useEffect(() => {
    setImageExists(false); // Se l'immagine non si carica, imposta imageExists a false
  }, [userData?.item]); // Dipendenza da userData.item per ricaricare se cambia l'utente

  const initials = (userData?.nome?.charAt(0) || '') + (userData?.cognome?.charAt(0) || ''); // Iniziali

  const circleStyle = {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: '#3b76ef',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '24px', // Regola la dimensione del font delle iniziali
    fontWeight: 'bold',
  };

  useEffect(() => {
    // Aggiorna i dati visualizzati solo se userData cambia
    setDisplayedUserData(userData);
  }, [userData]);
  
  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token'); 
    setDisplayedUserData(userData);
    navigate('/');
  };
  return (
    <div className="row">
      <div className="col-lg-12 p-0">
        <div className="header_iner d-flex justify-content-between align-items-center">
          <div className="sidebar_icon d-lg-none">
            <i className="ti-menu"></i>
          </div>
          <div className="serach_field-area">   
            <h6>Area Riservata di:</h6>
            {displayedUserData && displayedUserData.ragioneSociale ? (
              <h4>{displayedUserData && displayedUserData.ragioneSociale}</h4>
            ) : (
              <h4>{displayedUserData && displayedUserData.nome} {displayedUserData && displayedUserData.cognome}</h4>
            )}
            {displayedUserData && displayedUserData.indirizzo} -{" "}
            {displayedUserData && displayedUserData.cap}{" "}
            {displayedUserData && displayedUserData.comune} (
            {displayedUserData && displayedUserData.prov})<br />
            Telefono: {displayedUserData && displayedUserData.tel} -{" "}
            {displayedUserData && displayedUserData.cell}<br />
            Codice Fiscale: {displayedUserData && displayedUserData.codFisc} - Partita
            IVA: {displayedUserData && displayedUserData.piva}
          </div>
          <div className="header_right d-flex justify-content-between align-items-center">
            <div className="header_notification_warp d-flex align-items-center">
              {displayedUserData && displayedUserData.role === "user" && <Notifiche />} {/* Rendering condizionale di Notifiche */}
            </div>
            <div className="profile_info">
              {imageExists ? (
                <div style={circleStyle}>
                  <img
                    src={`/users/${userData?.item}/logo.jpg`}
                    alt={userData?.ragioneSociale}
                  />
                </div>
              ) : (
                <div style={circleStyle}>{initials.toUpperCase()}</div>
              )}
              <div className="profile_info_iner">
                <p className="text-white">
                  Ciao {displayedUserData && displayedUserData.nome}!
                </p>
                {/* <h5>{userData && userData.utente}</h5> */}
                <div className="profile_info_details">                  
                      <a href="/profilo">
                        Profilo <i className="ti-user"></i>
                      </a>
                      <a href="/cambio-password">
                        Cambia password <i className="ti-unlock"></i>
                      </a>
                  <hr />
                  <a href="#!" onClick={handleLogout}>
                    Uscita <i className="ti-shift-right text text-danger"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testata;
