import React, { useContext, useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import TestataOperatore from "./partials/TestataOperatore";
import NavBarOp from "./partials/NavBarOp";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
// import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import 'datatables.net';

function RichiesteRicevuteOperatore() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [documenti, setDocumenti] = useState([]); // Stato per i documenti
  const [errorDoc, setDocError] = useState(null);
//   const navigate = useNavigate();

  useEffect(() => {   
    const fetchDocumenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/docuOperatore`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const docum = await response.json();
            setDocumenti(docum); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei documenti');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchDocumenti(); 
  }, []);// Chiama la funzione per recuperare i documenti    

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

  useEffect(() => {
  // Funzione per inizializzare o aggiornare la DataTable
  let table;
  const initializeOrUpdateTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        // Inizializza la DataTable solo se non è già stata inizializzata
        table = $(tableRef.current).DataTable({ // Assegna il valore a table
            ordering: true,
            searching: true,
            paging: true,
            info: true,
            dom: "frtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            language: {
            url: "/js/it-IT.json",
            },
            pageLength: 20,
        });
      }
    };

    // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
    if (tableRef.current) {
        setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
    }

  // Distruggi la DataTable quando il componente viene smontato
  return () => {
    if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
        table.destroy();
    }
  };
}, [documenti]);

    const handleDownload = async (item, filename) => { // Funzione per gestire il download
        try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/documentazione/${item}`, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
        });
        if (response.ok) {
            const blob = await response.blob(); // Ottieni il file come blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // Usa il nome file corretto
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // setDocumenti(prevDocumenti => prevDocumenti.map(doc =>
            // doc._id === documentoId ? { ...doc, scaricato: true } : doc
            //   ));
        } else {
            throw new Error("Errore durante il download del documento");
        }
        } catch (err) {
        console.error('Errore durante il download:', err);
        // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
        }
    };

    return (
      <>
      <NavBarOp />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <TestataOperatore userData={userData} />
        </div>
            <div className="main_content_iner">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>Documenti ricevuti</h4>
                            </div>
                            {errorDoc ? (
                                <h5>Errore: {errorDoc}</h5>
                            ) : (
                                <div className="table-responsive mb_50">
                                        <table className="table lms_table_active table-striped dataTable no-footer dtr-inline" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th style={{fontWeight: '400', fontStyle: 'italic'}}>Tipo di richiesta</th>
                                                    <th style={{fontWeight: '400', fontStyle: 'italic'}}>Documento</th>
                                                    <th style={{fontWeight: '400', fontStyle: 'italic'}}>Mittente</th>
                                                </tr>
                                            </thead>                                              
                                            <tbody>
                                            {documenti.map(documento => (
                                                <tr key={documento._id} id={documento._id}>
                                                    <td>
                                                        <p style={{color: '#212529', fontWeight: '600', textDecoration: 'none', whiteSpace: 'nowrap' }}>{documento._doc.cosa}</p>
                                                    </td>
                                                    <td title={`Scarica:  ${documento.titolo}`}>
                                                        <p style={{color: '#212529', fontWeight: '400', textDecoration: 'none' }}>
                                                            {documento._doc.filename ? (
                                                                <>
                                                                <button className="btn btn-sm btn-warning" onClick={() => handleDownload(documento._doc._id, documento._doc.filename)} title={`Scarica:  ${documento._doc.titolo}`}>
                                                                    <i className='ti-cloud'></i>
                                                                    </button> {documento._doc.titolo} 
                                                                </>
                                                            ) : (
                                                                <>
                                                                <a href={`/gestione/messaggio/${documento._doc._id}`} className="btn btn-sm btn-warning" title={`Leggi:  ${documento.titolo}`}>
                                                                    <i className='ti-eye'></i>
                                                                </a> {documento._doc.titolo} 
                                                                </>
                                                            )}
                                                        </p>
                                                    </td>                                                        
                                                    <td><p style={{paddingTop: '10px', color: '#212529', fontWeight: '600', whiteSpace: 'nowrap', lineHeight: ''}}>
                                                        {documento.utente.ragioneSociale ? (
                                                            `${documento.utente.ragioneSociale}`
                                                        ) : (
                                                            `${documento.utente.nome} ${documento.utente.cognome}`
                                                        )}
                                                        <br /><i style={{fontSize: '0.7rem', fontWeight: '300'}}>{moment(documento._doc.pubblicato).format('DD MMMM YYYY')} alle h. {moment(documento._doc.pubblicato).format('HH:mm')}</i></p></td>                                                    
                                                </tr>
                                            ))}                
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Tipo di richiesta</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Titolo del Documento</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Mittente</th>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
      </>
    );
  }

export default RichiesteRicevuteOperatore;
