import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext(null);

function UserProvider({ children }) {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Recupera i dati dell'utente dal localStorage al caricamento
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUserData(JSON.parse(storedUser));
    }
  }, []);

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };