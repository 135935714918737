import React, { useState, useEffect } from 'react';
// import { UserContext } from '../UserContext'; 

function BoxFatturato() {
  var [fatturato, setFatturato] = useState(null); // Inizializza a null
  const [error, setError] = useState(null);
  const annoCorrente = new Date().getFullYear(); // Ottieni l'anno corrente

  useEffect(() => {
    const fetchFatturato = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/boxfatturato`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (response.ok) {
          const data = await response.json();
          const fatturato = data[0].fatturato;
          // fatturato = fatture.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2});
          setFatturato(fatturato); // Estrai fatturatoTotale o imposta 0 se null
        } else {
          throw new Error('Errore nel recupero delle fatture');
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchFatturato();
  }, []);

  return (
    <div className="single_quick_activity">      
      <h4>Fatturato del {annoCorrente}</h4>
      {error ? (
        <p>Errore: {error}</p>
      ) : (
        <h3>€ <span className="counter">
            {typeof fatturato === 'number' && fatturato !== null ? (
              fatturato.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            ) : (
              0 // O qualsiasi altro valore di default che desideri mostrare mentre i dati vengono caricati
            )}
          </span></h3>
      )}      
    </div>
  );
}

export default BoxFatturato;