import React, { useContext, useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";
import $ from 'jquery';
import 'datatables.net';
function ElencoClienti() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [clienti, setClienti] = useState([]); // Stato per i Clienti
  const [errorDoc, setDocError] = useState(null);
//   const navigate = useNavigate();

  useEffect(() => {   
    const fetchClienti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/clienti`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const clienti = await response.json();
            setClienti(clienti); // Aggiorna lo stato con i Clienti ricevuti
            } else {
            throw new Error('Errore nel recupero dei Clienti');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchClienti(); 
  }, []);// Chiama la funzione per recuperare i Clienti    

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

  useEffect(() => {
    // Funzione per inizializzare o aggiornare la DataTable
    let table;
    const initializeOrUpdateTable = () => {
        if (!$.fn.DataTable.isDataTable(tableRef.current)) {
            // Inizializza la DataTable solo se non è già stata inizializzata
            table = $(tableRef.current).DataTable({ // Assegna il valore a table
                ordering: true,
                searching: true,
                paging: true,
                info: true,
                dom: "frtip",
                buttons: ["copy", "csv", "excel", "pdf", "print"],
                language: {
                url: "/js/it-IT.json",
                },
                pageLength: 10,
            });
        }
        };

        // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
        if (tableRef.current) {
            setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
        }

    // Distruggi la DataTable quando il componente viene smontato
    return () => {
        if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
            table.destroy();
        }
    };
    }, [clienti]);

    return (
      <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>Anagrafiche dei Tuoi Clienti</h4>
                            </div>
                            {errorDoc ? (
                                <tr><td colspan="4"> <h5>Errore: {errorDoc}</h5> </td></tr>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table table-striped" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                        <thead>
                                            <tr>
                                                <th>Ragione Sociale</th>
                                                <th>Nome e Cognome</th>
                                                <th>Telefono</th>
                                                <th>E-mail</th>
                                            </tr>
                                        </thead>                                              
                                        <tbody>
                                            {clienti.map(cliente => (
                                                <tr key={cliente._id}>
                                                    <td>
                                                        <p><a href={`/cliente/${cliente._id}`} title="Scarica una copia di questo documento" style={{fontWeight: '500'}}>
                                                            {cliente.ragioneSociale} &nbsp; <i className="ti-pencil"></i>
                                                        </a></p>
                                                    </td>
                                                    <td><p>{cliente.nome} {cliente.cognome}</p></td>
                                                    <td><p>{cliente.tel}</p></td>
                                                    <td><p><a href={`mailto:${cliente.mail}`}>{cliente.mail}</a></p></td>
                                                </tr>
                                            ))}                                            
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Ragione Sociale</th>
                                                <th>Nome e Cognome</th>
                                                <th>Telefono</th>
                                                <th>E-mail</th>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
      </>
    );
  }

export default ElencoClienti;
