import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import { useNavigate } from 'react-router-dom';
import TestataAdmin from "./partials/TestataAdmin";
import NavBarAdmin from "./partials/NavBarAdmin";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import 'moment/locale/it'; // Importa il locale italiano

function UtenteAdminNew() {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const [utente, setUtente] = useState(null);
  const [operatori, setOperatori] = useState([]);
  const [piani, setPiani] = useState([]); // Stato per i piani disponibili
  const [selectedPiano, setSelectedPiano] = useState(null); // Stato per il piano selezionato
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Stato per mostrare/nascondere la password
  const generateRandomPassword = (length = 12) => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%()_-+=[]./?";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
    };

  const [formData, setFormData] = useState({
    role: "user",
    attivo: true,
    mostraStripe: false,
    password: generateRandomPassword()
  });
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 
  // Definizione della funzione fetchCliente FUORI da useEffect
  const fetchOperatori = async () => { 
    try {        
      const token = localStorage.getItem("token");      
      // Fetch operatori attivi (corretto endpoint)
      const responseOperatori = await fetch(`/api/operatori-attivi`, { 
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        credentials: "include",
      });
      const dataOperatori = await responseOperatori.json();
      setOperatori(dataOperatori);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchOperatori();
  }, []);

  useEffect(() => {
    // Fetch dei piani disponibili (sostituisci con la tua logica di recupero)
    const fetchPiani = async () => {
      try {
        const response = await fetch('/api/piani'); // Assumi che tu abbia un endpoint per recuperare i piani
        if (response.ok) {
          const data = await response.json();
          setPiani(data);
            console.log(data);
          // Imposta il primo piano come selezionato di default (se presente)
          if (data.length > 0) {
            setSelectedPiano(data[0]);
            setFormData({
              ...formData,
              piano: data[0].piano,
              quota: data[0].quota,
              ore: data[0].ore,
              residue: data[0].ore, // Assumi che i minuti residui iniziali siano uguali alle ore comprese
              plan: data[0].piano,
              planId: data[0]._id,
              stripePriceId: data[0].priceId,
              stripeProdId: data[0].prodId
            });
          }
        } else {
          throw new Error('Errore nel recupero dei piani');
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchPiani();
  }, []);

  const handlePianoChange = (e) => {
    const pianoId = e.target.value;
    const pianoSelezionato = piani.find(piano => piano._id === pianoId);

    setSelectedPiano(pianoSelezionato);
    setFormData({
        ...formData,
        pianoId: pianoId, // Aggiorna l'ID del piano selezionato in formData
        quota: pianoSelezionato?.quota || formData.quota, // Usa il valore predefinito se pianoSelezionato è null
        ore: pianoSelezionato?.ore || formData.ore,
        residue: pianoSelezionato?.ore || formData.residue,
        plan: pianoSelezionato?.piano || formData.plan,
        planId: pianoSelezionato?._id || formData.planId,
        stripeProdId: pianoSelezionato?.prodId || formData.stripeProdId,
        stripePriceId: pianoSelezionato?.priceId || formData.stripePriceId,
    });
    };
  
  useEffect(() => {
    // Genera una password casuale al caricamento del componente
    setGeneratedPassword(generateRandomPassword());
    }, []); // Esegui solo una volta al montaggio del componente

    const handleChangePassword = (e) => {
        setGeneratedPassword(e.target.value);
        setFormData({ ...formData, password: e.target.value });
    };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeBool = (e) => {
    const { name, checked } = e.target; // Ottieni il valore checked (booleano)
    setFormData({ ...formData, [name]: checked }); // Aggiorna lo stato con il valore booleano
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.regime === "" || formData.regime === " = = SELEZIONA = = ") { 
      setError("Seleziona un regime valido"); 
      return; // Impedisci l'invio del form
    }
    
    if (formData.ritenuta === "" || formData.ritenuta === " = = SELEZIONA = = ") { 
      setError("Seleziona la Ritenuta d'Acconto"); 
      return; // Impedisci l'invio del form
    }
    try {
      const token = localStorage.getItem('token');
      // Crea un nuovo FormData e aggiungi tutti i campi necessari
    //   const formDataToSend = new FormData();
    //   for (const key in formData) {
    //     formDataToSend.append(key, formData[key]);
    //   }
      const response = await fetch(`/api/nuovo-utente`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify(formData),
      });
      if (response.ok) {      
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
        setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
        navigate('/amministratore/utenti'); // Reindirizza alla dashboard dopo l'upload
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Errore durante il salvataggio');
        setTimeout(() => {
            error(false);
        }, 3000); // Nascondi dopo 3 secondi
      }
    } catch (err) {
      console.error('Errore durante il salvataggio:', err);
      setError('Si è verificato un errore durante il salvataggio');
        setTimeout(() => {
            error(false);
        }, 3000); // Nascondi dopo 3 secondi
    }
  };

  return (
    <>
      <NavBarAdmin />
      <section className="main_content dashboard_part">
        <div className='className="container-fluid g-0'>            
            <TestataAdmin userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box_tittle list_header">
                  <h4>Nuova Attività/Utente</h4>
                </div>                
                  <form onSubmit={handleSubmit}>
                    <div className="col-12 mb_30">
                      <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="disponibili">Operatore assegnato: </label>
                            <select 
                              className="form-select form-control"
                              id="id_operatore"
                              name="id_operatore"
                              onChange={handleChange}                              
                            >
                                <option value="">Seleziona operatore</option>
                                {operatori.map(operatore => (
                                <option 
                                    key={operatore._id} 
                                    value={operatore._id}
                                    selected={utente && utente.id_operatore === operatore._id}
                                    style={{
                                      fontWeight: utente && utente.id_operatore === operatore._id ? 'bold' : 'normal',
                                      color: utente && utente.id_operatore === operatore._id ? '#0d6efd' : 'inherit'
                                    }}
                                >
                                    {operatore.nome} {operatore.cognome}
                                </option>
                                ))}
                            </select>
                        </div>
                                          
                        <div className="form-group col-md-4 cols-sm-6 mb_20">
                            <label htmlFor="ragioneSociale">Attivo:</label><br />
                            <input
                                type="checkbox"
                                id="attivo"
                                name="attivo"
                                checked={formData.attivo}
                                onChange={handleChangeBool}                                
                            />
                        </div>

                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="disponibili"><b className="text text-warning">Disponibilità in cassa</b>:</label>
                            <div className="input-group input-group mb-3">
                                <div className="input-group-text">
                                    <span className="" id="inputGroup-sizing-sm">€</span>
                                </div>
                                <input type="number" step="0.01" className="form-control" id="disponibili" name="disponibili" value={formData.disponibili} onChange={handleChange}  />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6 cols-sm-12 mb_20">
                            <label htmlFor="piano">Piano:</label>
                            <select
                                className="form-select form-control"
                                id="piano"
                                name="piano"
                                value={formData.pianoId || ''} 
                                onChange={handlePianoChange}
                            >
                            <option value="">Seleziona un piano</option>
                            {piani.map(piano => (
                                <option key={piano._id} value={piano._id}>
                                {piano.piano} <small>({piano.ore} ore = {(piano.quota / 100).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €/mese)</small>
                                </option>
                            ))}
                            </select>
                        </div>
                        <div className="form-group col-md-3 cols-sm-12 mb_20">
                            <label htmlFor="disponibili">Quota mensile IVA 22% inclusa: </label>
                            <div className="input-group input-group mb-3">
                                <div className="input-group-text">
                                    <span className="" id="inputGroup-sizing-sm">€</span>
                                </div>
                                <input type="number" step="0.01" className="form-control" id="quota" name="quota" value={formData?.quota / 100 || ''} onChange={handleChange} disabled />
                            </div>
                        </div> 
                        <div className="form-group col-md-3 cols-sm-6 mb_20">
                            <label htmlFor="ore"><b className="text text-success">Minuti compresi mensili</b>:</label><br />
                            <input type="number" step="5" className="form-control" id="ore" name="ore" value={formData?.ore || ''} onChange={handleChange}  disabled />
                        </div>                                               
                      </div>
                      <input type="hidden" id="residue" name="residue" value={formData?.residue || ''} onChange={handleChange}  disabled />
                      <input type="hidden" name="plan" id="plan" value={formData?.plan || ''} onChange={handleChange}  disabled />
                      <input type="hidden" name="planId" id="planId" value={formData?.planId || ''} onChange={handleChange}  disabled />
                      <input type="hidden" name="stripePriceId" id="stripePriceId" value={formData?.stripePriceId || ''} onChange={handleChange}  disabled />
                      <input type="hidden" name="stripeProdId" id="stripeProdId" value={formData?.stripeProdId || ''} onChange={handleChange}  disabled />
                      <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="ragioneSociale">Ragione Sociale <b style={{color: "#CC0000"}}>*</b>:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="ragioneSociale"
                                name="ragioneSociale"
                                value={formData.ragioneSociale}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="nome">Nome:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nome"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}                                
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="cognome">Cognome:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cognome"
                                name="cognome"
                                value={formData.cognome}
                                onChange={handleChange}                                
                            />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="indirizzo">Indirizzo <b style={{color: "#CC0000"}}>*</b>:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="indirizzo"
                                name="indirizzo"
                                value={formData.indirizzo}
                                onChange={handleChange}                                
                            />
                        </div>
                      
                      <div className="form-group col-md-4 cols-sm-12 mb_20">
                        <label htmlFor="comune">Comune <b style={{color: "#CC0000"}}>*</b>:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="comune"
                            name="comune"
                            value={formData.comune}
                            onChange={handleChange}                            
                        />
                      </div>
                        <div className="form-group col-md-2 cols-sm-6 mb_20">
                            <label htmlFor="prov">Prov <b style={{color: "#CC0000"}}>*</b>:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="prov"
                                name="prov"
                                value={formData.prov}
                                onChange={handleChange}                                
                            />
                        </div>
                        <div className="form-group col-md-2 cols-sm-6 mb_20">
                            <label htmlFor="cap">CAP <b style={{color: "#CC0000"}}>*</b>:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cap"
                                name="cap"
                                value={formData.cap}
                                onChange={handleChange}                                
                            />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="tel">Telefono:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="tel"
                                name="tel"
                                value={formData.tel}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="cell">Cellulare:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cell"
                                name="cell"
                                value={formData.cell}
                                onChange={handleChange}                                
                            />
                        </div>     
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="whatsapp">WhatsApp al n°:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="whatsapp"
                                name="whatsapp"
                                value={formData.whatsapp}
                                onChange={handleChange}                                
                            />
                        </div>                      
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="whatsapp">Sito web &nbsp; [http(s)://nomeazienda.ext]:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="url"
                                name="url"
                                value={formData.url}
                                onChange={handleChange}                                
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="mail">Indirizzo e-mail/Nome Utente <b style={{color: "#CC0000"}}>*</b>:</label>
                            <input
                                type="mail"
                                className="form-control"
                                id="mail"
                                name="mail"
                                value={formData.mail}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20 position-relative">
                            <label htmlFor="password">Password <b style={{color: "#CC0000"}}>*</b>:</label>
                            <div class="input-group input-group mb-3">
                                <input
                                    type={showPassword ? "text" : "password"} 
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChangePassword}                                       
                                />
                                <div class="input-group-text">
                                    <span class="" id="inputGroup-sizing-sm">
                                        <button type="button" style={{padding: '0', border: '0px solid transparent'}} onClick={() => setShowPassword(!showPassword)}>
                                            <i className={showPassword ? "ti-lock" : "ti-eye"}></i> {/* Icone Themify */}                                        
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>                         
                    </div>

                    <div className="row">
                        <div className="form-group col-md-3 cols-sm-12 mb_20">
                            <label htmlFor="pec">Indirizzo P.E.C.:</label><br />
                            <input
                                type="mail"
                                className="form-control"
                                id="pec"
                                name="pec"
                                value={formData.pec}
                                onChange={handleChange}                                
                            />
                        </div> 
                        <div className="form-group col-md-3 cols-sm-12 mb_20">
                            <label htmlFor="codUniv">Codice Univoco/S.D.I.:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="codUniv"
                                name="codUniv"
                                value={formData.codUniv}
                                onChange={handleChange}                                
                            />
                        </div>
                        <div className="form-group col-md-3 cols-sm-12 mb_20">
                            <label htmlFor="codFisc">Codice Fiscale <b style={{color: "#CC0000"}}>*</b>:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="codFisc"
                                name="codFisc"
                                value={formData.codFisc}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-md-3 cols-sm-12 mb_20">
                            <label htmlFor="piva">Partita IVA <b style={{color: "#CC0000"}}>*</b>:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="piva"
                                name="piva"
                                value={formData.piva}
                                onChange={handleChange}                                
                            />
                        </div> 
                    </div>

                    <div className="row">                        
                        <div className="form-group col-md-4 cols-sm-4 mb_20">
                            <label htmlFor="mostraStripe">Mostra Stripe per annullamento pagamenti:</label><br />
                            <input
                                type="checkbox"
                                id="mostraStripe"
                                name="mostraStripe"
                                checked={formData.mostraStripe}
                                value="true"
                                onChange={handleChangeBool}                                
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="regime">Regime <b style={{color: "#CC0000"}}>*</b>:</label><br />
                            <select
                              className="form-select form-control"
                              id="regime"
                              name="regime"
                              value={formData.regime}
                              onChange={handleChange}                          
                            >
                              <option selected> = SELEZIONA! = </option>
                              <option value="Ordinario">Ordinario</option>
                              <option value="Semplificato">Semplificato</option>
                              <option value="Forfettario">Forfettario</option>
                            </select>
                        </div>
                                             
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="ritenuta">R.A. <b style={{color: "#CC0000"}}>*</b></label><br />
                            <select
                              className="form-select form-control"
                              id="ritenuta"
                              name="ritenuta"
                              value={formData.ritenuta}
                              onChange={handleChange}                            
                            >
                              <option selected> = SELEZIONA! = </option>
                              <option value="0">0%</option>
                              <option value="4">4%</option>
                              <option value="15">15%</option>
                            </select>
                        </div> 
                    </div>

                    <div className="row">                     
                                         
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="fatturazione">Piattaforma Fatturazione:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="fatturazione"
                                name="fatturazione"
                                value={formData.fatturazione}
                                onChange={handleChange}                                
                            />
                        </div>                                            
                        <div className="form-group col-md-8 cols-sm-12 mb_20">
                            <label htmlFor="urlFatture">URL accesso alla piattaforma:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="urlFatture"
                                name="urlFatture"
                                value={formData.urlFatture}
                                onChange={handleChange}
                                placeholder="http(s)://..."
                            />                        
                        </div>
                      </div>

                       <div className="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="userFatt">Nome Utente piattaforma fatturazione:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="userFatt"
                                name="userFatt"
                                value={formData.userFatt}
                                onChange={handleChange}                                
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_20">
                            <label htmlFor="pwdFatt">Password piattaforma fatturazione:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="pwdFatt"
                                name="pwdFatt"
                                value={formData.pwdFatt}
                                onChange={handleChange}                                
                            />
                        </div>

                        <div className="form-group col-md-4 cols-sm-12 mb_20" style={{textAlign: 'right'}}>
                            {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                                <div className="alert alert-success mt_20" role="alert">Dati aggiornati con successo!</div>
                            )}
                            {error && <div className="alert alert-danger mt_20" role="alert">{error}</div>}

                            <button type="submit" className="btn btn-primary mt_20">Aggiungi Utente</button>
                        </div>
                      </div>
                    </div>
                  </form>                
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default UtenteAdminNew;
