import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import { useNavigate } from 'react-router-dom';
import TestataAdmin from "./partials/TestataAdmin";
import NavBarAdmin from "./partials/NavBarAdmin";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import 'moment/locale/it'; // Importa il locale italiano

function NuovaFormazioneAdmin() {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();   
  const [utente, setUtente] = useState(null);
  const [formData, setFormData] = useState({
    id_utente: id,
    tipo: "",
    testo: "",
    scade: null,
  });
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 
  // Definizione della funzione fetchCliente FUORI da useEffect

  useEffect(() => {
  const fetchUtente = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `/api/utente/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUtente(data); 
        
      } else {
        // Gestisci qui l'errore della prima fetch se necessario
        console.error('Errore nel recupero dell\'utente:', response.statusText);
      } 
    } catch (error) { // Aggiunto blocco catch per la prima fetch
      console.error('Errore nel recupero degli utenti:', error);
    } 
  };

  fetchUtente();
}, [id]);
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/nuova-formazione/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (response.ok) {      
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
        setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
        navigate("/gestione/formazione/" + id);
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Errore durante il salvataggio');
      }
    } catch (err) {
      console.error('Errore durante il salvataggio:', err);
      setError('Si è verificato un errore durante il salvataggio');
    }
  };

  return (
    <>
      <NavBarAdmin />
      <section className="main_content dashboard_part">
        <div className='className="container-fluid g-0'>            
            <TestataAdmin userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box_tittle list_header">
                    {utente ? ( // Controllo se utente è definito
                        <>
                          <h4>Nuova Formazione obbligatoria per 
                              {utente.ragioneSociale ? (                                  
                                  <span className="text text-info" style={{ fontWeight: "700" }}> {utente.ragioneSociale}</span>
                              ) : (
                                  <span className="text text-info" style={{ fontWeight: "700" }}> {utente.nome} {utente.cognome}</span>
                              )}
                          </h4>
                          
                          <h4>
                            <a href={`/amministratore/dettaglio-utente/${id}`} className='btn btn-outline-primary'><i className='ti-control-backward'></i> Dettaglio Utente</a>
                          </h4>
                        </>
                    ) : (
                        <p>Caricamento dati utente...</p> // Messaggio di caricamento
                    )}
                </div>
                {error && <p className="error-message">{error}</p>}
                
                  <form onSubmit={handleSubmit}>
                    <div className="col-12 mb_30">
                        <div className="row">
                            <div className="form-group col-md-8 cols-sm-12 mb_20">
                                <label htmlFor="tipo">Tipologia:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tipo"
                                    name="tipo"
                                    value={formData.tipo}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group col-md-4 cols-sm-12 mb_20">
                                <label htmlFor="giorno">Entro il:</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="scade"
                                    name="scade"
                                    value={formData.scade}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group col-md-12 cols-sm-12 mb_20">
                                <label htmlFor="testo">Descrizione:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="testo"
                                    name="testo"
                                    value={formData.testo}
                                    onChange={handleChange}                                
                                />
                            </div>
                        </div>                        

                        <div className="row">
                            <div className="form-group col-12 mb_20" style={{textAlign: 'right'}}>
                                {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                                    <div className="alert alert-success mt_20" role="alert">Dati aggiornati con successo!</div>
                                )}
                                <button type="submit" className="btn btn-primary mt_20">Salva</button>
                            </div>
                        </div>
                    </div>
                  </form>                
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default NuovaFormazioneAdmin;
