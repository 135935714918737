import React, { useState, useContext } from 'react';
import { UserContext } from './UserContext'; // Importa il contesto
import { useNavigate } from 'react-router-dom';
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";


function NuovaAnagrafica() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [role, setrole] = useState('');
  const [showAlert, setShowAlert] = useState(true); // Nuovo stato per l'alert 
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const province = [
    { sigla: 'AG', nome: 'Agrigento' },
    { sigla: 'AL', nome: 'Alessandria' },
    { sigla: 'AN', nome: 'Ancona' },
    { sigla: 'AO', nome: 'Aosta' },
    { sigla: 'AR', nome: 'Arezzo' },
    { sigla: 'AP', nome: 'Ascoli Piceno' },
    { sigla: 'AT', nome: 'Asti' },
    { sigla: 'AV', nome: 'Avellino' },
    { sigla: 'BA', nome: 'Bari' },
    { sigla: 'BT', nome: 'Barletta-Andria-Trani' },
    { sigla: 'BL', nome: 'Belluno' },
    { sigla: 'BN', nome: 'Benevento' },
    { sigla: 'BG', nome: 'Bergamo' },
    { sigla: 'BI', nome: 'Biella' },
    { sigla: 'BO', nome: 'Bologna' },
    { sigla: 'BZ', nome: 'Bolzano' },
    { sigla: 'BS', nome: 'Brescia' },
    { sigla: 'BR', nome: 'Brindisi' },
    { sigla: 'CA', nome: 'Cagliari' },
    { sigla: 'CL', nome: 'Caltanissetta' },
    { sigla: 'CB', nome: 'Campobasso' },
    { sigla: 'CI', nome: 'Carbonia-Iglesias' },
    { sigla: 'CE', nome: 'Caserta' },
    { sigla: 'CT', nome: 'Catania' },
    { sigla: 'CZ', nome: 'Catanzaro' },
    { sigla: 'CH', nome: 'Chieti' },
    { sigla: 'CO', nome: 'Como' },
    { sigla: 'CS', nome: 'Cosenza' },
    { sigla: 'CR', nome: 'Cremona' },
    { sigla: 'KR', nome: 'Crotone' },
    { sigla: 'CN', nome: 'Cuneo' },
    { sigla: 'EN', nome: 'Enna' },
    { sigla: 'FM', nome: 'Fermo' },
    { sigla: 'FE', nome: 'Ferrara' },
    { sigla: 'FI', nome: 'Firenze' },
    { sigla: 'FG', nome: 'Foggia' },
    { sigla: 'FC', nome: 'Forlì-Cesena' },
    { sigla: 'FR', nome: 'Frosinone' },
    { sigla: 'GE', nome: 'Genova' },
    { sigla: 'GO', nome: 'Gorizia' },
    { sigla: 'GR', nome: 'Grosseto' },
    { sigla: 'IM', nome: 'Imperia' },
    { sigla: 'IS', nome: 'Isernia' },
    { sigla: 'SP', nome: 'La Spezia' },
    { sigla: 'AQ', nome: "L'Aquila" },
    { sigla: 'LT', nome: 'Latina' },
    { sigla: 'LE', nome: 'Lecce' },
    { sigla: 'LC', nome: 'Lecco' },
    { sigla: 'LI', nome: 'Livorno' },
    { sigla: 'LO', nome: 'Lodi' },
    { sigla: 'LU', nome: 'Lucca' },
    { sigla: 'MC', nome: 'Macerata' },
    { sigla: 'MN', nome: 'Mantova' },
    { sigla: 'MS', nome: 'Massa-Carrara' },
    { sigla: 'MT', nome: 'Matera' },
    { sigla: 'ME', nome: 'Messina' },
    { sigla: 'MI', nome: 'Milano' },
    { sigla: 'MO', nome: 'Modena' },
    { sigla: 'MB', nome: 'Monza e della Brianza' },
    { sigla: 'NA', nome: 'Napoli' },
    { sigla: 'NO', nome: 'Novara' },
    { sigla: 'NU', nome: 'Nuoro' },
    { sigla: 'OG', nome: 'Ogliastra' },
    { sigla: 'OT', nome: 'Olbia-Tempio' },
    { sigla: 'OR', nome: 'Oristano' },
    { sigla: 'PD', nome: 'Padova' },
    { sigla: 'PA', nome: 'Palermo' },
    { sigla: 'PR', nome: 'Parma' },
    { sigla: 'PV', nome: 'Pavia' },
    { sigla: 'PG', nome: 'Perugia' },
    { sigla: 'PU', nome: 'Pesaro e Urbino' },
    { sigla: 'PE', nome: 'Pescara' },
    { sigla: 'PC', nome: 'Piacenza' },
    { sigla: 'PI', nome: 'Pisa' },
    { sigla: 'PT', nome: 'Pistoia' },
    { sigla: 'PN', nome: 'Pordenone' },
    { sigla: 'PZ', nome: 'Potenza' },
    { sigla: 'PO', nome: 'Prato' },
    { sigla: 'RG', nome: 'Ragusa' },
    { sigla: 'RA', nome: 'Ravenna' },
    { sigla: 'RC', nome: 'Reggio Calabria' },
    { sigla: 'RE', nome: 'Reggio Emilia' },
    { sigla: 'RI', nome: 'Rieti' },
    { sigla: 'RN', nome: 'Rimini' },
    { sigla: 'RM', nome: 'Roma' },
    { sigla: 'RO', nome: 'Rovigo' },
    { sigla: 'SA', nome: 'Salerno' },
    { sigla: 'VS', nome: 'Medio Campidano' },
    { sigla: 'SS', nome: 'Sassari' },
    { sigla: 'SV', nome: 'Savona' },
    { sigla: 'SI', nome: 'Siena' },
    { sigla: 'SR', nome: 'Siracusa' },
    { sigla: 'SO', nome: 'Sondrio' },
    { sigla: 'TA', nome: 'Taranto' },
    { sigla: 'TE', nome: 'Teramo' },
    { sigla: 'TR', nome: 'Terni' },
    { sigla: 'TO', nome: 'Torino' },
    { sigla: 'TP', nome: 'Trapani' },
    { sigla: 'TN', nome: 'Trento' },
    { sigla: 'TV', nome: 'Treviso' },
    { sigla: 'TS', nome: 'Trieste' },
    { sigla: 'UD', nome: 'Udine' },
    { sigla: 'VA', nome: 'Varese' },
    { sigla: 'VE', nome: 'Venezia' },
    { sigla: 'VB', nome: 'Verbano-Cusio-Ossola' },
    { sigla: 'VC', nome: 'Vercelli' },
    { sigla: 'VR', nome: 'Verona' },
    { sigla: 'VV', nome: 'Vibo Valentia' },
    { sigla: 'VI', nome: 'Vicenza' },
    { sigla: 'VT', nome: 'Viterbo' },
  ];

  const handleTipoAnagraficaChange = (event) => {
    setrole(event.target.value);
    setShowAlert(false); // Nascondi l'alert quando viene selezionato un radio button
  };

  
  const [formData, setFormData] = useState({
    mail: '',
    role: '',
    ragioneSociale: '',
    nome: '',
    cognome: '',
    indirizzo: '',
    comune: '',
    prov: '',
    cap: '',
    codUniv: '',
    pec: '',
    codFisc: '',
    piva: '',
    tel: '',
    cell: '',
    whatsapp: '',
    url: '',
    forfait: false, // Solo per i clienti
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeBool = (e) => {
    const { name, checked } = e.target; // Ottieni il valore checked (booleano)
    setFormData({ ...formData, [name]: checked }); // Aggiorna lo stato con il valore booleano
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Logica per inviare i dati al backend (fetch, axios, etc.)
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/nuova-anagrafica`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify({ ...formData, role: role }),
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
            setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
        setTimeout(() => {
            navigate('/dashboard'); // Reindirizza alla dashboard dopo il salvataggio
        }, 3100);
        } else {
        const errorData = await response.json();
        setError(errorData.error || 'Errore durante il salvataggio');
      }
    } catch (err) {
      console.error('Errore durante il salvataggio:', err);
      setError('Si è verificato un errore durante il salvataggio');
    }
   };

  return (
        <>
            <NavBar />
            <section className="main_content dashboard_part">
                <div className='class="container-fluid g-0'>            
                    <Testata userData={userData} />
                </div>
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                        <div className="white_box_tittle list_header">
                            <h3>Nuova Anagrafica</h3>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="form-row mt_50">
                                <div className="form-group col-md-12 mb_50">
                                    <label htmlFor="role"><h4>Tipo:</h4></label>
                                    <div className="justify-content-center">
                                        <label className="col-lg-4 col-xs-12" style={{fontSize: '1.2rem', fontWeight: '600', textAlign: 'center'}}>
                                            &nbsp; Cliente &nbsp; <input 
                                            type="radio" 
                                            name="role" 
                                            value="cliente" 
                                            checked={role === 'cliente'} 
                                            onChange={handleTipoAnagraficaChange} 
                                            />
                                        </label>

                                        <label className="col-lg-4">
                                            {showAlert && ( // Mostra l'alert solo se showAlert è true
                                                <div className="alert alert-warning" role="alert" style={{textAlign: 'center'}}>
                                                    <b>ATTENZIONE!</b><br />Seleziona il tipo di anagrafica per iniziare.
                                                </div>
                                            )}
                                            {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                                                <div className="alert alert-success mt_20" role="alert">Anagrafica inserita con successo!</div>
                                            )}
                                            {error && (
                                                <div className="alert alert-danger" role="alert" style={{textAlign: 'center'}}>
                                                    <b>ATTENZIONE!</b><br />{error}
                                                </div>
                                            )}
                                        </label>

                                        <label className="col-lg-4 col-xs-12 justify-content-center" style={{fontSize: '1.2rem', fontWeight: '600', textAlign: 'center'}}>
                                            &nbsp; Fornitore &nbsp; <input 
                                            type="radio" 
                                            name="role" 
                                            value="fornitore" 
                                            checked={role === 'fornitore'} 
                                            onChange={handleTipoAnagraficaChange} 
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="form-group col-md-4 col-sm-12 mb_30">
                                    <label htmlFor="ragioneSociale">Ragione Sociale:</label>
                                    <input type="text" className="form-control" id="ragioneSociale" name="ragioneSociale" value={formData.ragioneSociale} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="nome">Nome:</label>
                                    <input type="text" className="form-control" id="nome" name="nome" value={formData.nome} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="cognome">Cognome:</label>
                                    <input type="text" className="form-control" id="cognome" name="cognome" value={formData.cognome} onChange={handleChange} />
                                </div>
                            </div>
                            <div class="row">
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="indirizzo">Indirizzo:</label>
                                    <input type="text" className="form-control" id="indirizzo" name="indirizzo" value={formData.indirizzo} onChange={handleChange} />
                                </div>
                      
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="comune">Comune:</label>
                                    <input type="text" className="form-control" id="comune" name="comune" value={formData.comune} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-2 cols-sm-6 mb_30">
                                    <label htmlFor="prov">Prov:</label>
                                    {/* <input type="text" className="form-control" id="prov" name="prov" value={formData.prov} onChange={handleChange} /> */}
                                    <select
                                        className="form-select form-control"
                                        id="prov"
                                        name="prov"
                                        value={formData.prov.toUpperCase()} // Imposta il valore in maiuscolo
                                        onChange={handleChange}
                                    >
                                        {province.map(provincia => (
                                        <option key={provincia.sigla} value={provincia.sigla.toUpperCase()}>
                                            {provincia.nome.charAt(0).toUpperCase() + provincia.nome.slice(1)}
                                        </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-2 cols-sm-6 mb_30">
                                    <label htmlFor="cap">CAP:</label>
                                    <input type="text" className="form-control" id="cap" name="cap" value={formData.cap} onChange={handleChange} />
                                </div>
                            </div>
                            <div class="row">
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="tel">Telefono:</label>
                                    <input type="text" className="form-control" id="tel" name="tel" value={formData.tel} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="cell">Cellulare:</label>
                                    <input type="text" className="form-control" id="cell" name="cell" value={formData.cell} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="whatsapp">Notifiche via WhatsApp:</label><br />
                                    <input type="text" className="form-control" id="whatsapp" name="whatsapp" value={formData.whatsapp} onChange={handleChange} />
                                </div>
                            </div>

                            <div class="row">                        
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="whatsapp">Sito web &nbsp; [http(s)://nomeazienda.ext]:</label><br />
                                    <input type="text" className="form-control" id="url" name="url" value={formData.url} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="mail">Indirizzo e-mail:</label>
                                    <input type="mail" className="form-control" id="mail" name="mail" value={formData.mail} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="pec">Indirizzo P.E.C.:</label><br />
                                    <input type="mail" className="form-control" id="pec" name="pec" value={formData.pec} onChange={handleChange} />
                                </div>
                            </div>
                            <div class="row">                        
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="codUniv">Codice Univoco/S.D.I.:</label><br />
                                    <input type="text" className="form-control" id="codUniv" name="codUniv" value={formData.codUniv} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="codFisc">Codice Fiscale:</label>
                                    <input type="text" className="form-control" id="codFisc" name="codFisc" value={formData.codFisc} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <label htmlFor="piva">Partita IVA:</label>
                                    <input type="text" className="form-control" id="piva" name="piva" value={formData.piva} onChange={handleChange} />
                                </div>
                            </div>
                      
                            <div className="row mb_50">
                                <div className="form-group col-md-4 cols-sm-12 mb_30">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="forfait">Regime Forfettario:</label>
                                        <input type="checkbox" id="forfait" name="forfait" checked={formData.forfait} value="true" onChange={handleChangeBool} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">                        
                                <div className="form-group col-md-4 cols-sm-12 mb_30 text-center">                                
                                    <button type="submit" className="btn btn-primary mt_20 right">Salva</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
          </section>
        </>
    );
 };

export default NuovaAnagrafica;