import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ children }) {
  const location = useLocation();

  const isAuthenticated = !!localStorage.getItem('token'); // Verifica la presenza del token

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} /> // Reindirizza al login
  );
}

export default PrivateRoute;