import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import TestataAdmin from "./partials/TestataAdmin";
import NavBarAdmin from "./partials/NavBarAdmin";
import Footer from "./partials/Footer";

function OperatoreAdmin() {
  const { userData } = useContext(UserContext);
  const { id } = useParams(); 
  const [utente, setUtente] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    disponibili: 0,
    residue: 0,
    attivo: false,
    mostraStripe: false,
  });
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 
  // Definizione della funzione fetchCliente FUORI da useEffect
  const fetchUtente = async () => { 
    try {        
      const token = localStorage.getItem("token");
      const response = await fetch(
        `/api/operatore/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        data.attivo = data.attivo === true;
        setUtente(data);
        setFormData(data); // Inizializza formData con i dati del cliente
      } else {
        throw new Error("Errore nel recupero dell'anagrafica");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchUtente();
  }, [id]);
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeBool = (e) => {
    const { name, checked } = e.target; // Ottieni il valore checked (booleano)
    setFormData({ ...formData, [name]: checked }); // Aggiorna lo stato con il valore booleano
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/operatore/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setEditMode(false);        
        fetchUtente(); // Ricarica i dati aggiornati del cliente
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
        setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Errore durante il salvataggio');
      }
    } catch (err) {
      console.error('Errore durante il salvataggio:', err);
      setError('Si è verificato un errore durante il salvataggio');
    }
  };

  return (
    <>
      <NavBarAdmin />
      <section className="main_content dashboard_part">
        <div className='className="container-fluid g-0'>            
            <TestataAdmin userData={userData} />
        </div>
        <div className="main_content_iner ">
            <div className="container-fluid plr_30 body_white_bg pt_30">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="white_box_tittle list_header">
                            {utente ? ( <h4>Profilo dell'Operatore: {formData.nome} {formData.cognome}</h4>) : (<h4>Profilo dell'Operatore</h4> )}
                            {!editMode && ( // Mostra il pulsante "Modifica" solo se non sei in modalità modifica
                                <button className="btn btn-outline-warning btn-sm" onClick={() => setEditMode(true)}>
                                Modifica
                                </button>
                            )}
                        </div>
                        {error && <p className="error-message">{error}</p>}
                        {utente ? (
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" id="disponibili" name="disponibili" value="0" onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="ore" name="ore" value="0" onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="residue" name="residue" value="100" onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="ragioneSociale" name="ragioneSociale" value={formData.ragioneSociale} onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="indirizzo" name="indirizzo" value={formData.indirizzo} onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="comune" name="comune" value={formData.comune} onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="prov" name="prov" value={formData.prov} onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="cap" name="cap" value={formData.cap} onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="whatsapp" name="whatsapp" value={formData.whatsapp} onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="url" name="url" value={formData.url} onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="pec" name="pec" value={formData.pec} onChange={handleChange} disabled={!editMode} />
                            <input type="hidden" id="codUniv" name="codUniv" value={formData.codUniv} onChange={handleChange} disabled={!editMode} />                           

                            <div className="row">
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="attivo">Attivo:</label><br />
                                    <input
                                      type="checkbox"
                                      id="attivo"
                                      name="attivo"
                                      checked={formData.attivo}
                                      onChange={handleChangeBool}
                                      disabled={!editMode}
                                  />
                                </div>  
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="nome">Nome:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nome"
                                        name="nome"
                                        value={formData.nome}
                                        onChange={handleChange}
                                        disabled={!editMode}
                                    />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="cognome">Cognome:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cognome"
                                        name="cognome"
                                        value={formData.cognome}
                                        onChange={handleChange}
                                        disabled={!editMode}
                                    />
                                </div>
                            </div>                      
                      
                            <div className="row">
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="mail">Indirizzo e-mail:</label>
                                    <input
                                        type="mail"
                                        className="form-control"
                                        id="mail"
                                        name="mail"
                                        value={formData.mail}
                                        onChange={handleChange}
                                        disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                                    />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="tel">Telefono:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="tel"
                                        name="tel"
                                        value={formData.tel}
                                        onChange={handleChange}
                                        disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                                    />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="cell">Cellulare:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cell"
                                        name="cell"
                                        value={formData.cell}
                                        onChange={handleChange}
                                        disabled={!editMode}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="codFisc">Codice Fiscale:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="codFisc"
                                        name="codFisc"
                                        value={formData.codFisc}
                                        onChange={handleChange}
                                        disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                                    />
                                </div>
                                <div className="form-group col-md-4 cols-sm-12 mb_20">
                                    <label htmlFor="piva">Partita IVA:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="piva"
                                        name="piva"
                                        value={formData.piva}
                                        onChange={handleChange}
                                        disabled={!editMode}
                                    />
                                </div> 

                                <div className="form-group col-md-2 cols-sm-12 mb_20">
                                    <label htmlFor="regime">Regime:</label><br />
                                    <select
                                        className="form-select form-control"
                                        id="regime"
                                        name="regime"
                                        value={formData.regime}
                                        onChange={handleChange}
                                        disabled={!editMode}
                                    >
                                        <option value="Forfettario">Forfettario</option>
                                        <option value="Semplificato">Semplificato</option>
                                        <option value="Ordinario">Ordinario</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-2 cols-sm-12 mb_20">
                                    <label htmlFor="ritenuta">R.A.</label><br />
                                    <select
                                        className="form-select form-control"
                                        id="ritenuta"
                                        name="ritenuta"
                                        value={formData.ritenuta}
                                        onChange={handleChange}
                                        disabled={!editMode}
                                    >
                                        <option value="0">0%</option>
                                        <option value="4">4%</option>
                                        <option value="15">15%</option>
                                    </select>
                                </div> 
                            </div>

                            <div className="row"> 
                                <div className="form-group col-md-12 cols-sm-12 mb_20" style={{textAlign: 'right'}}>
                                    {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                                        <div className="alert alert-success mt_20" role="alert">Dati aggiornati con successo!</div>
                                    )}
                                    {editMode && ( // Mostra il pulsante "Salva" solo se sei in modalità modifica
                                        <button type="submit" className="btn btn-primary mt_20">
                                            Salva
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    ) : (
                    <p>Caricamento dei tuoi dati...</p>
                    )}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default OperatoreAdmin;
