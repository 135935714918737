import React, { useContext, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";
function ElencoFornitori() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [fornitori, setFornitori] = useState([]); // Stato per i documenti
  const [errorDoc, setDocError] = useState(null);
//   const navigate = useNavigate();

  useEffect(() => {   
    const fetchFornitori = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/fornitori`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const fornitori = await response.json();
            setFornitori(fornitori); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei Fornitori');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchFornitori(); 
  }, []);// Chiama la funzione per recuperare i documenti    

    return (
      <div>
        <Testata userData={userData} />
        <NavBar /> {/* Includi il componente NavBar */}
        <section className="main_content dashboard_part">
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>Anagrafiche dei Tuoi Fornitori</h4>
                            </div>
                            {errorDoc ? (
                                <tr><td colspan="4"> <h5>Errore: {errorDoc}</h5> </td></tr>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table table-striped" id="datatable" style={{width: '100%'}}>
                                        <thead>
                                            <tr>
                                                <th>Ragione Sociale</th>
                                                <th>Nome e Cognome</th>
                                                <th>Telefono</th>
                                                <th>E-mail</th>
                                            </tr>
                                        </thead>                                              
                                        <tbody>
                                            {fornitori.map(fornitore => (
                                                <tr>
                                                    <td>
                                                        <p><a href={`/fornitore/${fornitore._id}`} style={{fontWeight: '500'}}>
                                                            {fornitore.ragioneSociale} &nbsp; <i className="ti-pencil"></i>
                                                        </a></p>
                                                    </td>
                                                    <td><p>{fornitore.nome} {fornitore.cognome}</p></td>
                                                    <td><p>{fornitore.tel}</p></td>
                                                    <td><p><a href={`mailto:${fornitore.mail}`}>{fornitore.mail}</a></p></td>
                                                </tr>
                                            ))}                                            
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Ragione Sociale</th>
                                                <th>Nome e Cognome</th>
                                                <th>Telefono</th>
                                                <th>E-mail</th>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
      </div>
    );
  }

export default ElencoFornitori;
