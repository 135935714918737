import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";

function AnagraficaFornitore() {
  const { userData } = useContext(UserContext);
  const { fornitoreId } = useParams();
  const [ fornitore, setFornitore] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 

  // Definizione della funzione fetchFornitore FUORI da useEffect
  const fetchFornitore = async () => { 
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `/api/fornitore/${fornitoreId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setFornitore(data);
        setFormData(data); // Inizializza formData con i dati del cliente
      } else {
        throw new Error("Errore nel recupero dell'anagrafica");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchFornitore();
  }, [fornitoreId]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/fornitore/${fornitoreId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setEditMode(false);        
        fetchFornitore(); // Ricarica i dati aggiornati del cliente
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
        setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Errore durante il salvataggio');
      }
    } catch (err) {
      console.error('Errore durante il salvataggio:', err);
      setError('Si è verificato un errore durante il salvataggio');
    }
  };

  return (
    <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box_tittle list_header">
                  <h4>Anagrafica Fornitore</h4>
                  
                  {!editMode && ( // Mostra il pulsante "Modifica" solo se non sei in modalità modifica
                    <button className="btn btn-outline-warning btn-sm" onClick={() => setEditMode(true)}>
                      Modifica
                    </button>
                  )}
                </div>
                {error && <p className="error-message">{error}</p>}
                {fornitore ? (
                  <form onSubmit={handleSubmit}>
                    <div className="col-12 mb_30">
                      <div class="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="ragioneSociale">Ragione Sociale:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="ragioneSociale"
                                name="ragioneSociale"
                                value={formData.ragioneSociale}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="nome">Nome:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nome"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="cognome">Cognome:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cognome"
                                name="cognome"
                                value={formData.cognome}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                      </div>
                      <div class="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="indirizzo">Indirizzo:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="indirizzo"
                                name="indirizzo"
                                value={formData.indirizzo}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                      
                      <div className="form-group col-md-4 cols-sm-12 mb_30">
                        <label htmlFor="comune">Comune:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="comune"
                            name="comune"
                            value={formData.comune}
                            onChange={handleChange}
                            disabled={!editMode}
                        />
                      </div>
                        <div className="form-group col-md-2 cols-sm-6 mb_30">
                            <label htmlFor="prov">Prov:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="prov"
                                name="prov"
                                value={formData.prov}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-2 cols-sm-6 mb_30">
                            <label htmlFor="cap">CAP:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cap"
                                name="cap"
                                value={formData.cap}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                      </div>
                      <div class="row">
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="tel">Telefono:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="tel"
                                name="tel"
                                value={formData.tel}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="cell">Cellulare:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cell"
                                name="cell"
                                value={formData.cell}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="whatsapp">Notifiche WhatsApp al n°:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="whatsapp"
                                name="whatsapp"
                                value={formData.whatsapp}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                      </div>

                      <div class="row">                        
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="whatsapp">Sito web &nbsp; [http(s)://nomeazienda.ext]:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="url"
                                name="url"
                                value={formData.url}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="mail">Indirizzo e-mail:</label>
                            <input
                                type="mail"
                                className="form-control"
                                id="mail"
                                name="mail"
                                value={formData.mail}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="pec">Indirizzo P.E.C.:</label><br />
                            <input
                                type="mail"
                                className="form-control"
                                id="pec"
                                name="pec"
                                value={formData.pec}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                      </div>
                      <div class="row">                        
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="codUniv">Codice Univoco/S.D.I.:</label><br />
                            <input
                                type="text"
                                className="form-control"
                                id="codUniv"
                                name="codUniv"
                                value={formData.codUniv}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="codFisc">Codice Fiscale:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="codFisc"
                                name="codFisc"
                                value={formData.codFisc}
                                onChange={handleChange}
                                disabled={!editMode} // Disabilita il campo se non sei in modalità modifica
                            />
                        </div>
                        <div className="form-group col-md-4 cols-sm-12 mb_30">
                            <label htmlFor="piva">Partita IVA:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="piva"
                                name="piva"
                                value={formData.piva}
                                onChange={handleChange}
                                disabled={!editMode}
                            />
                        </div>
                      </div>
                      
                      <div className="row">                        
                        <div className="form-group col-12 text-center">
                            {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                                <div className="alert alert-success mt_20" role="alert">Dati aggiornati con successo!</div>
                            )}
                            {editMode && ( // Mostra il pulsante "Salva" solo se sei in modalità modifica
                                <button type="submit" className="btn btn-primary mt_20 right">
                                    Salva
                                </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <p>Caricamento dati fornitore...</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default AnagraficaFornitore;
