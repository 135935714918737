import PrivateRoute from "./PrivateRoute";
import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard"; // Componente per la dashboard
import Profilo from "./components/Profilo";
import CambioPassword from './components/CambioPassword';
import DocUtenteUpload from "./components/DocUtenteUpload";
import DocumentiInviati from "./components/DocumentiInviati";
import DocumentiRicevuti from "./components/DocumentiRicevuti";
import ElencoClienti from "./components/Clienti";
import AnagraficaCliente from "./components/AnagraficaCliente";
import ElencoFornitori from "./components/Fornitori";
import AnagraficaFornitore from "./components/AnagraficaFornitore";
import Preventivi from "./components/Preventivi";
import Preventivo from "./components/Preventivo";
import RichiestaPreventivo from "./components/RichiestaPreventivo";
import RichiestaFattura from "./components/RichiestaFattura";
import FattureInviate from "./components/FattureInviate";
import NotificheTot from "./components/NotificheTot.jsx";
import FormazioneCliente from "./components/FormazioneCliente";
import CallConsulente from "./components/CallConsulente";
import SupportoTecnico from "./components/SupportoTecnico";
import CertificatiInviati from "./components/CertificatiInviati";
import CertificatoUpload from "./components/CertificatoUpload";
import Convenzioni from "./components/Convenzioni";
import NuovaAnagrafica from "./components/NuovaAnagrafica";
import SetupPagamento from "./components/SetupPagamento";
import { ForgotPassword, ResetPassword } from "./components/RecuperoPassword";
import AdminDashboard from "./components/AdminDashboard";
import RichiesteRicevuteAdmin from "./components/RichiesteRicevuteAdmin";
import UtentiAdmin from "./components/UtentiAdmin";
import OperatoriAdmin from "./components/OperatoriAdmin";
import UtenteAdmin from "./components/UtenteAdmin";
import OperatoreAdmin from "./components/OperatoreAdmin";
import UtenteAdminNew from "./components/UtenteAdminNew.jsx";
import OperatoreNew from "./components/OperatoreNew";
import DettaglioUtente from "./components/DettaglioUtente";
import RicevutiUtente from "./components/RicevutiUtente";
import NotificaAdminNew from "./components/NotificaAdminNew";
import SupportoTecnicoAdmin from "./components/SupportoTecnicoAdmin.jsx";
import MessaggioAdmin from "./components/MessaggioAdmin";
import DocAdminUpload from "./components/DocAdminUpload";
import ProfiloAdmin from "./components/ProfiloAdmin";
import CertificatiUtente from "./components/CertificatiUtente";
import FormazioneAdmin from "./components/FormazioneAdmin";
import NuovaFormazioneAdmin from "./components/NuovaFormazioneAdmin";
import CambioPasswordAdmin from "./components/CambioPasswordAdmin.jsx"
import OperatoreDashboard from "./components/OperatoreDashboard";
import MessaggioOperatore from "./components/MessaggioOperatore";
import OperatoreDettaglioUtente from "./components/OperatoreDettaglioUtente";
import RicevutiUtenteOperatore from "./components/RicevutiUtenteOperatore";
import CertificatiUtenteOperatore from "./components/CertificatiUtenteOperatore.jsx";
import FormazioneOperatore from "./components/FormazioneOperatore";
import NuovaFormazioneOperatore from "./components/NuovaFormazioneOperatore";
import NotificaOperatoreNew from "./components/NotificaOperatoreNew";
import UtenteOperatore from "./components/UtenteOperatore";
import UtentiOperatore from "./components/UtentiOperatore";
import RichiesteRicevuteOperatore from "./components/RichiesteRicevuteOperatore";
import SupportoTecnicoOperatore from "./components/SupportoTecnicoOperatore";
import ProfiloOperatore from "./components/ProfiloOperatore";
import CambioPasswordOperatore from "./components/CambioPasswordOperatore.jsx";
import DocOpUpload from "./components/DocOpUpload";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons";
// import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import { UserProvider } from './components/UserContext'; // Importa UserProvider

function App() {
  return (
    <Router>
      <UserProvider>
        {/* Avvolgi l'app con il provider */}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/setup-pagamento"
            element={
              <PrivateRoute>
                <SetupPagamento />
              </PrivateRoute>
            }
          />
          <Route
            path="/profilo"
            element={
              <PrivateRoute>
                <Profilo />
              </PrivateRoute>
            }
          />
          <Route
            path="/cambio-password"
            element={
              <PrivateRoute>
                <CambioPassword />
              </PrivateRoute>
            }
          />
          <Route path="/recupera-password" element={<ForgotPassword />} />
          <Route
            path="/reimposta-password/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/upload-documenti"
            element={
              <PrivateRoute>
                <DocUtenteUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/documenti-personali"
            element={
              <PrivateRoute>
                <DocumentiInviati />
              </PrivateRoute>
            }
          />
          <Route
            path="/documenti-ricevuti"
            element={
              <PrivateRoute>
                <DocumentiRicevuti />
              </PrivateRoute>
            }
          />
          <Route
            path="/clienti"
            element={
              <PrivateRoute>
                <ElencoClienti />
              </PrivateRoute>
            }
          />
          <Route
            path="/cliente/:clienteId"
            element={
              <PrivateRoute>
                <AnagraficaCliente />
              </PrivateRoute>
            }
          />
          <Route
            path="/fornitori"
            element={
              <PrivateRoute>
                <ElencoFornitori />
              </PrivateRoute>
            }
          />
          <Route
            path="/nuova-anagrafica"
            element={
              <PrivateRoute>
                <NuovaAnagrafica />
              </PrivateRoute>
            }
          />
          <Route
            path="/fornitore/:fornitoreId"
            element={
              <PrivateRoute>
                <AnagraficaFornitore />
              </PrivateRoute>
            }
          />
          <Route
            path="/richiedi-call-consulente"
            element={
              <PrivateRoute>
                <CallConsulente />
              </PrivateRoute>
            }
          />
          <Route
            path="/preventivi"
            element={
              <PrivateRoute>
                <Preventivi />
              </PrivateRoute>
            }
          />
          <Route
            path="/preventivo/:preventivoId"
            element={
              <PrivateRoute>
                <Preventivo />
              </PrivateRoute>
            }
          />
          <Route
            path="/nuovo_preventivo"
            element={
              <PrivateRoute>
                <RichiestaPreventivo />
              </PrivateRoute>
            }
          />
          <Route
            path="/nuova_fattura"
            element={
              <PrivateRoute>
                <RichiestaFattura />
              </PrivateRoute>
            }
          />
          <Route
            path="/certificati"
            element={
              <PrivateRoute>
                <CertificatiInviati />
              </PrivateRoute>
            }
          />
          <Route
            path="/nuovo-certificato"
            element={
              <PrivateRoute>
                <CertificatoUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/assistenza-crediti"
            element={
              <PrivateRoute>
                <FattureInviate />
              </PrivateRoute>
            }
          />
          <Route
            path="/formazione"
            element={
              <PrivateRoute>
                <FormazioneCliente />
              </PrivateRoute>
            }
          />
          <Route
            path="/supporto"
            element={
              <PrivateRoute>
                <SupportoTecnico />
              </PrivateRoute>
            }
          />
          <Route
            path="/convenzioni"
            element={
              <PrivateRoute>
                <Convenzioni />
              </PrivateRoute>
            }
          />
          {/* INIZIO SEZIONE SUPERAMMINISTRATORE */}
          <Route
            path="/amministratore/dashboard"
            element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/richieste-ricevute"
            element={
              <PrivateRoute>
                <RichiesteRicevuteAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/utenti"
            element={
              <PrivateRoute>
                <UtentiAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/operatori"
            element={
              <PrivateRoute>
                <OperatoriAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/utente/:id"
            element={
              <PrivateRoute>
                <UtenteAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/dettaglio-utente/:id"
            element={
              <PrivateRoute>
                <DettaglioUtente />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/ricevuti-utente/:id"
            element={
              <PrivateRoute>
                <RicevutiUtente />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/operatore/:id"
            element={
              <PrivateRoute>
                <OperatoreAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/nuovo-utente"
            element={
              <PrivateRoute>
                <UtenteAdminNew />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/nuovo-operatore"
            element={
              <PrivateRoute>
                <OperatoreNew />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/supporto"
            element={
              <PrivateRoute>
                <SupportoTecnicoAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/invia-documento/:id"
            element={
              <PrivateRoute>
                <DocAdminUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/profilo"
            element={
              <PrivateRoute>
                <ProfiloAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/cambio-password"
            element={
              <PrivateRoute>
                <CambioPasswordAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/invia-notifica/:id"
            element={
              <PrivateRoute>
                <NotificaAdminNew />
              </PrivateRoute>
            }
          />
          <Route
            path="/elenco-notifiche"
            element={
              <PrivateRoute>
                <NotificheTot />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/messaggio/:id"
            element={
              <PrivateRoute>
                <MessaggioAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/formazione/:id"
            element={
              <PrivateRoute>
                <FormazioneAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/nuova-formazione/:id"
            element={
              <PrivateRoute>
                <NuovaFormazioneAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/amministratore/certificati-utente/:id"
            element={
              <PrivateRoute>
                <CertificatiUtente />
              </PrivateRoute>
            }
          />
          {/* SEZIONE OPERATORE */}
          <Route
            path="/gestione/dashboard"
            element={
              <PrivateRoute>
                <OperatoreDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/messaggio/:id"
            element={
              <PrivateRoute>
                <MessaggioOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/dettaglio-utente/:id"
            element={
              <PrivateRoute>
                <OperatoreDettaglioUtente />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/invia-documento/:id"
            element={
              <PrivateRoute>
                <DocOpUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/ricevuti-utente/:id"
            element={
              <PrivateRoute>
                <RicevutiUtenteOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/certificati-utente/:id"
            element={
              <PrivateRoute>
                <CertificatiUtenteOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/formazione/:id"
            element={
              <PrivateRoute>
                <FormazioneOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/nuova-formazione/:id"
            element={
              <PrivateRoute>
                <NuovaFormazioneOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/invia-notifica/:id"
            element={
              <PrivateRoute>
                <NotificaOperatoreNew />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/utenti"
            element={
              <PrivateRoute>
                <UtentiOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/utente/:id"
            element={
              <PrivateRoute>
                <UtenteOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/richieste-ricevute"
            element={
              <PrivateRoute>
                <RichiesteRicevuteOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/supporto"
            element={
              <PrivateRoute>
                <SupportoTecnicoOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/profilo"
            element={
              <PrivateRoute>
                <ProfiloOperatore />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestione/cambio-password"
            element={
              <PrivateRoute>
                <CambioPasswordOperatore />
              </PrivateRoute>
            }
          />
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
