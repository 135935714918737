import React, { useContext, useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
// import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import 'datatables.net';

function DocumentiInviati() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [documenti, setDocumenti] = useState([]); // Stato per i documenti
  const [errorDoc, setDocError] = useState(null);
//   const navigate = useNavigate();

  useEffect(() => {   
    const fetchDocumenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/docuric`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const docum = await response.json();
            setDocumenti(docum); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei documenti');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchDocumenti(); 
  }, []);// Chiama la funzione per recuperare i documenti    

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

  useEffect(() => {
  // Funzione per inizializzare o aggiornare la DataTable
  let table;
  const initializeOrUpdateTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        // Inizializza la DataTable solo se non è già stata inizializzata
        table = $(tableRef.current).DataTable({ // Assegna il valore a table
            ordering: true,
            searching: true,
            paging: true,
            info: true,
            dom: "frtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            language: {
            url: "/js/it-IT.json",
            },
            pageLength: 10,
        });
      }
    };

    // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
    if (tableRef.current) {
        setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
    }

  // Distruggi la DataTable quando il componente viene smontato
  return () => {
    if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
        table.destroy();
    }
  };
}, [documenti]);

    const handleDownload = async (documentoId, filename) => { // Funzione per gestire il download
        try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/documenti/${documentoId}/scaricato`, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
        });
        if (response.ok) {
            const blob = await response.blob(); // Ottieni il file come blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // Usa il nome file corretto
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setDocumenti(prevDocumenti => prevDocumenti.map(doc =>
            doc._id === documentoId ? { ...doc, scaricato: true } : doc
      ));
        } else {
            throw new Error("Errore durante il download del documento");
        }
        } catch (err) {
        console.error('Errore durante il download:', err);
        // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
        }
    };

    return (
      <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>Documenti ricevuti</h4>
                            </div>
                            {errorDoc ? (
                                <tr><td colspan="4"> <h5>Errore: {errorDoc}</h5> </td></tr>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table table-striped" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                        <thead>
                                            <tr>
                                                <th>Titolo del Documento</th>
                                                <th>Pubblicato in data</th>
                                                <th>Ultima modifica</th>
                                                <th>Già scaricato</th>
                                            </tr>
                                        </thead>                                              
                                        <tbody>
                                            {documenti.map(documento => (
                                                <tr key={documento._id}>
                                                    <td>
                                                        <button className="btn btn-link" onClick={() => handleDownload(documento._id, documento.filename)} title="Scarica questo documento" style={{color: '#212529', fontWeight: '500', textDecoration: 'none' }}>
                                                            {documento.titolo}
                                                        </button>
                                                    </td>
                                                    <td style={{paddingTop: '20px'}}><p>{moment(documento.pubblicato).format('DD/MM/YY')} h. {moment(documento.pubblicato).format('HH:mm')}</p></td>
                                                    <td style={{paddingTop: '20px'}}><p>{moment(documento.modificato).format('DD/MM/YY')} h. {moment(documento.modificato).format('HH:mm')}</p></td>
                                                    <td>
                                                        {!documento.scaricato && (
                                                            <button className="btn btn-success" onClick={() => handleDownload(documento._id, documento.filename)} title="Scarica questo documento">
                                                                Scarica ora!
                                                            </button>
                                                        )}
                                                        {documento.scaricato && (
                                                            <button className="btn btn-secondary" onClick={() => handleDownload(documento._id, documento.filename)} title="Scarica nuovamente">
                                                                Scarica di nuovo
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}                
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Titolo del Documento</th>
                                                <th>Pubblicato in data</th>
                                                <th>Ultima modifica</th>
                                                <th>Già scaricato</th>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
      </>
    );
  }

export default DocumentiInviati;
