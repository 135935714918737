import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'react-tooltip';
import "react-tooltip/dist/react-tooltip.css";
import { UserContext } from "./UserContext";
import TestataOperatore from "./partials/TestataOperatore";
import NavBarOp from "./partials/NavBarOp";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import 'moment/locale/it'; // Importa il locale italiano
import $ from 'jquery';
import 'datatables.net';

function CertificatiUtenteOperatore() {
  const { userData } = useContext(UserContext);
  const { id } = useParams(); 
  const [utente, setUtente] = useState(null);
  const [copied, setCopied] = useState({ userFatt: false, pwdFatt: false }); // Unico stato per entrambi i campi
  const [documenti, setDocumenti] = useState([]); // Stato per i documenti ricevuti dall'utente
  const [errorDoc, setDocError] = useState(null);
  const [operatore, setOperatore] = useState([]);
  const [formData, setFormData] = useState({
    evaso: false,
  });
  useEffect(() => {
  const fetchUtente = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `/api/utente/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUtente(data); 

        if (data.id_operatore) {
          try {
            const responseOperatore = await fetch(`/api/operatore/${data.id_operatore}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              credentials: "include",
            });

            if (responseOperatore.ok) {
              const dataOperatore = await responseOperatore.json();
              setOperatore(dataOperatore); 
            } else {
              console.error('Errore nel recupero dell\'operatore:', responseOperatore.statusText);
            }
          } catch (error) {
            console.error('Errore nel recupero dell\'operatore:', error);
          }
        }
      } else {
        // Gestisci qui l'errore della prima fetch se necessario
        console.error('Errore nel recupero dell\'utente:', response.statusText);
      } 
    } catch (error) { // Aggiunto blocco catch per la prima fetch
      console.error('Errore nel recupero degli utenti:', error);
    } 
  };

  fetchUtente();
}, [id]);

    const handleCopy = (field) => {
        setCopied({ ...copied, [field]: true });
        setTimeout(() => setCopied({ ...copied, [field]: false }), 4000);
    };

    useEffect(() => {   
    const fetchDocumenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/certificati-utente/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const docum = await response.json();
            setDocumenti(docum); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei certificati');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchDocumenti(); 
  }, [id]); // Chiama la funzione per recuperare i documenti ricevuti

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

useEffect(() => {
  // Inizializza la DataTable per la prima tabella (documenti)
  let table;
  const initializeTable = () => {
    if (!$.fn.DataTable.isDataTable(tableRef.current)) {
      table = $(tableRef.current).DataTable({
        ordering: true,
        searching: true,
        paging: true,
        info: true,
        dom: "frtip",
        buttons: ["copy", "csv", "excel", "pdf", "print"],
        language: {
          url: "/js/it-IT.json",
        },
        pageLength: 20,
      });
    }
  };

  if (tableRef.current) {
    setTimeout(initializeTable, 500);
  }

  // Distruggi la DataTable quando il componente viene smontato
  return () => {
    if (table && table.destroy) {
      table.destroy();
    }
  };
}, [documenti]); // Dipendenza da documenti per aggiornare la tabella

    const handleDownload = async (documentoId, filename) => { // Funzione per gestire il download
        try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/certificato-admin/${documentoId}`, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
        });
        if (response.ok) {
            const blob = await response.blob(); // Ottieni il file come blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // Usa il nome file corretto
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // setDocumenti(prevDocumenti => prevDocumenti.map(doc =>
            // doc._id === documentoId ? { ...doc, scaricato: true } : doc
            //   ));
        } else {
            throw new Error("Errore durante il download del documento");
        }
        } catch (err) {
        console.error('Errore durante il download:', err);
        // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
        }
    };

  return (
    <>
      <NavBarOp />
      <section className="main_content dashboard_part">
        <div className='className="container-fluid g-0'>            
            <TestataOperatore userData={userData} />
        </div>
        <div className="main_content_iner">
          <div className="container-fluid plr_30 body_white_bg pt_30 pd_30">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    {utente ? ( // Controllo se utente è definito
                        <>
                            <div className="white_box_tittle list_header mb_30">
                                <h4>Dati utili per Fatturazione a 
                                {utente.ragioneSociale ? (
                                
                                    <span className="text text-info" style={{ fontWeight: "700" }}> {utente.ragioneSociale}</span>
                                ) : (
                                    <span className="text text-info" style={{ fontWeight: "700" }}> {utente.nome} {utente.cognome}</span>
                                )}
                                </h4>
                              </div>
                              <div className="row justify-content-center">
                                <div className="col-lg-12 mb_30" style={{textAlign: 'right'}}>
                                  <a href={`/gestione/invia-documento/${id}`} className="btn btn-sm btn-outline-dark"><i className="ti-cloud-up"></i> Invia documento</a> &nbsp; 
                                  <a href={`/gestione/invia-notifica/${id}`} className="btn btn-sm btn-outline-success"><i className="ti-bell"></i> Invia notifica</a>
                                  {utente.formazioneObbligatoria ? (
                                    <>&nbsp; <a href={`/gestione/formazione/${id}`} className="btn btn-sm btn-outline-warning"><i className="ti-crown"></i> Formazione</a></>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                
                                <div className="col-lg-4 col-sm-12 mb_30">
                                    <p>
                                        Piattaforma:<br />
                                        <a
                                        href={utente.urlFatture}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ color: "#ff7f2a", fontWeight: "700" }}
                                        >
                                        {utente.fatturazione}
                                        </a> &nbsp; <i className="ti-new-window"></i>
                                    </p>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mb_30">
                                        <p>
                                            Nome Utente:<br />
                                            <CopyToClipboard text={utente.userFatt} onCopy={() => handleCopy("userFatt")}>
                                                <span style={{ cursor: "pointer" }} title="Clicca per copiare il Nome Utente">
                                                    <b style={{ color: "#ff7f2a", fontWeight: "700" }}>
                                                    {utente.userFatt}{" "}
                                                    <Tooltip id="copyTooltip1" place="top" effect="solid">
                                                        {copied.userFatt ? "Nome Utente copiato!" : "Clicca per copiare il Nome Utente"}
                                                    </Tooltip>
                                                    </b> &nbsp; <i className="ti-layers"></i>
                                                </span>
                                            </CopyToClipboard>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mb_30">
                                        <p>
                                            Password:<br />
                                            <CopyToClipboard text={utente.pwdFatt} onCopy={() => handleCopy("pwdFatt")}>
                                                <span style={{ cursor: "pointer" }} title="Clicca per copiare la Password">
                                                    <b style={{ color: "#ff7f2a", fontWeight: "700" }}>
                                                    {utente.pwdFatt}{" "}
                                                    <Tooltip id="copyTooltip2" place="top" effect="solid">
                                                        {copied.pwdFatt ? "Password copiata!" : "Clicca per copiare la Password"}
                                                    </Tooltip>
                                                    </b> &nbsp; <i className="ti-layers"></i>
                                                </span>
                                            </CopyToClipboard>
                                        </p>

                                    </div>
                                </div>                            
                            </>
                            ) : (
                                <p>Caricamento dati utente...</p> // Messaggio di caricamento
                            )}
                        </div>
              <p>&nbsp;</p>
            </div>
          </div>
        
          <div className="container-fluid plr_30 body_white_bg pt_30 pd_30">
            <div className="row justify-content-center">
                        <div className="col-xs-12 mb_50" style={{borderBottom: '1px solid #cacaca'}}>
                            <div className="white_box_tittle list_header">
                                <h4>Certificati condivisi da questo Utente</h4>
                                <p className="text text-info">
                                  <a href={`/gestione/dettaglio-utente/${id}`} className="btn btn-sm btn-outline-primary">Vedi documenti ricevuti <i className="ti-control-backward"></i></a> &nbsp; 
                                  <a href={`/gestione/ricevuti-utente/${id}`} className="btn btn-sm btn-outline-primary">Vedi documenti inviati <i className="ti-control-forward"></i></a>
                                </p>
                            </div>
                            {errorDoc ? (
                                <h5>Errore: {errorDoc}</h5>
                            ) : (
                                <div className="table-responsive mb_50">
                                        <table className="table lms_table_active table-striped dataTable no-footer dtr-inline" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th style={{width: '20%', fontWeight: '400', fontStyle: 'italic'}}>Data</th>
                                                    <th style={{width: '70%', fontWeight: '400', fontStyle: 'italic'}}>Titolo</th>
                                                    <th style={{width: '10%', fontWeight: '400', fontStyle: 'italic'}}>Scarica</th>
                                                </tr>
                                            </thead>                                              
                                            <tbody>
                                            {documenti.map(documento => (
                                                <tr key={documento._id} id={documento._id}>
                                                    <td><p style={{paddingTop: '10px', whiteSpace: 'nowrap', lineHeight: ''}}>{moment(documento.pubblicato).format('DD/MM/YYYY')}</p></td>
                                                    <td title={`Scarica:  ${documento.titolo}`}>
                                                        <p style={{fontWeight: '400', textDecoration: 'none', whiteSpace: 'nowrap'}}>
                                                            {documento.titolo}
                                                        </p>
                                                    </td>
                                                    <td title={`Scarica:  ${documento.titolo}`}>
                                                        <p style={{fontWeight: '400', textDecoration: 'none', whiteSpace: 'nowrap'}}>
                                                            <button className="btn btn-sm btn-warning" onClick={() => handleDownload(documento._id, documento.filename)} title={`Scarica:  ${documento.titolo}`}>
                                                                <i className='ti-cloud-down'></i>
                                                            </button> 
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))}                
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Data</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Titolo</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Scarica</th>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
          </div>
        </div>
         <Footer />
      </section>
    </>
  );
}

export default CertificatiUtenteOperatore;