import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import TestataOperatore from "./partials/TestataOperatore";
import NavBarOp from "./partials/NavBarOp";
import Footer from "./partials/Footer";

function SupportoTecnicoOperatore() {
    const { userData } = useContext(UserContext);

    return (
        <>
            <NavBarOp />            
            <section className="main_content dashboard_part">
                <div className='class="container-fluid g-0'>            
                    <TestataOperatore userData={userData} />
                </div>
                <div className="main_content_iner ">
                    <div className="container-fluid plr_30 body_white_bg pt_30">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="white_box_tittle list_header">
                                    <h4>Supporto Tecnico</h4>                                    
                                </div>
                                <div className="row justify-content-center mt_20">
                                    <div className="col-lg-6">
                                        <div className="alert alert-warning">
                                            Scegli il canale che preferisci, seguendo le relative istruzioni per ricevere assistenza dal nostro Supporto Tecnico.<br /><br />
                                            A breve implementeremo nuovi canali per accedere al supporto tecnico.
                                        </div>
                                    </div>
                                </div>
                                <div className='row'> 
                                    <div className='col-lg-12 col-md-12'>
                                      <h3>Via e-mail:</h3>                                       
                                      {/* <p>Contattaci, specificando il più dettagliatamente possibile il problema riscontrato, a questo indirizzo e-mail:<br />
                                          <a href="mailto:supporto@animusoperandi.it" style={{fontWeight: '500'}}>supporto@animusoperandi.it</a>.</p>
                                          <p className='mt_50'>Rispondiamo solitamente nell'arco di 24 ore; nei giorni festivi questi tempi possono essere più lunghi.</p>
                                      </div>
                                      <div className='col-lg-6 col-md-12'>
                                        <h3>Telefonicamente:</h3> */}
                                        <p>Contattaci per essere ricontattato da un ns. responsabile, specificando:
                                            <ul type="square">
                                                <li> <i className="ti-check"></i> il problema riscontrato;</li>
                                                <li> <i className="ti-check"></i> il recapito cui essere ricontattato</li>
                                                <li> <i className="ti-check"></i> l'orario per te più comodo</li>
                                            </ul> a questo indirizzo e-mail: <a href="mailto:supporto@animusoperandi.it" style={{fontWeight: '500'}}>supporto@animusoperandi.it</a>.</p>
                                        <p className='mt_50'>Rispondiamo solitamente nell'arco di 24 ore; nei giorni festivi questi tempi possono essere più lunghi.</p>
                                    </div>
                                    {/* <div className='col-lg-4'>
                                        <h3>Chat in real-time:</h3>
                                        <p>Clicca sul bottone qui in basso a destra per aprire una chat con un nostro operatore (nessun bot se siamo disponibili al momento!).</p>
                                        <p className='mt_50'>Rispondiamo immediatamente, se non impegnati con altri Utenti.</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
};

export default SupportoTecnicoOperatore;