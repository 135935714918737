import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
function Login() {
  const annoCorrente = new Date().getFullYear();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Ottieni la funzione navigate
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Resetta eventuali errori precedenti

    try {
      const response = await fetch(`/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: username, password }),
        credentials: 'include'
      });

      if (response.ok) {
        // Login riuscito
        const data = await response.json();
        const item = data.user._id;
        const nome = data.user.nome;
        const cognome = data.user.cognome;
        const mail = data.user.mail;
        const role = data.user.role;
        const ragioneSociale = data.user.ragioneSociale;
        const indirizzo = data.user.indirizzo;
        const comune = data.user.comune;
        const prov = data.user.prov;
        const tel = data.user.tel;
        const cell = data.user.cell;
        const codFisc = data.user.codFisc;
        const piva = data.user.piva;
        const ore = data.user.ore;
        const residue = data.user.residue;
        const regime = data.user.regime;
        const ritenuta = data.user.ritenuta;
        const id_operatore = data.user.id_operatore;
        const formazione = data.user.formazioneObbligatoria;
        const planId = data.user.planId;
        const plan = data.user.plan;
        const stripeProdId = data.user.stipeProdId;
        const stripePriceId = data.user.stripePriceId;
        const userData = {item, nome, cognome, mail, role, ragioneSociale, indirizzo, comune, prov, tel, cell, codFisc, piva, ore, residue, regime, ritenuta, id_operatore, formazione, planId, plan, stripeProdId, stripePriceId};
        // Memorizza l'oggetto nel localStorage
        localStorage.setItem('user', JSON.stringify(userData))
        localStorage.setItem('token', data.token);

        // Reindirizzamento in base al ruolo
        if (data.user.role === 'user') {
          const schedaUtenteResponse = await fetch(`/api/scheda-utente/${item}`, {
            headers: {
                'Content-Type': 'application/json'
            }
          });
          const schedaUtenteData = await schedaUtenteResponse.json();
          if (!schedaUtenteData.stripeStatus) {
            navigate(`/setup-pagamento`);
          } else {
            navigate('/dashboard');
          }
        } else if (data.user.role === 'operatore') {
          navigate('/gestione/dashboard');
        } else if (data.user.role === 'superAdmin') {
          navigate('/amministratore/dashboard');
        } else {
          setError('Ruolo utente non valido');
        }
      } else {
        // Login fallito
        const errorData = await response.json();
        setError(errorData.error || 'Credenziali non valide');
      }
    } catch (err) {
      // console.error('Errore durante il login:', err);
      setError('Si è verificato un errore durante il login');
    }
  };

  return (
    <div className="main_content_iner">
      <div className="container-fluid body_white_bg plr_30 pt_30 pb_30">
        <div className="row justify-content-center mt-5 pt_30 pb_30">
          <div class="col-lg-6 col-sm-8 col-xs-12">
            <div className="modal-content cs_modal">
              <div className="modal-header flexxa">
                <h5 className="modal-title">Accedi ad Animus Operandi</h5>
                <img src="/img/logo-animus-operandi.svg" alt="Animus Operandi" className="logologin"></img>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div>
                    <input className="form-control" type="text" id="email" placeholder="Nome Utente, ovvero la tua e-mail" value={username} onChange={(e) => setUsername(e.target.value)} />
                  </div>
                  <div>
                    <input className="form-control" type="password" id="password" placeholder="La tua password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <button type="submit" className="btn_1 full_width text-center">Accedi</button>
                  {error && <p className="error-message text-danger"><b>Attenzione: </b>{error}</p>}
                  <p>Non disponi di un account? <a data-bs-toggle="modal" data-bs-target="#sing_up" data-bs-dismiss="modal"
                                href="https://www.animusoperandi.it"> Richiedi un contatto!</a></p>
                  <div className="text-center">
                    <a href="/recupera-password" data-bs-toggle="modal" data-bs-target="#forgot_password" data-bs-dismiss="modal" className="pass_forget_btn">Password dimenticata?</a>
                        </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div style={{minHeight: '120px'}}> </div>
      </div>
      <div className="footer_partGen justify-content-center">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                    <div className="footer_iner text-center">
                        <p>© {annoCorrente} Animus Operandi -  Designed & Developed by <a href="https://stefanocoggiola.com/" target="_blank" rel="noreferrer">Stefano Coggiola</a></p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Login;