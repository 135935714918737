import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";

function RichiestaPreventivo() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const tipo = "i";
  const [titolo, setTitolo] = useState('');
  const [testo, setTesto] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);  
  // const [cosa] = useState('');
  const [formData, setFormData] = useState({
    id_utente: userData ? userData.id : "",
    titolo: "",
    filename: "",
    cosa: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Imposta il valore predefinito di cosa solo se tipo è null o undefined
  //   if (!tipo) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       cosa: "Richiesta emissione Fattura",
  //     }));
  //   } else {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       cosa: "Richiesta emissione Fattura",
  //     }));
  //   }
  // }, [tipo]); // Dipendenza da tipo

  const handleFileChange = (e) => {
    const { name, value } = e.target; // Ottieni il valore del radio button selezionato
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Aggiorna lo stato con il valore del radio button
    }));
    setSelectedFile(e.target.files[0]); // Aggiorna lo stato con il file selezionato
  };
  
  // const handleChange = (e) => {
  //   const { name, value } = e.target; // Ottieni il valore del radio button selezionato
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: "value", // Aggiorna lo stato con il valore del radio button
  //   }));
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      alert('Seleziona un file da caricare.');
      return;
    }
    const formData = new FormData();
    formData.append('titolo', 'Richiesta Preventivo per ' + titolo);
    formData.append('file', selectedFile);
    formData.append('cosa', "Richiesta emissione Preventivo");

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/upload_documenti_utente`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: formData
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        // Nascondi il messaggio dopo un breve ritardo (opzionale)
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000); // Nascondi dopo 3 secondi
        navigate('/dashboard'); // Reindirizza alla dashboard dopo l'upload
      } else {
        const errorData = await response.json();
        alert(errorData.error || 'Errore durante il caricamento del documento');
      }
    } catch (err) {
      console.error('Errore durante il caricamento:', err);
      alert('Si \u00e8 verificato un errore durante il caricamento. Probabilmente il tipo di file non è consentito o le dimensioni superano i 5 Mb. massimi consentiti.');
    }    
  };
  return (
    <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
        <div className="main_content_iner ">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_box mb_30">
                  <div className="box_header ">
                    <div className="main-title">
                      <h3 className="mb-0">Richiedi l'emissione di un preventivo</h3>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <input type="hidden" name="cosa" value="Preventivo" />
                    <div className='row'>
                      <div className="col-12 mb-3">
                        <label className="form-label" htmlFor="titolo">Ragione Sociale/Partita IVA/Codice Fiscale del Destinatario</label>
                        <input type="text" className="form-control" id="titolo" placeholder="Ragione Sociale del Destinatario o Partita IVA/Codice Fiscale" value={titolo} onChange={(e) => setTitolo(e.target.value)}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <div className="input-group mb-3">
                          <label className="form-label" htmlFor="file">Scegli il file dal tuo device
                          <input type="file" className="form-control" name="file" id="file" onChange={handleFileChange} />
                          </label>
                        </div>
                      </div> 
                      <div className="col-12 mb-3 mt-3 text-center"><h4 className='text text-dark'>oppure</h4></div>
                      <div className="col-12 mb-3">
                        <label className="form-label" htmlFor="titolo">Descrivi qui ciò di cui hai bisogno</label>
                        <textarea className="form-control" id="testo" rows="5" placeholder="Scrivi qui la descrizione " value={testo} onChange={(e) => setTesto(e.target.value)}></textarea>
                      </div>                     
                      <div className="col-12 mb-3" style={{textAlign: 'right'}}>
                        <button type="submit" className="btn btn-outline-primary" id="btnUploadUserDoc" style={{marginBottom: '0px'}}>Richiedi preventivo</button>
                      </div>
                      {showSuccessMessage && ( // Mostra il messaggio solo se showSuccessMessage è true
                        <div className="col-12">
                          <div className="alert alert-success mt_20" role="alert">Status aggiornato con successo!<br /> <br />Puoi modificarlo ulteriormente o impostare </div>
                        </div>
                      )}
                    </div>
                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
      </section>
    </>
  );

}

export default RichiestaPreventivo;