import React, { useState, useEffect, useRef } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal"; 
import moment from 'moment'; // Importa moment.js
import 'moment/locale/it'; // Importa il locale italiano
import $ from 'jquery';
function Notifiche() {
  const [notifiche, setNotifiche] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedNotifica, setSelectedNotifica] = useState(null);
  const [updateMenu, setUpdateMenu] = useState(false); // Nuovo stato per forzare il re-render del menu
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    const fetchNotifiche = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(`/api/notifiche`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setNotifiche(data);
        } else {
          throw new Error("Errore nel recupero delle notifiche");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifiche();

    const intervalId = setInterval(fetchNotifiche, 300000); // 300000 ms = 5 minuti

    // Pulisci l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Chiudi il dropdown se l'utente clicca fuori
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleNotificaClick = async (notificaId) => { 
  try {
    console.log("Inizio handleNotificaClick");
    const token = localStorage.getItem("token");
    const response = await fetch(
      `/api/notifica/${notificaId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ letto: true }),
      }
    );
    if (response.ok) {
        // Aggiorna l'array delle notifiche solo se la richiesta ha avuto successo
        setNotifiche((prevNotifiche) => prevNotifiche.map((notifica) => {
          if (notifica._id === notificaId) {
            return { ...notifica, letto: true };
          } else {
            return notifica;
          }
        }));

        // Trova la notifica aggiornata nell'array
        const notificaAggiornata = notifiche.find(notifica => notifica._id === notificaId);

        if (notificaAggiornata) {
          setSelectedNotifica(notificaAggiornata); // Imposta la notifica selezionata
          setShowModal(true); // Mostra la modale
          setUpdateMenu(!updateMenu); // Forza il re-render del menu
        } else {
          console.error("Notifica non trovata dopo l'aggiornamento");
        }
      } else {
        throw new Error("Errore nell'aggiornamento della notifica");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setShowDropdown(false); // Chiudi il dropdown dopo il click
    }
  };

  const notificheNonLette = notifiche.filter((notifica) => !notifica.letto).length;

  return (
    <>
      <Dropdown ref={dropdownRef} show={showDropdown} onToggle={setShowDropdown}>
        <Dropdown.Toggle variant="link" id="dropdown-notifiche" style={{ all: 'unset' }}>
          <div style={{ position: 'relative',  all: 'unset' }}>
            <i className="ti-bell"></i>
            {notificheNonLette > 0 && (
              <span className="badge bg-danger" style={{ position: "absolute", top: "-8px", right: "-10px", fontSize: "0.6rem", borderRadius: "100%", }}>
                {notificheNonLette}
              </span>
            )}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu align="end" key={updateMenu}>
          {isLoading ? (
            <Dropdown.Item>Caricamento...</Dropdown.Item>
          ) : error ? (
            <Dropdown.Item className="error-message">{error}</Dropdown.Item>
          ) : (
            <>
              {notifiche.map(notifica => (
                <Dropdown.Item key={notifica._id} className="dropspacer mt_10 mb_10" as="div">
                <a href="#!" onClick={(event) => { event.preventDefault();  handleNotificaClick(notifica._id);}} className="text text-primary" style={{fontWeight: '600', fontSize: '0.8rem'}}>{notifica.titolo}</a>              
                {/* <br />{notifica.testo} */}
              </Dropdown.Item>
              ))}
              {notifiche.length === 0 && <Dropdown.Item>Non ci sono nuove notifiche.</Dropdown.Item>}
            </>
          )}
          <Dropdown.Item key="0" className="dropspacer mt_30 mb_10" as="div" style={{textAlign: "right"}}>
            <a href="/elenco-notifiche" style={{fontWeight: '600'}}>Vedi tutte</a>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {/* Modale */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notifica</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="mb_30 text text-primary" style={{lineHeight: '1rem'}}>{selectedNotifica?.titolo}</h4>
          <p>{selectedNotifica?.testo}</p>
          {selectedNotifica?.url ? (
            <p style={{textAlign: 'right', color: '#212529'}}><a href={`/${selectedNotifica?.url}`} className="btn btn-outline-primary">Vai al collegamento</a></p>
          ) : (
            <> </>
          )}
          <p className="mt_30" style={{fontSize: '0.7rem', textAlign: 'right'}}><i>{moment(selectedNotifica?.data).format('DD MMMM YYYY')} alle ore {moment(selectedNotifica?.data.pubblicato).format('HH:mm')}</i></p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Notifiche;