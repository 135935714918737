import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";

function Convenzioni() {
    const { userData } = useContext(UserContext);

    return (
        <>
            <NavBar />            
            <section className="main_content dashboard_part">
                <div className='class="container-fluid g-0'>            
                    <Testata userData={userData} />
                </div>
                <div className="main_content_iner ">
                    <div className="container-fluid plr_30 body_white_bg pt_30">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="white_box_tittle list_header">
                                    <h4>I nostri servizi convenzionati</h4>
                                </div>
                                <div className="row justify-content-center mt_20">
                                    <div className="col-lg-6">
                                        <div className="alert alert-info">
                                            <p>Abbiamo previsto dei Servizi in convenzione per i nostri Utenti con le seguenti attività.</p>
                                            <p>I Servizi vengono costantemente aggiornati, prevedono servizi professionali selezionati dal nostro staff e garantiscono competenza e qualità, oltre a costi agevolati.</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='row mt_30'>
                                    <div className='col-lg-2'><img src="./img/convenzioni/catia-lettieri.png" id="internazionale" alt="internazionalizzazione by Catia Lettieri" className="responsive" /></div>
                                    <div className='col-lg-2 pt_30'><b>Internazionalizzazione</b></div>
                                    <div className='col-lg-4 pt_30'><h4>Catia Lettieri</h4></div>
                                    <div className='col-lg-2 pt_30'><i className='ti-mobile'></i> <a href="tel:00390757829452" style={{color: '#ff7f2a', fontWeight: '500'}}>075 782 94 52</a></div>
                                    <div className='col-lg-2 pt_30'><i className='ti-link'></i> <a href="https://www.catialettieri.it/" target="_blank" rel="noreferrer" style={{color: '#ff7f2a', fontWeight: '500'}}>www.ritadellomo.it</a></div>
                                </div>
                                <div className='row mt_30'>
                                    <div className='col-lg-2'><img src="./img/convenzioni/studio-regine.png" id="marchi" alt="Registrazione marchi by Studio Avvocato Cristina Regine" className="responsive" /></div>
                                    <div className='col-lg-2 pt_30'><b>Registrazione Marchi</b></div>
                                    <div className='col-lg-4 pt_30'><h4>Studio Legale Cristina Regine</h4></div>
                                    <div className='col-lg-2 pt_30'><i className='ti-mobile'></i> <a href="tel:00390757829452" style={{color: '#ff7f2a', fontWeight: '500'}}>075 782 94 52</a></div>
                                    <div className='col-lg-2 pt_30'><i className='ti-link'></i> n.d.</div>
                                </div>
                                <div className='row mt_30'>
                                    <div className='col-lg-2'><img src="./img/convenzioni/idea-business.png" id="grafica" className='responsive' alt="Servizi di progettazione grafica by IDEA Business" /></div>
                                    <div className='col-lg-2 pt_30'><b>Grafica</b></div>
                                    <div className='col-lg-4 pt_30'><h4>IDEA Business</h4></div>
                                    <div className='col-lg-2 pt_30'><i className="ti-mobile"></i> <a href="tel:003901119567111" style={{color: '#ff7f2a', fontWeight: '500'}}>011 95 67 111</a></div>
                                    <div className='col-lg-2 pt_30'><i className='ti-link'></i> <a href="https://www.ibow.com" target="_blank" rel="noreferrer" style={{color: '#ff7f2a', fontWeight: '500'}}>www.ibow.com</a></div>
                                </div>
                                <div className='row mt_30'>
                                    <div className='col-lg-2'><img src="./img/convenzioni/ritadellomo.png" id="eventi" alt="Organizzazione Eventi Aziendali by Rita Dell'Omo" className="responsive" /></div>
                                    <div className='col-lg-2 pt_30'><b>Eventi Aziendali</b></div>
                                    <div className='col-lg-4 pt_30'><h4>Rita Dell'Omo Wedding & Events</h4></div>
                                    <div className='col-lg-2 pt_30'><i className='ti-mobile'></i> <a href="tel:00390757829452" style={{color: '#ff7f2a', fontWeight: '500'}}>075 782 94 52</a></div>
                                    <div className='col-lg-2 pt_30'><i className='ti-link'></i> <a href="https://www.ritadellomo.it/" target="_blank" rel="noreferrer" style={{color: '#ff7f2a', fontWeight: '500'}}>www.ritadellomo.it</a></div>
                                </div>
                                <div className='row mt_30'>
                                    <div className='col-lg-2'><img src="./img/convenzioni/tutor-digitale.png" id="web" alt="Servizi Web by Tutor-digitale.it" className="responsive" /></div>
                                    <div className='col-lg-2 pt_10'><b>Servizi Web</b><br />(Sviluppo e Webdesign, Social Media, Web marketing)</div>
                                    <div className='col-lg-4 pt_30'><h4>Tutor Digitale</h4></div>
                                    <div className='col-lg-2 pt_30'><i className='ti-mobile'></i> <a href="tel:00390662279218" style={{color: '#ff7f2a', fontWeight: '500'}}>06 622 79 218</a></div>
                                    <div className='col-lg-2 pt_30'><i className='ti-link'></i>  <a href="https://www.tutor-digitale.it/" target="_blank" rel="noreferrer" style={{color: '#ff7f2a', fontWeight: '500'}}>www.tutor-digitale.it</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
};

export default Convenzioni;