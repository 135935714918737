import React, { useContext, useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import $ from 'jquery';
import 'datatables.net';
function FattureInviate() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [documenti, setDocumenti] = useState([]); // Stato per i documenti
  const [errorDoc, setDocError] = useState(null);
  //   const navigate = useNavigate();
  const [acquistiInCorso, setAcquistiInCorso] = useState([]); // Nuovo stato

  useEffect(() => {   
    const fetchDocumenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/fatture-inviate`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const docum = await response.json();
            console.log(docum);
            setDocumenti(docum); // Aggiorna lo stato con i documenti ricevuti
            } else {
            throw new Error('Errore nel recupero dei documenti');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchDocumenti(); 
  }, []);// Chiama la funzione per recuperare i documenti    

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

  useEffect(() => {
  // Funzione per inizializzare o aggiornare la DataTable
  let table;
  const initializeOrUpdateTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        // Inizializza la DataTable solo se non è già stata inizializzata
        table = $(tableRef.current).DataTable({ // Assegna il valore a table
            ordering: true,
            searching: true,
            paging: true,
            info: true,
            dom: "frtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            language: {
            url: "/js/it-IT.json",
            },
            pageLength: 10,
        });
      }
    };

    // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
    if (tableRef.current) {
        setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
    }

  // Distruggi la DataTable quando il componente viene smontato
  return () => {
    if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
        table.destroy();
    }
  };
}, [documenti]);

    const handleDownload = async (documentoId, filename) => { // Funzione per gestire il download
        try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/documenti/${documentoId}/scaricato`, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
        });
        if (response.ok) {
            const blob = await response.blob(); // Ottieni il file come blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // Usa il nome file corretto
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setDocumenti(prevDocumenti => prevDocumenti.map(doc =>
            doc._id === documentoId ? { ...doc, scaricato: true } : doc
      ));
        } else {
            throw new Error("Errore durante il download del documento");
        }
        } catch (err) {
        console.error('Errore durante il download:', err);
        // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
        }
    };

    const handleAcquistoRecuperoCrediti = async (documentoId) => {
  setAcquistiInCorso(prev => [...prev, documentoId]);

  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`/api/fatture-inviate/${documentoId}`, {
      method: 'PATCH', // Metodo PATCH per aggiornare
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    });

    if (response.ok) {
      const data = await response.json();

      // Reindirizza a Stripe solo se la risposta contiene l'URL
      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      } else {
        console.error('URL di reindirizzamento mancante nella risposta:', data);
        // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
      }
    } else {
      const errorData = await response.json();
      console.error('Errore nella richiesta al server:', response.status, errorData.error || response.statusText);
      // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
    }
  } catch (err) {
    console.error('Errore durante l\'acquisto:', err);
    // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
  }
};


    return (
      <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>Richiedi Recupero Crediti</h4>
                            </div>
                            <div className="alert alert-primary">
                                <h5>Servizio di Assistenza per recupero bonario dei crediti</h5>
                                <p className="text text-dark">Si tratta di una serie di azioni volte a sollecitare in via bonaria il pagamento di fatture già emesse effettuate da ns. personale a Vs. nome, prima di adire le vie legali.<br /> <br />
                                Questo Servizio viene offerto ad un costo unitario, <cite>una tantum</cite>, di <b>€ 22,57</b> imposte comprese, da pagarsi anticipatamente tramite Stripe.</p>
                            </div>
                            {errorDoc ? (
                                <tr><td colspan="4"> <h5>Errore: {errorDoc}</h5> </td></tr>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table table-striped" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                        <thead>
                                            <tr>
                                            <th className="text-center" style={{fontWeight: '700'}}><i>Titolo</i></th>
                                            <th style={{fontWeight: '700'}}><i>Pubblicato in data</i></th>
                                            <th style={{fontWeight: '700'}}><i>Richiedi/Status lavorazione</i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documenti.map(documento => (
                                            <tr key={documento._id}>
                                                <td style={{ paddingTop: '20px' }}>
                                                  {documento.filename ? (
                                                    <button className="btn btn-link btn-block" onClick={() => handleDownload(documento._id, documento.filename)} title="Scarica una copia di questo documento" style={{fontWeight: '600', textDecoration: 'none'}}>
                                                        {documento.titolo} &nbsp; <i className="ti-download"></i>
                                                    </button>
                                                  ) : (
                                                    <p className="text text-dark" style={{fontWeight: '600'}}>{documento.titolo}</p>
                                                  )}
                                                </td>
                                                <td style={{ paddingTop: '20px' }}>
                                                <p>{documento.pubblicato ? moment(documento.pubblicato).format('DD/MM/YY') : ''} h. {documento.pubblicato ? moment(documento.pubblicato).format('HH:mm') : ''}</p>
                                                </td>

                                                <td style={{ paddingTop: '20px' }}>
                                                    {documento.evaso ? (
                                                        <span className="badge bg-success">Evaso</span>
                                                    ) : (
                                                        // Se il documento non è evaso, controlla se è una "Richiesta emissione Fattura" e se NON è stato acquistato
                                                        !documento.pagato && documento.cosa === "Richiesta emissione Fattura" ? (
                                                            
                                                        // Se è una richiesta di fattura e non è stato acquistato, mostra il pulsante di acquisto
                                                        <button
                                                            onClick={() => handleAcquistoRecuperoCrediti(documento._id)}
                                                            className='badge bg-primary'
                                                            style={{ color: '#FFF' }}
                                                        >
                                                            Acquista recupero crediti <i className='ti-arrow-top-right'></i>
                                                        </button>
                                                        ) : (
                                                        // Altrimenti, mostra il badge "In lavorazione" con la data di acquisto (se presente)
                                                        (() => {
                                                            const daysDiff = moment().diff(moment(documento.pubblicato), 'days');

                                                            const badge = (
                                                            <>
                                                                {daysDiff < 4 ? (
                                                                <span className="badge bg-info text-dark">In lavorazione</span>
                                                                ) : daysDiff >= 4 && daysDiff <= 10 ? (
                                                                <span className="badge bg-warning text-dark">In lavorazione</span>
                                                                ) : (
                                                                <span className="badge bg-danger">In lavorazione</span>
                                                                )}
                                                                {documento.dataAcquisto && (
                                                                <span className="ms-2">
                                                                    Acquistato il {moment(documento.dataAcquisto).format('DD/MM/YY')}
                                                                </span>
                                                                )}
                                                            </>
                                                            );

                                                            return badge; // Restituisci il badge completo
                                                        })()
                                                        )
                                                    )}
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className="text-center" style={{fontWeight: '700'}}><i>Titolo</i></th>
                                                <th style={{fontWeight: '700'}}><i>Pubblicato in data</i></th>
                                                <th style={{fontWeight: '700'}}><i>Richiedi/Status lavorazione</i></th>
                                            </tr>
                                        </tfoot>
                                        </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
      </>
    );
  }

export default FattureInviate;