import React, { useContext, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import Testata from "./partials/Testata";
import NavBar from "./partials/NavBar";
import Footer from "./partials/Footer";

function CallConsulente() {
    const { userData } = useContext(UserContext); // Ottieni userData dal contesto
    const tidycalContainerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://asset-tidycal.b-cdn.net/js/embed.js';
        script.async = true;
        script.onload   
    = () => {
        // Lo script è stato caricato, ora puoi renderizzare il componente Tidycal
        if (tidycalContainerRef.current) {
            tidycalContainerRef.current.innerHTML = `
            <div class="tidycal-embed" data-path="idea-business/il-tuo-consulente-animus-operandi"></div>
            `;
        }
        };
        document.head.appendChild(script);

        return () => {
        document.head.removeChild(script);
        };
    }, []);

    return (
      <>
      <NavBar />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <Testata userData={userData} />
        </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>Prenota una call con il Tuo consulente</h4>
                            </div>
                            <div ref={tidycalContainerRef}></div> 
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
      </>
    );
  }

export default CallConsulente;