import React, { useContext, useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importa il contesto
import TestataAdmin from "./partials/TestataAdmin";
import NavBarAdmin from "./partials/NavBarAdmin";
import Footer from "./partials/Footer";
import moment from 'moment'; // Importa moment.js
import 'moment/locale/it'; // Importa il locale italiano
import $ from 'jquery';
import 'datatables.net';
function ElencoUtenti() {
  const { userData } = useContext(UserContext); // Ottieni userData dal contesto
  const [utenti, setUtenti] = useState([]); // Stato per i Clienti
  const [errorDoc, setDocError] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleDeleteOperator = (operatorId) => {
    setUserToDelete(operatorId);
    setShowConfirmationModal(true);
  };

  const confirmDeleteOperator = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/utente/${userToDelete}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
      });

      if (response.ok) {
        // Rimuovi l'utente eliminato dall'array
        setUtenti(utenti.filter(op => op._id !== userToDelete));
      } else {
        throw new Error('Errore durante l\'eliminazione dell\'utente');
      }
    } catch (err) {
      console.error('Errore:', err);
      // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
    } finally {
      setShowConfirmationModal(false);
      setUserToDelete(null);
    }
  };
//   const navigate = useNavigate();

  useEffect(() => {   
    const fetchUtenti = async () => {
      const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/utenti`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            });

            if (response.ok) {
            const utenti = await response.json();
            setUtenti(utenti); // Aggiorna lo stato con i Clienti ricevuti
            } else {
            throw new Error('Errore nel recupero dei Utenti');
            }
        } catch (err) {
            setDocError(err.message);
        }
    };

    fetchUtenti(); 
  }, []);// Chiama la funzione per recuperare i Clienti    

  // Ref per l'elemento della tabella
  const tableRef = useRef(null);

  useEffect(() => {
    // Funzione per inizializzare o aggiornare la DataTable
    let table;
    const initializeOrUpdateTable = () => {
        if (!$.fn.DataTable.isDataTable(tableRef.current)) {
            // Inizializza la DataTable solo se non è già stata inizializzata
            table = $(tableRef.current).DataTable({ // Assegna il valore a table
                responsive: true,
                ordering: true,
                searching: true,
                paging: true,
                info: true,
                dom: "frtip",
                buttons: ["copy", "csv", "excel", "pdf", "print"],
                language: {
                url: "/js/it-IT.json",
                },
                pageLength: 10,
            });
        }
        };

        // Verifica se tableRef.current è definito prima di inizializzare o aggiornare
        if (tableRef.current) {
            setTimeout(initializeOrUpdateTable, 500); // Timeout per garantire che il DOM sia pronto
        }

    // Distruggi la DataTable quando il componente viene smontato
    return () => {
        if (table && table.destroy) { // Controlla se table è definito e ha il metodo destroy
            table.destroy();
        }
    };
    }, [utenti]);

    return (
      <>
      <NavBarAdmin />
      <section className="main_content dashboard_part">
        <div className='class="container-fluid g-0'>            
            <TestataAdmin userData={userData} />
        </div>
            <div className="main_content_iner ">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box_tittle list_header">
                                <h4>Anagrafiche degli Utenti registrati</h4>
                            </div>
                            {errorDoc ? (
                                <h5>Errore: {errorDoc}</h5>
                            ) : (
                                <div className="table-responsive mb_50">
                                    <table className="table table-striped" id="datatable" style={{width: '100%'}} ref={tableRef}>
                                        <thead>
                                            <tr>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Status</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Ragione Sociale</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Nome e Cognome</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Registrato</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Scadenza</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Elimina</th>
                                            </tr>
                                        </thead>                                              
                                        <tbody>
                                            {utenti.map(utente => (
                                                <tr key={utente._id}>
                                                    <td>{utente.attivo && moment(utente.rinnovo).isAfter(moment()) ? (
                                                            <button className="btn btn-sm btn-success" style={{fontSize: "0.7rem"}}>
                                                                {new Intl.NumberFormat('it-IT', {
                                                                    style: 'currency',
                                                                    currency: 'EUR',
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(utente.quota / 100)}
                                                            </button>
                                                        ) : (
                                                            <button className="btn btn-sm btn-danger" style={{fontSize: "0.7rem"}}>
                                                                 {new Intl.NumberFormat('it-IT', {
                                                                    style: 'currency',
                                                                    currency: 'EUR',
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(utente.quota / 100)}
                                                            </button>
                                                        )}</td>
                                                    <td>
                                                        <p>                                                            
                                                            <a href={`/amministratore/utente/${utente._id}`} title="Vedi dettaglio anagrafica" style={{fontWeight: '500'}}>
                                                            {utente.ragioneSociale} &nbsp; <i className="ti-pencil"></i>
                                                        </a></p>
                                                    </td>
                                                    <td><p style={{fontWeight: '500', color: '#00273d'}}>{utente.nome} {utente.cognome}</p></td>
                                                    <td><p>{moment(utente.registrazione).format('DD/MM/YYYY')}</p></td>
                                                    <td><p>{moment(utente.scade).format('DD/MM/YYYY')}</p></td>
                                                    <td>
                                                        <button  className="btn btn-sm btn-danger"  onClick={() => handleDeleteOperator(utente._id)} title="Elimina questo utente">
                                                            <i className='ti-trash'></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}                                            
                                        </tbody>
                                        {/* Modale di conferma (Bootstrap 5) */}
                                            <div className={`modal fade ${showConfirmationModal ? 'show' : ''}`} style={{ display: showConfirmationModal ? 'block' : 'none', backgroundColor: 'rgba(255, 255, 255, 1)' }} tabIndex="-1">
                                                <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                    <h5 className="modal-title">Conferma eliminazione</h5>
                                                    <button type="button" className="btn-close" onClick={() => setShowConfirmationModal(false)} aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                    <p>Sei sicuro di voler eliminare questo Utente?</p>
                                                    </div>
                                                    <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmationModal(false)}>Annulla</button>
                                                    <button type="button" className="btn btn-danger" onClick={confirmDeleteOperator}>Elimina</button>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        {/* Fine Modale di conferma */}
                                        <tfoot>
                                            <tr>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Status</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Ragione Sociale</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Nome e Cognome</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Registrato</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Scadenza</th>
                                                <th style={{fontWeight: '400', fontStyle: 'italic'}}>Elimina</th>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
      </>
    );
  }

export default ElencoUtenti;
